@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.input-style_01,.mf_finder_searchBox_query_wrap{
  input{
    @include appearance(none);
  	margin: 0;
    border: 2/$design-num-sp*100vw solid $key-gray1;
    border-radius: 8px;
  	outline: none;
    @include fz_vw(38);
  	line-height: 1em;
  	padding-left: 24/$design-num-sp*100vw;
  	padding-right: 24/$design-num-sp*100vw;
  	color:$key-black!important;
  	min-height:120/$design-num-sp*100vw;
    box-sizing: border-box;
  	width:100%;
    background-color: $white;
    transition: .2s;
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button{
      -webkit-appearance: none;
      margin: 0;
    }
    &[type="number"]{
      -moz-appearance:textfield;
    }
  	&:focus{
  		border:2/$design-num-sp*100vw solid $key-black;
  	}
  	/* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
  	&:placeholder-shown {
  		color:$key-gray2;
  	}
  	/* Google Chrome, Safari, Opera 15+, Android, iOS */
  	&::-webkit-input-placeholder {
  		color:$key-gray2;
  	}
  	/* Firefox 18- */
  	&:-moz-placeholder {
  		color:$key-gray2;
  	}
  	/* Firefox 19+ */
  	&::-moz-placeholder {
  		color:$key-gray2;
  	}
  	/* IE 10+ */
  	&:-ms-input-placeholder {
  		color:$key-gray2;
  	}
  }
}
@media screen and (min-width: $break-point-middle) {
.input-style_01,.mf_finder_searchBox_query_wrap{
  input{
    border: 2px solid $key-gray1;
    border-radius: 10px;
    font-size: 1.8rem;
  	padding-left: 16px;
  	padding-right: 16px;
  	min-height:70px;
    &:focus{
  		border:2px solid $key-black;
  	}
  }
}
}
///////////////////////////////////////
.input-style_02{
  @extend .input-style_01;
  input{
    @include fz_vw(36);
  }
}
@media screen and (min-width: $break-point-middle) {
.input-style_02{
  input{
    font-size: 1.6rem;
  }
}
}
///////////////////////////////////////
.input-style_03{
  @extend .input-style_02;
  position: relative;
  cursor: pointer;
  input{
    cursor: pointer;
  }
  &::after{
    @include iconFont;
    content: '\EA01';
    position: absolute;
    $tri-gutter:16/$design-num-sp*100vw;
    top:-webkit-calc(50% - #{$tri-gutter});
    top:calc(50% - #{$tri-gutter});
    right: 30/$design-num-sp*100vw;
    @include fz_vw(32);
    line-height: 1em;
  }
  &.active{
    input{
      border: 2/$design-num-sp*100vw solid $key-black;
      border-radius: 8px 8px 0 0;
      border-bottom: none;
    }
    &::after{
      transform: rotate(180deg);
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.input-style_03{
  &::after{
    top:-webkit-calc(50% - .7rem);
    top:calc(50% - .7rem);
    right: 12px;
    font-size: 1.8rem;
  }
  &.active{
    input{
      border: 2px solid $key-black;
      border-radius: 10px;
    }
  }
  &:hover{
    input{
      border:2px solid $key-black;
    }
  }
}
}
///////////////////////////////////////
.input-style_04{
  input,textarea{
    @include appearance(none);
  	margin: 0;
    border: 1/$design-num-sp*100vw solid $key-gray1;
    border-radius: 3px;
  	outline: none;
    @include fz_vw(32);
  	line-height: 1em;
  	padding-left: 24/$design-num-sp*100vw;
  	padding-right: 24/$design-num-sp*100vw;
  	color:$key-black!important;
  	min-height:120/$design-num-sp*100vw;
    box-sizing: border-box;
  	width:100%;
    background-color: $white;
    transition: .2s;
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button{
      -webkit-appearance: none;
      margin: 0;
    }
    &[type="number"]{
      -moz-appearance:textfield;
    }
  	&:focus{
  		border:1/$design-num-sp*100vw solid $key-black;
  	}
  	/* 各ベンダープレフィックスが取れた標準版！！(http://caniuse.com/#search=placeholder-shown) */
  	&:placeholder-shown {
  		color:$key-gray2;
  	}
  	/* Google Chrome, Safari, Opera 15+, Android, iOS */
  	&::-webkit-input-placeholder {
  		color:$key-gray2;
  	}
  	/* Firefox 18- */
  	&:-moz-placeholder {
  		color:$key-gray2;
  	}
  	/* Firefox 19+ */
  	&::-moz-placeholder {
  		color:$key-gray2;
  	}
  	/* IE 10+ */
  	&:-ms-input-placeholder {
  		color:$key-gray2;
  	}
  }
  textarea{
    padding-top: 16/$design-num-sp*100vw;
    padding-bottom: 16/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.input-style_04{
  input,textarea{
    border: 1px solid $key-gray1;
    font-size: 1.4rem;
  	padding-left: 8px;
  	padding-right: 8px;
  	min-height:3em;
    &:focus{
  		border:1px solid $key-black;
  	}
  }
  textarea{
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.select-style_01{
	background-color:$white;
	position:relative;
	//border:1px solid #ccc;
	&:before{
    @include iconFont;
		content:"\EA1F";
		position:absolute;
		line-height:0;
		top:60/$design-num-sp*100vw;
		right:10px;
		color:$key-gray1;
		z-index:1;
		font-size:.8rem;
	}
	select{
		width:100%;
		position:relative;
		z-index:2;
		border:none;
		@include appearance(none);
    @include fz_vw(32);
		//line-height:(38/16)+em;
		@include border-radius(3px);
		background-color:transparent;
		padding:0 8px;
		height:120/$design-num-sp*100vw;
		outline:0;
    border: 1px solid $key-gray1;
		@include box-shadow(0);
    color: $key-black;
		&:focus{
      border: 1px solid $key-gray1;
			@include box-shadow(0);
			outline:0;
		}
	}
	select::-ms-expand {
		display: none;
	}
}
@media screen and (min-width: $break-point-middle) {
.select-style_01{
	&:before{
    top:2.8em;
	}
	select{
    font-size: 1.4rem;
    height: 3.2em;
	}
	option{
		width:100%;
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.check-style_01{
  input{
    display: none;
  }
  input + span{
    display: inline-block;
    @include fz_vw(28);
    line-height: 1.4em;
    //white-space: nowrap;
    padding-left: 65/$design-num-sp*100vw;
    position: relative;
    cursor: pointer;
    &::before{
      content: '';
      width: 51/$design-num-sp*100vw;
      height: 51/$design-num-sp*100vw;
      position: absolute;
      top: -4/$design-num-sp*100vw;
      left: 0;
      position: absolute;
      border-radius: 2px;
      border: 1px solid $key-black;
    }
    &::after{
      content: '';
      width: 35/$design-num-sp*100vw;
      height: 35/$design-num-sp*100vw;
      position: absolute;
      top: 4/$design-num-sp*100vw;
      left: 8/$design-num-sp*100vw;
      background-color: $key-color;
      transform-origin: 50% 50%;
      opacity: 0;
      transform: scale(0);
      transition: .2s;
    }
  }
  input:checked + span{
    &::after{
      opacity: 1;
      transform: scale(1);
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.check-style_01{
  input + span{
    font-size: 1.6rem;
    padding-left: 36px;
    &::before{
      width: 25px;
      height: 25px;
      top: 0;
    }
    &::after{
      width: 15px;
      height: 15px;
      top: 5px;
      left: 5px;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.custom-select-wrapper {
  position: relative;
  display: inline-block;
  user-select: none;
}
.custom-select-wrapper select {
  display: none;
}
.custom-select {
  position: relative;
  display: inline-block;
}
select.custom-select{
  display: none;
}
.custom-select-trigger {
  position: relative;
  display: block;
  min-width: 270/$design-num-sp*100vw;
  padding: 34/$design-num-sp*100vw 20/$design-num-sp*100vw 30/$design-num-sp*100vw;
  text-align: center;
  @include fz_vw(30);
  font-family: $fontMix;
  line-height: 1em;
  border-radius: 100px;
  cursor: pointer;
  border: 2/$design-num-sp*100vw solid $key-black;
  white-space: nowrap;
  transition: .3s;
}
.custom-select-trigger:after {
  position: absolute;
  display: block;
  @include iconFont;
  content: '\EA01';
  @include fz_vw(20);
  line-height: 1em;
  $gutter-icon:10/$design-num-sp*100vw;
  top: -webkit-calc(50% - #{$gutter-icon});
  top: calc(50%  - #{$gutter-icon});
  right: 30/$design-num-sp*100vw;
}
.custom-select.opened .custom-select-trigger:after {
  transform: rotate(-180deg);
}
.custom-options {
  position: absolute;
  display: block;
  top: 120%;
  left: 0;
  right: 0;
  min-width: 100%;
  //margin: 15px 0;
  border: 2/$design-num-sp*100vw solid $key-black;
  border-radius: 16/$design-num-sp*100vw;
  box-sizing: border-box;
  background: $white;
  transition: all .4s ease-in-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-15px);
  padding: 16/$design-num-sp*100vw 0;
}
.custom-select.opened .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateY(0);
}
.option-hover:before {
  background: $bg-color1;
}
.custom-option {
  padding: 15/$design-num-sp*100vw 32/$design-num-sp*100vw;
  position: relative;
  display: block;
  @include fz_vw(30);
  line-height: 1em;
  cursor: pointer;
  transition: all .4s ease-in-out;
}
.custom-option.selection {
  background: $bg-color1;
  color: $key-color;
}
@media screen and (min-width: $break-point-middle) {
.custom-select-trigger {
  min-width: 116px;
  padding: 8px 10px 7px;
  font-size: 1.3rem;
  border: 1px solid $key-black;
  &:hover{
    border: 1px solid $key-color;
    background-color: $key-color;
    color: $white;
  }
}
.custom-select-trigger:after {
  font-size: 1.2rem;
  top: -webkit-calc(50% - .6rem);
  top: calc(50% - .6rem);
  right: 8px;
}
.custom-options {
  border: 1px solid $key-black;
  border-radius: 8px;
  padding: 4px 0;
}
.custom-option {
  padding: 4px 8px;
  font-size: 1.2rem;
}
.custom-option:hover{
  background: $bg-color1;
  color: $key-color;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.form-style{
  .sec-msg{
    border: 1px solid $key-color;
    padding: 20/$design-num-sp*100vw;
    text-align: center;
    @include fz_vw(28);
    color: $key-color;
    margin-bottom: 30/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.form-style{
  .sec-msg{
    padding: 10px;
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.sec-form_01{
  padding: 20/$design-num-sp*100vw 0;
  dt{
    @include fz_vw(32);
    line-height: 1.6em;
    margin-bottom: 16/$design-num-sp*100vw;
    font-weight: bold;
    &.req{
      &::after{
        content: '必須';
        @include fz_vw(26);
        line-height: 1em;
        padding: 8/$design-num-sp*100vw 16/$design-num-sp*100vw;
        background-color: $key-color;
        color: $white;
        display: inline-block;
        margin-left: 10/$design-num-sp*100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-form_01{
  padding: 10px 0;
  dt{
    font-size: 1.5rem;
    margin-bottom: 8px;
    &.req{
      &::after{
        font-size: 1.1rem;
        padding: 4px 8px;
        margin-left: 5px;
      }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.input-col-style_01{
  >*{
    margin-bottom: 30/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.input-col-style_01{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  >*{
    width: 1/4*100%;
    box-sizing: border-box;
    padding-right: 1em;
    margin-bottom: 0;
    &:nth-child(n+5){
      margin-top: 16px;
    }
  }
  &::before,&::after{
    content: '';
    width: 1/4*100%;
    height: 0;
    display: block;
  }
  &::before{
    order: 1;
  }
}
}
/////////////////////////////////////////
.input-col-style_02{
  .col{
    &:nth-child(n+2){
      margin-top: 20/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.input-col-style_02{
  display: flex;
  justify-content: space-between;
  .col{
    width: 49%;
    &:nth-child(n+2){
      margin-top: 0;
    }
  }
}
}
/////////////////////////////////////////
.input-col-style_03{
  .col{
    &:nth-child(n+2){
      margin-top: 20/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.input-col-style_03{
  display: flex;
  justify-content: space-between;
  .col{
    width: 49%;
    &:nth-child(n+2){
      margin-top: 0;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}

@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#outermenu{
	@include opacity(1);
	position: relative;
	.menuOpen &,.modalOpen &{
		overflow: hidden;
		position: fixed;
		width: 100%;
	}
	.modalOpen &{
		opacity: 0;
	}
}
@media screen and (min-width: $break-point-middle) {
#outermenu{
	min-height: 100vh;
  padding-bottom: $footer-size;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#clickblocker{
	width: 100%;
	height: 0%;
	position:fixed;
	top: 0;
	left: 0;
	z-index: -1;
	background-color: $bg-color1;
	//background-color: rgba(255, 255, 255, .95);
	transition: .3s $ease-out-quad;
	//background-color:rgba(255, 255, 255, .8);
	//display: none;
	.menuOpen &{
		//display: block;
		width: 100%;
		height: 100%;
		z-index: 600;
	}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrap-menu{
	height: 0;
	opacity: 0;
	position: fixed;
	z-index: 0;
	transition: .6s;
	width: 100%;
	overflow: hidden;
	transition: height .6s $ease-out-quad,opacity .6s $ease-out-quad;
	background-color: $bg-color1;
	.flag-menu &{
		height: auto;
		padding: 0 $sp-pad2 $sp-pad2;
	}
	.menuOpen &{
		opacity: 1;
		z-index: 3000;
		width: 100%;
		position: relative;
	}
	>.wrapper{
		position: relative;
		box-sizing: content-box;
	}
	>.wrapper>.inner{
		position: relative;
		border-top: 2/$design-num-sp*100vw solid $key-black;
		//margin-top: 120/$design-num-sp*100vw;
		padding-top: 40/$design-num-sp*100vw;
		padding-bottom: 120/$design-num-sp*100vw;
	}
}
@media screen and (min-width: $break-point-middle) {
#wrap-menu{
	.flag-menu &{
		padding: 40px;
	}
	>.wrapper{
		max-width: 1090px;
		margin: 0 auto;
	}
	>.wrapper>.inner{
		@include setTbl;
		border-top: none;
		//margin-top: 0;
		padding-top: 0;
		>*{
			vertical-align: top;
		}
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrap-search{
	height: 0;
	opacity: 0;
	position: fixed;
	z-index: 0;

	// opacity: 1;
	// height: auto;
	// z-index: 9999;
	// top: 0;
	// left: 0;
	// padding: 0/$design-num-sp*100vw $sp-pad 120/$design-num-sp*100vw;
	// background-color: $bg-color1;

	transition: .9s;
	width: 100%;
	overflow: hidden;
	transition: height .6s $ease-out-quad,opacity .6s $ease-out-quad;
	margin-top: -70/$design-num-sp*100vw;
	.flag-search &{
		height: auto;
		padding: 70/$design-num-sp*100vw $sp-pad 120/$design-num-sp*100vw;
	}
	.menuOpen &{
		opacity: 1;
		z-index: 3000;
		width: 100%;
		position: relative;
	}
	>.wrapper{
		position: relative;
		box-sizing: content-box;
	}
}
@media screen and (min-width: $break-point-middle) {
#wrap-search{
	margin-top: 0;
	.flag-search &{
		padding: 90px 40px;
	}
	>.wrapper{
		max-width: 870px;
		margin: 0 auto;
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrap-menu .bg-elem{
	span{
		position: absolute;
		img{
			@include imgset;
		}
	}
	span:first-child{
		right: -40/$design-num-sp*100vw;
		bottom: 80/$design-num-sp*100vw;
		img{
			width: 275/$design-num-sp*100vw;
		}
	}
	span:last-child{
		right: 120/$design-num-sp*100vw;
		bottom: 20/$design-num-sp*100vw;
		img{
			width: 150/$design-num-sp*100vw;
		}
	}
}
@media screen and (min-width: $break-point-middle) {
#wrap-menu .bg-elem{
	span{
		position: absolute;
		img{
			@include imgset;
		}
	}
	span:first-child{
		right: -40px;
		bottom: 0;
		img{
			width: 180px;
		}
	}
	span:last-child{
		right: 206px;
		bottom: -20px;
		img{
			width: 146px;
		}
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrap-menu .menu-global{
	display: block;
	>*>.inner{
		>*{
			margin-bottom: 40/$design-num-sp*100vw;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
}
@media screen and (min-width: $break-point-middle) {
#wrap-menu .menu-global{
	width: 640/1090*100%;
	>*>.inner{
		@include setTbl;
		>*{
			vertical-align: top;
			margin-bottom: 0;
		}
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
#wrap-menu .menu-side{
	padding-left: 28px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrap-menu .menu-area{
	padding-top: 40/$design-num-sp*100vw;
	padding-bottom: 40/$design-num-sp*100vw;
	margin-top: 40/$design-num-sp*100vw;
	margin-bottom: 40/$design-num-sp*100vw;
	border-top: 2/$design-num-sp*100vw solid $key-gray1;
	border-bottom: 2/$design-num-sp*100vw solid $key-gray1;
}
@media screen and (min-width: $break-point-middle) {
#wrap-menu .menu-area{
	padding-top: 0;
	padding-bottom: 22px;
	margin-top: 0;
	margin-bottom: 26px;
	border-top: none;
	border-bottom: 2px solid $key-gray1;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrap-menu .tag-area{
	margin-top: 20/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
#wrap-menu .tag-area{
	padding-left: 27px;
	margin-top: 10px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrap-menu .menu-other{
	display: block;
}
@media screen and (min-width: $break-point-middle) {
#wrap-menu .menu-other{
	>.inner{
		@include setTbl;
		>*{
			vertical-align: top;
		}
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrap-menu .col-nav{
	@include setTbl;
	>*{
		vertical-align: top;
		&:first-child{
			width: 45%;
		}
	}
}
@media screen and (min-width: $break-point-middle) {
#wrap-menu .col-nav{
	display: table-cell;
	width: auto;
	>*{
		display: block;
		&:first-child{
			width: auto;
		}
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrap-menu .col-sns{
	margin-top: 48/$design-num-sp*100vw;
	h2{
		color: $key-color;
		font-family: $fontEn;
		@include fz_vw(28);
		font-weight: bold;
		line-height: 1em;
		white-space: nowrap;
		margin-bottom: 20/$design-num-sp*100vw;
	}
	>.inner>ul{
		@include killSpace;
		white-space: nowrap;
	}
	li{
		margin: 0 20/$design-num-sp*100vw 0 0;
		&:last-child{
			margin-right: 0;
		}
	}
	a{
		display: block;
		text-align: center;
		background-color: $white;
		width: 68/$design-num-sp*100vw;
		height: 68/$design-num-sp*100vw;
		border-radius: 100%;
		i{
			line-height: 68/$design-num-sp*100vw;
			@include fz_vw(30);
			color: $key-color;
		}
	}
}
@media screen and (min-width: $break-point-middle) {
#wrap-menu .col-sns{
	width: 210/422*100%;
	>.inner{
		@include setTbl;
	}
	h2{
		width: 3.2em;
		font-size: 1.3rem;
		margin-bottom: 0;
	}
	>.inner>ul{
		text-align: right;
	}
	li{
		margin: 0 2px;
		&:last-child{
			margin: 0 2px;
		}
	}
	a{
		width: 30px;
		height: 30px;
		i{
			line-height: 30px;
			font-size: 1.4rem;
		}
	}
	a:hover{
		background-color: $key-color;
		i{
			color: $white;
		}
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrap-search .sec-freeword{
	position: relative;
	margin-bottom: 70/$design-num-sp*100vw;
	.bg-elem{
		position: absolute;
		right: 40/$design-num-sp*100vw;
		top: -60/$design-num-sp*100vw;
		img{
			@include imgset;
			width: 206/$design-num-sp*100vw;
		}
	}
	.col-search{
		position: relative;
		margin-top: 20/$design-num-sp*100vw;
		@include setTbl;
		.col-input{
			width: 500/$design-num-sp*100vw;
		}
		.col-btn{
			padding-left: 6/$design-num-sp*100vw;
			>*{
				width: 100%;
			}
		}
	}
}
@media screen and (min-width: $break-point-middle) {
#wrap-search .sec-freeword{
	margin-bottom: 48px;
	>.inner{
		padding-right: 160/870*100%;
	}
	.bg-elem{
		right: -40px;
		top: 0;
		img{
			width: 178px;
		}
	}
	.col-search{
		margin-top: 22px;
		.col-input{
			width: 552/710*100%;
		}
		.col-btn{
			width: 150px;
			padding-left: 10px;
		}
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}

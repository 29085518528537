@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.article-style_01{
  a{
    position: relative;
    display: block;
    .eyecatch{
      border-radius: 16/$design-num-sp*100vw;
      overflow: hidden;
      aspect-ratio: 276 / 194;
      position: relative;
      margin-bottom:6/$design-num-sp*100vw;
      background-color: $white;
      &::after{
        content: '';
        border: 1px solid $key-black;
        border-radius: 16/$design-num-sp*100vw;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        display: block;
        width: 100%;
      }
      >span{
        display: block;
        @include opacity(0);
        @include transition(all 0.9s $ease-in-out-circ);
      	@include transform(scale(0));
      	@include transform-origin(50%,50%);
      }
      img{
        @include imgset;
        transition: .6s;
        transform-origin: 50% 50%;
      }
    }
    h2{
      position: relative;
      padding-left: 40/$design-num-sp*100vw;
      clip-path: inset(0 100% 0 0);
      transition:clip-path .8s $ease-out-quad,color .3s;
      .tx{
        @include fz_vw(26);
        line-height: 1em;
        white-space: nowrap;
        @include over_01($key-color);
        padding-bottom: 0;
        position: relative;
        top: 4/$design-num-sp*100vw;
        .nav-content &{
          white-space: normal;
        }
      }
      .icon{
        @include arrow_02;
        .nav-content &{
          top: 7/$design-num-sp*100vw;
        }
      }
    }
  }
  a.current{
    cursor: default;
    pointer-events: none;
    h2{
      .icon{
        background-color: $black;
      }
    }
  }
  &.active{
    a{
      .eyecatch>span{
        @include opacity(1);
    		@include transform(scale(1));
      }
      h2{
        clip-path: inset(0);
      }
    }
  }
  &.style_01{
    display: none;
    .carousel-style_05 &{
      display: block;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_01{
  a{
    .eyecatch{
      border-radius: 8px;
      margin-bottom: 8px;
      &::after{
        border-radius: 8px;
        border: 1px solid $key-black;
      }
    }
    h2{
      padding-left: 27px;
      .tx{
        font-size: 1.5rem;
        top: 0;
      }
      .tx{
        .nav-content &{
          white-space: nowrap;
        }
      }
      .icon{
        .nav-content &{
          top: -webkit-calc(50% - .9rem);
          top: calc(50% - .9rem);
        }
      }
    }
  }
  a:hover{
    .eyecatch{
      img{
        transform: scale(1.05);
      }
    }
    h2{
      color: $key-color;
      .tx{
        &::before{
          width: 100%;
        }
      }
      .icon{
        left: 5px;
        background-color: $key-black;
      }
    }
  }
}
}
//////////////////////////////////////////
.article-style_02{
  @include maskFade_01;
  a{
    display: flex;
    justify-content: space-between;
    position: relative;
    .eyecatch{
      width: 300/$design-num-sp*100vw;
      border-radius: 8px;
      height: 200/$design-num-sp*100vw;
      position: relative;
      overflow: hidden;
      z-index: 1;
      img{
        @include imgset;
        transform-origin: 50% 50%;
        transition: .6s;
        aspect-ratio:300/200;
        object-fit: cover;
      }
    }
    .update{
      @include fz_vw(20);
      line-height: 1em;
      font-family: $fontEn;
      margin-bottom: 10/$design-num-sp*100vw;
      font-weight: 600;
    }
    h2{
      //line-height: (22/19)+em;
      /*safariバグ回避*/
      line-height: 1.2em;
      @include fz_vw(30);
      padding-bottom: 15/$design-num-sp*100vw;
      border-bottom: 1px solid $key-black;
      margin-bottom: 10/$design-num-sp*100vw;
      clip-path: inset(0 100% 0 0);
      transition:clip-path .8s $ease-out-quad;
      transition: .6s;
      >span{
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      &.style_01{
        >span{
          -webkit-line-clamp: 1;
        }
        .ruby{
          line-height: 1.4em;
          display: -webkit-box;
        }
      }
      .sub,.ruby{
        font-weight: normal;
        line-height: 1em;
        @include fz_vw(22);
      }
      .ruby{
        display: block;
        padding-top: 10/$design-num-sp*100vw;
        //padding-left: .5em;
      }
    }
    .ttl-sec{
      position: relative;
      h2{
        >span{
          padding-right: 52%;
        }
      }
    }
    .sub-ttl{
      position: absolute;
      right: 0;
      width: 50%;
      top: 2px;
      color: $key-color;
      line-height: 1.2em;
      @include fz_vw(22);
      font-weight: bold;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .content{
      width: 387/$design-num-sp*100vw;
      opacity: 0;
      transition: .3s;
    }
    .summary{
      line-height: (31/24)+em;
      @include fz_vw(24);
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  &.new a{
    .eyecatch{
      &::before{
        content: 'NEW';
        font-family: $fontEn;
        color: $key-color;
        border: 1px solid $key-color;
        border-radius: 3px;
        @include fz_vw(12);
        line-height: 1em;
        padding: 3/$design-num-sp*100vw 4/$design-num-sp*100vw 0;
        position: absolute;
        left: 20/$design-num-sp*100vw;
        top: 20/$design-num-sp*100vw;
        z-index: 10;
        background-color: $white;
        opacity: 0;
        transition: .3s;
      }
    }
  }
  &.active a{
    h2{
      clip-path: inset(0);
    }
    .content{
      opacity: 1;
    }
  }
  &.active.new a{
    .eyecatch{
      &::before{
        opacity: 1;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_02{
  a{
    display: block;
    .eyecatch{
      width: 100%;
      height: auto;
      aspect-ratio: 370 / 247;
      margin-bottom: 14px;
    }
    .update{
      font-size: 1.0rem;
      margin-bottom: 5px;
    }
    h2{
      font-size: 1.9rem;
      padding-bottom: 10px;
      margin-bottom: 8px;
      .sub,.ruby{
        font-size: 1.3rem;
      }
      .ruby{
        padding-top: 5px;
      }
    }
    .sub-ttl{
      top: 5px;
      font-size: 1.3rem;
    }
    .content{
      width: 100%;
    }
    .summary{
      line-height: (23/17)+em;
      font-size: 1.7rem;
    }
  }
  &.new a{
    .eyecatch{
      &::before{
        font-size: 1.2rem;
        padding: 3px 4px 0;
        left: 10px;
        top: 10px;
      }
    }
  }
  a:hover{
    opacity: .9;
    .eyecatch{
      img{
        transform: scale(1.1);
      }
    }
    h2{
      color: $key-color;
    }
  }
}
}
//////////////////////////////////////////
.article-style_03{
  .post-ttl{
    margin-bottom: 40/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_03{
  .post-ttl{
    margin-bottom: 24px;
    .update{
      font-weight: bold;
      font-family: $fontEn;
      font-size: 1.0rem;
      position: relative;
      top: 10px;
    }
  }
}
}
//////////////////////////////////////////
.article-style_04{
  a{
    display: block;
    .update{
      white-space: nowrap;
      font-family: $fontEn;
      @include fz_vw(20);
      color: $key-gray1;
      transition: .3s;
    }
    h2{
      line-height: (37/30)+em;
      @include fz_vw(30);
      transition: .3s;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_04{
  a{
    >.inner{
      @include setTbl;
      >*{
        vertical-align: top;
      }
    }
    .update{
      font-size: 1.4rem;
    }
    h2{
      line-height: (17/16)+em;
      font-size: 1.6rem;
    }
  }
  a:hover{
    .update,h2{
      color: $key-color;
    }
    h2{
      text-decoration: underline;
    }
  }
  .col-side{
    width: 8em;
  }
}
}
//////////////////////////////////////////
.article-style_05{
  .col-img{
    margin-bottom: 30/$design-num-sp*100vw;
    @include setTbl;
    >*:first-child{
      width: 220/$design-num-sp*100vw;
    }
  }
  .ttl-info{
    padding-left: 15/$design-num-sp*100vw;
  }
  h2{
    @include fz_vw(20);
    font-weight: normal;
    line-height: 1em;
    >span{
      @include fz_vw(40);
      line-height: 1em;
      font-weight: bold;
    }
  }
  .info-sub{
    @include fz_vw(26);
    line-height: 1em;
    color: $key-color;
    margin-top: 12/$design-num-sp*100vw;
  }
  .sns-list{
    @include killSpace;
    margin-top: 20/$design-num-sp*100vw;
    li{
      width: 68/$design-num-sp*100vw;
      margin: 0 20/$design-num-sp*100vw 0 0;
      &:last-child{
        margin-right: 0;
      }
    }
    a{
      display: block;
      width: 100%;
      height: 68/$design-num-sp*100vw;
      border-radius: 100%;
      background-color: $white;
      text-align: center;
      transition: .3s;
      i{
        line-height: 68/$design-num-sp*100vw;
        @include fz_vw(30);
        color: $key-color;
      }
    }
  }
  .large-list{
    line-height: (28/18)+em;
    @include fz_vw(28);
    font-weight: bold;
    margin-bottom: 12/$design-num-sp*100vw;
    li{
      position: relative;
      padding-left: 28/$design-num-sp*100vw;
      &::before{
        content: '';
        display: block;
        width: 12/$design-num-sp*100vw;;
        height: 12/$design-num-sp*100vw;;
        background-color: $black;
        position: absolute;
        left: 0;
        top: 14/$design-num-sp*100vw;;
        border-radius: 100%;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_05{
  @include setTbl;
  >*{
    vertical-align: top;
  }
  .col-img{
    margin-bottom: 0;
    display: table-cell;
    width: 200/660*100%;
    >*:first-child{
      display: block;
      width: 100%;
    }
  }
  .col-content{
    padding-left: 22/660*100%;
  }
  .ttl-info{
    display: block;
    text-align: center;
    padding-left: 0;
    margin-top: 10px;
  }
  h2{
    font-size: 1.3rem;
    >span{
      padding-right: 2px;
      font-size: 1.8rem;
    }
  }
  .info-sub{
    font-size: 1.1rem;
    margin-top: 8px;
  }
  .sns-list{
    margin-top: 10px;
    li{
      width: 26px;
      margin: 0 4px;
    }
    a{
      height: 26px;
      i{
        line-height: 26px;
        font-size: 1.8rem;
      }
    }
    a:hover{
      background-color: $key-color;
      i{
        color: $white;
      }
    }
  }
  .large-list{
    font-size: 1.8rem;
    margin-bottom: 12px;
    li{
      padding-left: 14px;
      &::before{
        width: 8px;
        height: 8px;
        top: 12px;
      }
    }
  }
}
}
//////////////////////////////////////////
.article-style_06{
  >a>.inner,>.inner{
    @include setTbl;
    >*{
      vertical-align: top;
    }
  }
  .col-img{
    width: 300/$design-num-sp*100vw;
    >*{
      overflow: hidden;
      border-radius: 16px;
      z-index: 1;
    }
    .item{
      transform-origin: 50% 50%;
      transition: .6s;
    }
  }
  .col-content{
    padding-left: 22/$design-num-sp*100vw;
  }
  .sec-ttl{
    margin-bottom: 20/$design-num-sp*100vw;
    >p{
      @include fz_vw(26);
    }
  }
  h2{
    @include fz_vw(30);
    line-height: 1.6em;
  }
  h3{
    line-height: (34/26)+em;
    @include fz_vw(26);
    color: $key-color;
    margin-bottom: 20/$design-num-sp*100vw;
  }
  h2,h3,.summary,.sec-ttl{
    transition: .3s;
  }
  .summary{
    line-height: (31/24)+em;
    @include fz_vw(24);
  }
  &.style_01{
    >a>.inner,>.inner{
      display: block;
      >*{
        display: block;
      }
    }
    .col-img{
      width: 100%;
      margin-bottom: 30/$design-num-sp*100vw;
      img{
        aspect-ratio:710/474;
        object-fit: cover;
      }
    }
    .col-content{
      padding-left: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_06{
  >a>.inner,>.inner{
    @include setTbl;
    >*{
      vertical-align: middle;
    }
  }
  .col-img{
    width: 260/720*100%;
    overflow: hidden;
    border-radius: 16px;
    z-index: 1;
    >*{
      border-radius: 0;
    }
  }
  .col-content{
    padding-left: 24/720*100%;
  }
  .sec-ttl{
    margin-bottom: 16px;
    >p{
      font-size: 1.2rem;
    }
  }
  h2{
    font-size: 2.2rem;
  }
  h3{
    line-height: (24/20)+em;
    font-size: 2.0rem;
    margin-bottom: 11px;
  }
  .summary{
    line-height: (22/15)+em;
    font-size: 1.5rem;
  }
  >a:hover{
    opacity: .9;
    h2,h3,.summary,.sec-ttl{
      color: $key-color;
    }
    .col-img{
      .item{
        transform: scale(1.03);
      }
    }
  }
  &.style_01{
    >a>.inner,>.inner{
      @include setTbl;
      >*{
        vertical-align: middle;
      }
    }
    .col-img{
      width: 260/720*100%;
      margin-bottom: 0;
      img{
        aspect-ratio:1/1;
      }
    }
    .col-content{
      padding-left: 24/720*100%;
    }
  }
}
}
//////////////////////////////////////////
.article-style_07{
  a{
    display: block;
    .eyecatch{
      overflow: hidden;
      border-radius: 8px;
      aspect-ratio:340/227;
      margin-bottom: 12/$design-num-sp*100vw;
      img{
        @include imgset;
        transition: .6s;
        transform-origin: 50% 50%;
      }
    }
    h2{
      @include fz_vw(30);
      line-height: 1.6em;
      position: relative;
      display: inline-block;
      color: $key-color;
      @include over_01($key-color);
      padding-bottom: 0;
      &.style_01{
        &::after{
          font-weight: normal;
          @include iconFont;
          content: '\EA10';
          @include fz_vw(20);
          line-height: 1em;
          position: relative;
          top: -4/$design-num-sp*100vw;
          right: -2/$design-num-sp*100vw;
        }
      }
    }
    .info{
      @include fz_vw(22);
      line-height: 1.6em;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_07{
  a{
    .eyecatch{
      border-radius: 16px;
      margin-bottom: 12px;
    }
    h2{
      font-size: 1.9rem;
      &.style_01{
        &::after{
          font-size: 1.0rem;
          top: -8px;
          right: -2px;
        }
      }
    }
    .info{
      font-size: 1.3rem;
    }
  }
  a:hover{
    opacity: .8;
    .eyecatch{
      img{
        transform: scale(1.05);
      }
    }
    h2{
      &::before{
        width: 100%;
      }
    }
  }
}
}
//////////////////////////////////////////
.article-style_08{
  position: relative;
  padding-bottom: 166/$design-num-sp*100vw;
  a{
    .eyecatch{
      >span{
        display: block;
      }
      img{
        width: 100%;
        height: 585/$design-num-sp*100vw!important;
        object-fit: cover;
        //object-position: 0 50%;
      }
    }
    .content{
      background-color: $white;
      border-radius: 16/$design-num-sp*100vw;
      width: 620/$design-num-sp*100vw;
      position: absolute;
      bottom: 0;
      left: $sp-pad;
      overflow: hidden;
      transition: .5s $ease-in-out-quad .5s;
      opacity: 0;
      transform: scale(0);
      .swiper-slide-active &{
        opacity: 1;
        transform: scale(1);
      }
      .bg-elem{
        transform: rotate(-270deg);
        position: absolute;
        right: -20/$design-num-sp*100vw;;
        bottom: -20/$design-num-sp*100vw;;
        svg{
          width: 368/$design-num-sp*100vw;
          height: 368/$design-num-sp*100vw;
          fill:$bg-color1;
        }
      }
      >.inner{
        padding: 30/$design-num-sp*100vw;
        position: relative;
      }
    }
    h2{
      line-height: 1.2em;
      @include fz_vw(38);
      padding-bottom: 20/$design-num-sp*100vw;
      margin-bottom: 15/$design-num-sp*100vw;
      border-bottom: 1px solid $key-black;
      >span{
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .sub-info{
      padding-right: 10em;
      position: relative;
    }
    .update{
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_08{
  padding-bottom: 56px;
  a{
    .eyecatch{
      img{
        height: 550px!important;
      }
    }
    .content{
      border-radius: 16px;
      width: 509/$design-num*100vw;
      max-width: 509px;
      left: 30px;
      .bg-elem{
        right: -10%;
        top: -60%;
        svg{
          width: 322px;
          height: 322px;
        }
      }
      >.inner{
        padding: 30px;
      }
    }
    h2{
      line-height: 1.2em;
      font-size: 2.6rem;
      padding-bottom: 10px;
      margin-bottom: 6px;
      border-bottom: 1px solid $key-black;
    }
  }
}
}
//////////////////////////////////////////
.article-style_09{
  @include maskFade_01;
  a{
    display:block;
    border-radius: 16/$design-num-sp*100vw;
    overflow: hidden;
    z-index: 1;
    position: relative;
    >.inner{
      position: relative;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: block;
        background-color: rgba(0, 0, 0, .15);
        z-index: 2;
        transition: .3s;
      }
    }
    .eyecatch{
      position: relative;
      z-index: 1;
      img{
        position: relative;
        @include imgset;
        aspect-ratio:16/9;
        object-fit: cover;
        transform-origin: 50% 50%;
        transition: .6s;
      }
    }
    .content{
      position: absolute;
      left: 24/$design-num-sp*100vw;
      bottom: 20/$design-num-sp*100vw;
      z-index: 3;
      opacity: 0;
      transition: .3s;
      width: 570/$design-num-sp*100vw;
    }
    h2{
      color: $white;
      line-height: 1.2em;
      @include fz_vw(38);
      padding-bottom: 20/$design-num-sp*100vw;
      border-bottom: 1px solid $white;
      margin-bottom: 13/$design-num-sp*100vw;
      clip-path: inset(0 100% 0 0);
      transition:clip-path .8s $ease-out-quad;
      transition: .6s;
      >span{
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  &.active a{
    h2{
      clip-path: inset(0);
    }
    .content{
      opacity: 1;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_09{
  a{
    border-radius: 16px;
    .eyecatch{
      img{
        aspect-ratio:1/1;
      }
    }
    .content{
      left: 20px;
      bottom: 20px;
      width: 360/624*100%;
    }
    h2{
      font-size: 2.2rem;
      padding-bottom: 10px;
      margin-bottom: 8px;
    }
  }
  a:hover{
    opacity: .9;
    >.inner{
      &::before{
        opacity: 0;
      }
    }
    .eyecatch{
      img{
        transform: scale(1.02);
      }
    }
  }
}
}
//////////////////////////////////////////
.article-style_10{
  @extend .article-style_02;
  a{
    display: block;
    .eyecatch{
      width: 100%;
      aspect-ratio: 1 / 1;
      margin-bottom: 17/$design-num-sp*100vw;
      height: auto;
      img{
        aspect-ratio: 1 / 1;
      }
    }
    h2{
      @include fz_vw(30);
      margin-bottom: 10/$design-num-sp*100vw;
    }
    .content{
      width: 100%;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_10{
  a{
    .eyecatch{
      margin-bottom: 10px;
    }
    h2{
      font-size: 1.6rem;
      margin-bottom: 5px;
    }
  }
}
}
//////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
.article-style_11{
  background-color: $white;
  border-radius: 16px;
  >.inner{
    padding: 40px;
  }
}
}
//////////////////////////////////////////
.article-style_11 .parent-content{
  @include maskFade_01;
  margin-bottom: 27/$design-num-sp*100vw;
  a{
    position: relative;
    display: block;
    >.inner{
      position: relative;
      border-radius: 16px;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }
    .label-map{
      background-color: $bg-color1;
      border-radius: 0 0 0 16px;
      color: $key-color;
      font-family: $fontEn;
      text-align: center;
      position: absolute;
      z-index: 10;
      top: -1px;
      right: 0;
      >.inner{
        padding: 0 18/$design-num-sp*100vw 6/$design-num-sp*100vw 32/$design-num-sp*100vw;
      }
      .label-en{
        @include fz_vw(22);
        line-height: 1em;
        letter-spacing: .05em;
      }
      .label-num{
        @include fz_vw(46);
        line-height: 1em;
        font-weight: 600;
        letter-spacing: .05em;
      }
    }
    .eyecatch-parent{
      img{
        @include imgset;
        aspect-ratio:750/500;
        transform-origin: 50% 50%;
        transition: .6s;
      }
    }
    h2{
      position: absolute;
      top: 44/$design-num-sp*100vw;
      left: 14/$design-num-sp*100vw;
      transition: .6s;
      opacity: 0;
      >span.wrap{
        display: flex;
        align-items: flex-start;
      }
      >span.wrap>span{
        display: block;
        text-orientation: upright;
        writing-mode: vertical-rl;
        line-height: 1em;
        @include fz_vw(38);
        color: $white;
        background-color: $key-color;
        padding: 12/$design-num-sp*100vw;
        border-radius: 4px;
        margin-right: 8/$design-num-sp*100vw;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .en{
      position: absolute;
      @include fz_vw(24);
      line-height: 1em;
      font-family: $fontEn;
      right: 20/$design-num-sp*100vw;
      bottom: 10/$design-num-sp*100vw;
      font-weight: bold;
      color: $white;
      transition: .6s;
      opacity: 0;
    }
  }
  &.active a{
    h2{
      opacity: 1;
      top: 24/$design-num-sp*100vw;
    }
    .en{
      opacity: 1;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_11 .parent-content{
  margin-bottom: 16px;
  a{
    >.inner{
      border-radius: 8px;
    }
    .label-map{
      background-color: $white;
      top: 0;
      >.inner{
        padding: 0 12px 6px 23px;
      }
      .label-en{
        font-size: 1.3rem;
      }
      .label-num{
        font-size: 2.6rem;
      }
    }
    h2{
      top: 24px;
      left: 14px;
      >span.wrap>span{
        font-size: 2.2rem;
        padding: 6px;
        margin-right: 4px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .en{
      font-size: 1.4rem;
      right: 20px;
      bottom: 10px;
    }
  }
  &.active a{
    h2{
      top: 14px;
    }
  }
  a:hover{
    opacity: .9;
    .eyecatch-parent{
      img{
        transform: scale(1.05);
      }
    }
  }
}
}
//////////////////////////////////////////
.article-style_12{
  @include maskFade_01;
  a{
    display: block;
    >.inner{
      @include setTbl;
    }
    .col-eyecatch{
      width: 300/$design-num-sp*100vw;
      position: relative;
    }
    .eyecatch{
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      z-index: 1;
      img{
        @include imgset;
        aspect-ratio:3/2;
        transform-origin: 50% 50%;
        transition: .6s;
      }
    }
    .content{
      padding-left:18/$design-num-sp*100vw;
      opacity: 0;
    }
    .update{
      @include fz_vw(20);
      line-height: 1em;
      font-family: $fontEn;
      margin-bottom: 10/$design-num-sp*100vw;
      font-weight: 600;
    }
    h2{
      line-height: 1.2em;
      @include fz_vw(30);
      padding-bottom: 12/$design-num-sp*100vw;
      border-bottom: 1px solid $key-black;
      margin-bottom: 10/$design-num-sp*100vw;
      clip-path: inset(0 100% 0 0);
      transition:clip-path .8s $ease-out-quad;
      >span{
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  &.active a{
    h2{
      clip-path: inset(0);
    }
    .content{
      opacity: 1;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_12{
  a{
    .col-eyecatch{
      width: 180/460*100%;
    }
    .content{
      padding-left: 14/460*100%;
    }
    .update{
      font-size: 1.0rem;
      margin-bottom: 5px;
    }
    h2{
      font-size: 1.6rem;
      padding-bottom: 9px;
      margin-bottom: 8px;
    }
  }
  a:hover{
    opacity: .9;
    color: $key-color;
    .eyecatch{
      img{
        transform: scale(1.05);
      }
    }
  }
}
}
//////////////////////////////////////////
.article-style_13{
  @extend .article-style_09;
  a{
    .eyecatch{
      img{
        aspect-ratio:710/399;
      }
    }
    .content{
      left: 20/$design-num-sp*100vw;
      width: 430/$design-num-sp*100vw;
    }
    .update{
      line-height: 1em;
      @include fz_vw(20);
      font-family: $fontEn;
      margin-bottom: 8/$design-num-sp*100vw;
      color: $white;
      font-weight: bold;
    }
    h2{
      padding-bottom: 0px;
      border-bottom: none;
      margin-bottom: 0px;
    }
    .sec-btn{
      position: absolute;
      opacity: 0;
      transition: .6s;
      right: 20/$design-num-sp*100vw;
      bottom: 16/$design-num-sp*100vw;
      z-index: 3;
      >*{
        min-width: 220/$design-num-sp*100vw;
      }
    }
  }
  &.active a{
    .sec-btn{
      opacity: 1;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_13{
  a{
    .eyecatch{
      img{
        aspect-ratio:3/2;
      }
    }
    .content{
      left: 16px;
      width: 338/520*100%;
    }
    .update{
      font-size: 1.0rem;
      margin-bottom: 4px;
    }
    .sec-btn{
      right: 16px;
      bottom: 20px;
      >*{
        min-width: 120px;
      }
    }
  }
  a:hover{
    .sec-btn{
      >*{
        &::after{
          right: 6px;
        }
        &::before{
          left: 0;
        }
        &:hover{
          pointer-events: none;
        }
      }
    }
  }
}
}
//////////////////////////////////////////
.article-style_14{
  @extend .article-style_12;
  a{
    >.inner{
      display: block;
      >*{
        display: block;
      }
    }
    .col-eyecatch{
      width: 100%;
      margin-bottom: 20/$design-num-sp*100vw;
    }
    .eyecatch{
      img{
        aspect-ratio:1/1;
      }
    }
    .content{
      padding-left: 0;
    }
    h2{
      line-height: 1.2em;
      @include fz_vw(30);
      //padding-bottom: 5px;
      margin-bottom: 20/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_14{
  a{
    >.inner{
      @include setTbl;
    }
    .col-eyecatch{
      width: 200/530*100%;
      margin-bottom: 0;
    }
    .content{
      padding-left: 20/530*100%;
    }
    h2{
      font-size: 1.8rem;
      margin-bottom: 10px;
      //padding-bottom: 5px;
      //margin-bottom: 5px;
    }
  }
}
}
//////////////////////////////////////////
.article-style_15{
  @include maskFade_01;
  padding-left: 40/$design-num-sp*100vw;
  display: block;
  a{
    display: block;
    >.wrap{
      position: relative;
      &::before{
        content: '';
        background-color: $white;
        border-radius: 16px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform-origin: 50% 50%;
        transition: .6s $ease-out-expo;
        transform: scale(0);
      }
    }
    >.wrap>.inner{
      padding: 46/$design-num-sp*100vw 40/$design-num-sp*100vw;
      position: relative;
    }
    .eyecatch{
      border-radius: 100%;
      overflow: hidden;
      transition: .6s;
      position: absolute;
      top: 46/$design-num-sp*100vw;
      left: -40/$design-num-sp*100vw;
      width: 240/$design-num-sp*100vw;
      opacity: 0;
      z-index: 1;
      img{
        @include imgset;
        aspect-ratio:1/1;
        transform-origin: 50% 50%;
        transition: .6s;
      }
    }
    .content{
      //transition: .6s;
      opacity: 0;
      padding-left: 220/$design-num-sp*100vw;
      position: relative;
    }
    h2{
      overflow: hidden;
      line-height: 1em;
      @include fz_vw(32);
      margin-bottom: 20/$design-num-sp*100vw;
      .tx-small{
        @include fz_vw(20);
        line-height: 1em;
      }
      >span{
        top: 4rem;
        transition: .4s $ease-out-quart;
        position: relative;
      }
    }
    h3{
      color: $key-color;
      @include fz_vw(22);
      font-weight: normal;
      text-align: center;
      position: absolute;
      top: 0;
      right: 0;
    }
    .summary{
      @include fz_vw(24);
      line-height: 1.2em;
      padding-bottom: 24/$design-num-sp*100vw;
      border-bottom: 1px solid $key-black;
      margin-bottom: 20/$design-num-sp*100vw;
      p{
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    .content{
      position: relative;
    }
  }
  &.active a{
    h2{
      >span{
        top: 0;
      }
    }
    .content{
      opacity: 1;
    }
    >.wrap{
      &::before{
        transform: scale(1);
      }
    }
    .eyecatch{
      opacity: 1;
    }
  }
  a:hover{
    opacity: .9;
    color: $key-color;
    .eyecatch{
      img{
        transform: scale(1.03);
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_15{
  padding-left: 0;
  padding-top: 28px;
  a{
    >.wrap>.inner{
      padding: 0 38px 20px;
    }
    .eyecatch{
      position: relative;
      top: 0px;
      left: 0;
      width: 200px;
      margin: 0 auto 10px;
    }
    h2{
      text-align: center;
      font-size: 1.8rem;
      margin-bottom: 0;
      .tx-small{
        font-size: 1.3rem;
      }
    }
    h3{
      font-size: 1.3rem;
      position: relative;
      margin-bottom: 10px;
    }
    .summary{
      font-size: 1.3rem;
      padding-bottom: 14px;
      margin-bottom: 10px;
    }
    .content{
      margin-top: -28px;
      padding-left: 0;
    }
  }
  &.active a{
    .eyecatch{
      top: -28px;
    }
  }
  a:hover{
    opacity: .9;
    color: $key-color;
    .eyecatch{
      img{
        transform: scale(1.03);
      }
    }
  }
}
}
//////////////////////////////////////////
.article-style_16{
  @extend .article-style_01;
  @include maskFade_01;
  a{
    .eyecatch{
      aspect-ratio: 750 / 500;
      z-index: 1;
      &::after{
        display: none;
      }
      >span{
        opacity: 1;
        @include transition(.7s);
        @include scale(1,1);
        @include transform-origin(100%,0%);
      }
    }
    &::after{
      @include iconFont;
      content:'\EA18';
      line-height: 1em;
      @include fz_vw(118);
      color: $white;
      position: absolute;
      top: calc(50% - .6em);
      left: calc(50% - .5em);
      transition: .3s;
      z-index: 2;
    }
  }
  &.killer-link a{
    cursor: default;
    pointer-events: none;
    &::after{
      display: none;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_16{
  a{
    &::after{
      font-size: 5.4rem;
      top: calc(50% - 3.5rem);
      left: calc(50% - 2.7rem);
    }
  }
  a:hover{
    opacity: .8;
    &::after{
      color: $key-color;
    }
  }
}
}
//////////////////////////////////////////
.article-style_17{
  @extend .article-style_07;
  @include maskFade_01;
  a{
    .eyecatch{
      margin-bottom: 10/$design-num-sp*100vw;
      >span{
        &::after{
          background-color: $key-black;
        }
      }
    }
    h2{
      @include fz_vw(30);
      color: $white;
      @include over_01($white);
    }
    .info{
      color: $white;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_17{
  a{
    .eyecatch{
      margin-bottom: 18px;
    }
    h2{
      font-size: 2.2rem;
    }
  }
  a:hover{
    opacity: 1;
    h2{
      &::before{
        width: 100%;
      }
    }
  }
}
}
//////////////////////////////////////////
.article-style_18{
  @extend .article-style_02;
  a{
    display: block;
    .eyecatch{
      width: 340/$design-num-sp*100vw;
      height: 340/$design-num-sp*100vw;
      margin-bottom: 20/$design-num-sp*100vw;
      img{
        aspect-ratio:340/340;
      }
    }
    .content{
      width: auto;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_18{
  a{
    .eyecatch{
      width: 100%;
      height: auto;
      margin-bottom: 14px;
    }
  }
}
}
//////////////////////////////////////////
.article-style_19{
  background-color: $bg-color2;
  @include border-radius(8px);
  margin-bottom: 20/$design-num-sp*100vw;
  &:last-child{
    margin-bottom: 0;
  }
  >.inner{
    padding: 20/$design-num-sp*100vw;
  }
  .sec-ttl{
    position: relative;
    cursor: pointer;
    transition: .3s;
    .arrow{
      @include fz_vw(30);
      line-height: 1em;
      position: absolute;
      right: 0;
      top: -webkit-calc(50% - .8rem);
      top: calc(50% - .8rem);
      transition: .3s;
    }
  }
  &.active .sec-ttl{
    .arrow{
      transform: rotate(180deg);
    }
  }
  h2{
    .icon{
      width: 36/$design-num-sp*100vw;
      height: 36/$design-num-sp*100vw;
      border-radius: 100%;
      background-color: $key-color;
      color: $white;
      @include fz_vw(20);
      text-align: center;
      display: inline-block;
      margin-right: 10/$design-num-sp*100vw;
      position: relative;
      top: -1px;
      i{
        line-height: 36/$design-num-sp*100vw;
      }
    }
    .tx{
      @include fz_vw(28);
      line-height: 1em;
    }
  }
  .sec-content{
    padding-top: 20/$design-num-sp*100vw;
    display: none;
  }
  .summary{
    line-height: (36/26)+em;
    @include fz_vw(26);
  }
  .sec-additional{
    border-top: 1px solid $key-black;
    margin-top: 24/$design-num-sp*100vw;
    padding-top: 24/$design-num-sp*100vw;
  }
  .name{
    @include fz_vw(28);
    line-height: 1em;
    font-weight: bold;
    margin-bottom: 16/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_19{
  border-radius: 8px;
  margin-bottom: 16px;
  &:last-child{
    margin-bottom: 0;
  }
  >.inner{
    padding: 14px 16px;
  }
  .sec-ttl{
    .arrow{
      font-size: 1.6rem;
    }
    &:hover{
      opacity: .8;
    }
  }
  h2{
    .icon{
      width: 28px;
      height: 28px;
      font-size: 1.6rem;
      margin-right: 8px;
      top: 0;
      i{
        line-height: 28px;
      }
    }
    .tx{
      font-size: 2.0rem;
    }
  }
  .sec-content{
    padding-top: 16px;
  }
  .summary{
    line-height: (26/16)+em;
    font-size: 1.6rem;
  }
  .sec-additional{
    margin-top: 16px;
    padding-top: 16px;
  }
  .name{
    font-size: 2.0rem;
    margin-bottom: 10px;
  }
}
}
//////////////////////////////////////////
.article-style_20{
  >.inner{
    @include setTbl;
    >*{
      vertical-align: top;
    }
  }
  .eyecatch{
    img{
      @include imgset;
      aspect-ration:1/1;
      border-radius: 16/$design-num-sp*100vw;
    }
  }
  .content{
    padding-top: 26/$design-num-sp*100vw;
    padding-left: 20/$design-num-sp*100vw;
  }
  h2{
    @include fz_vw(30);
    line-height: 1.6em;
    margin-bottom:12/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_20{
  >.inner{
    display: block;
    >*{
      display: block;
    }
  }
  .eyecatch{
    margin-bottom: 8px;
    img{
      border-radius: 16px;
    }
  }
  .content{
    padding: 0;
  }
  h2{
    font-size: 1.9rem;
    margin-bottom: 8px;
  }
}
}
//////////////////////////////////////////
.article-style_21{
  background-color: $bg-color2;
  border-radius: 8/$design-num-sp*100vw;
  >.inner{
    padding: 30/$design-num-sp*100vw 20/$design-num-sp*100vw;
  }
  .sec-ttl{
    @include setTbl;
    margin-bottom: 18/$design-num-sp*100vw;
    .avater{
      width: 101/$design-num-sp*100vw;
      .img-style{
        display: block;
        width: 101/$design-num-sp*100vw;
        height: 101/$design-num-sp*100vw;
        border-radius: 100%;
        overflow: hidden;
        img{
          @include imgset;
        }
      }
    }
    h2{
      padding-left: 12/$design-num-sp*100vw;
      @include fz_vw(28);
      line-height: 1em;
    }
  }
  .summary{
    line-height: (34/24)+em;
    @include fz_vw(24);
  }
}
@media screen and (min-width: $break-point-middle) {
.article-style_21{
  border-radius: 8px;
  >.inner{
    padding: 26px 36px;
  }
  .sec-ttl{
    margin-bottom: 14px;
    .avater{
      width: 71px;
      .img-style{
        width: 71px;
        height: 71px;
      }
    }
    h2{
      padding-left: 10px;
      font-size: 1.8rem;
    }
  }
  .summary{
    line-height: (22/15)+em;
    font-size: 1.5rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}

@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.ttl-style_01{
  @include fz_vw(36);
  font-weight: bold;
  line-height: 1em;
  .global-keyword &{
    text-align: center;
  }
  .ja{
    display: inline-block;
    overflow: hidden;
    >*{
      top: 4rem;
      transition: .4s $ease-out-quart;
      position: relative;
    }
  }
  .en{
    opacity: 0;
    transition: .4s;
    color: $key-gray1;
    @include fz_vw(20);
    line-height: 1em;
    padding-left: 8/$design-num-sp*100vw;
    font-family: $fontEn;
    .global-keyword &{
      display: block;
      margin-top: 10/$design-num-sp*100vw;
      padding-left: 0;
    }
  }
  &.active{
    .ja{
      >*{
        top: 0;
      }
    }
    .en{
      opacity: 1;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_01{
  font-size: 2.5rem;
  .global-keyword &{
    text-align: left;
  }
  .en{
    font-size: 1.4rem;
    padding-left: 7px;
    .global-keyword &{
      display: inline;
      margin-top: 0;
      padding-left: 7px;
    }
  }
}
}
///////////////////////////////////
.ttl-style_02{
  @extend .ttl-style_01;
  .en{
    display: block;
    margin-top: 10/$design-num-sp*100vw;
    padding-left: 0;
  }
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_02{
  .en{
    margin-top: 8px;
  }
}
}
///////////////////////////////////
.ttl-style_03{
  @extend .ttl-style_02;
  text-align: center;
  .ja{
    display: block;
    .icon{
      i{
        @include fz_vw(40);
        line-height: 1em;
        position: relative;
        left: -10/$design-num-sp*100vw;
      }
    }
  }
  .menuOpen &{
		opacity: 0;
	}
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_03{
  .ja{
    padding-top: 3px;
    .icon{
      i{
        font-size: 3.0rem;
        top: -3px;
        left: -5px;
      }
    }
  }
}
}
///////////////////////////////////
.ttl-style_04{
  border-bottom: 2/$design-num-sp*100vw solid $key-black;
  padding-bottom: 20/$design-num-sp*100vw;
  margin-bottom: 14/$design-num-sp*100vw;
  clip-path: inset(0 100% 0 0);
  transition:clip-path .8s $ease-out-quad,color .3s;
  h1{
    @include fz_vw(50);
    line-height: 1.6em;
    .ruby{
      @include fz_vw(30);
      padding-left: .5em;
    }
    &.style_01{
      .main{
        display: block;
        line-height: 1.2em;
      }
      .ruby{
        display: block;
        line-height: 1.4em;
        padding-top: 10/$design-num-sp*100vw;
      }
    }
  }
  .ttl-sub{
    @include fz_vw(26);
    line-height: 1.6em;
  }
  .loaded &{
    clip-path: inset(0);
  }
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_04{
  border-bottom: 2px solid $key-black;
  padding-bottom: 10px;
  margin-bottom: 10px;
  h1{
    font-size: 4.2rem;
    .ruby{
      font-size: 2.2rem;
    }
    &.style_01{
      .ruby{
        padding-top: 5px;
      }
    }
  }
  .ttl-sub{
    font-size: 1.6rem;
  }
}
}
///////////////////////////////////
.ttl-style_05{
  @extend .ttl-style_01;
  .ja{
    position: relative;
    padding-left: 55/$design-num-sp*100vw;
    overflow: visible;
    >*{
      top: 0;
    }
  }
  .icon{
    top: -4/$design-num-sp*100vw;
    left: 0;
    width: 44/$design-num-sp*100vw;
    height: 44/$design-num-sp*100vw;
    background-color: $key-color;
    border-radius: 100%;
    text-align: center;
    display: block;
    position: absolute;
    i{
      color: $white;
      line-height: 44/$design-num-sp*100vw;
      @include fz_vw(20);
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_05{
  .ja{
    padding-left: 40px;
  }
  .icon{
    top: -2px;
    width: 32px;
    height: 32px;
    i{
      line-height: 32px;
      font-size: 1.6rem;
    }
  }
}
}
///////////////////////////////////
.ttl-style_06{
  padding-bottom: 14/$design-num-sp*100vw;
  border-bottom: 2px solid $key-black;
  position: relative;
  margin-bottom: 25/$design-num-sp*100vw;
  h3{
    @extend .ttl-style_01;
  }
  .bg-elem{
    position: absolute;
    right: 0;
    top: -30/$design-num-sp*100vw;
    >span{
      //mix-blend-mode: multiply;
    }
    img{
      @include imgset;
      //mix-blend-mode: multiply;
      width: 148/$design-num-sp*100vw;
    }
    &.style_01{
      right: 0px;
      top: -40/$design-num-sp*100vw;
      img{
        width: 194/$design-num-sp*100vw;
      }
    }
    &.style_02{
      right: 0/$design-num-sp*100vw;
      top: -12/$design-num-sp*100vw;
      img{
        width: 158/$design-num-sp*100vw;
      }
    }
    &.style_03{
      right: 0;
      top: 0/$design-num-sp*100vw;;
      img{
        width: 199/$design-num-sp*100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_06{
  padding-bottom: 11px;
  border-bottom: 2px solid $key-black;
  margin-bottom: 30px;
  .bg-elem{
    top: -20px;
    img{
      width: 106px;
    }
    &.style_01{
      right: 13px;
      top: -24px;
      img{
        width: 120px;
      }
    }
    &.style_02{
      right: 8px;
      top: -17px;
      img{
        width: 122px;
      }
    }
    &.style_03{
      right: -7px;
      top: -17px;
      img{
        width: 199px;
      }
    }
  }
}
}
///////////////////////////////////
.ttl-style_07{
  @include fz_vw(30);
  line-height: 1.6em;
  margin-bottom: 20/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_07{
  font-size: 2.0rem;
  margin-bottom: 10px;
}
}
///////////////////////////////////
.ttl-style_08{
  @include fz_vw(36);
  line-height: 1em;
  .ja{
    overflow: hidden;
    display: block;
    margin-bottom: 10/$design-num-sp*100vw;
    >*{
      position: relative;
      top: 4rem;
      transition: .4s $ease-out-quart;
    }
  }
  .en{
    opacity: 0;
    transition: .4s;
    display: block;
    @include fz_vw(20);
    color: $key-gray1;
    line-height: 1em;
    text-orientation: sideways;
    font-family: $fontEn;
    font-weight: normal;
  }
  &.active{
    .ja{
      >*{
        top: 0;
      }
    }
    .en{
      opacity: 1;
    }
  }
}
.ttl-style_08.style_01{
  writing-mode: vertical-rl;
  position: absolute;
  left: 40/$design-num-sp*100vw;
  top: 0;
  .ja{
    text-orientation: upright;
  }
  .en{
    padding-right: 14/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_08{
  writing-mode: vertical-rl;
  font-size: 2.5rem;
  position: absolute;
  left: 40px;
  top: 0;
  .ja{
    text-orientation: upright;
    margin-bottom: 0;
    >*{
      top: auto;
      right: 4rem;
    }
  }
  .en{
    font-size: 1.4rem;
    padding-right: 7px;
  }
  &.active{
    .ja{
      >*{
        top: auto;
        right: 0;
      }
    }
  }
}
.ttl-style_08.style_01{
  left: 40px;
  .en{
    padding-right: 7px;
  }
}
}
///////////////////////////////////
.ttl-style_09{
  line-height: 1em;
  @include fz_vw(32);
  margin-bottom: 20/$design-num-sp*100vw;
  overflow: hidden;
  >span{
    display: block;
    position: relative;
    transition: .4s $ease-out-quart;
    top: 4rem;
  }
  .icon{
    display: inline-block;
  }
  &.active{
    >span{
      top: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_09{
  font-size: 1.8rem;
  margin-bottom: 12px;
}
}
///////////////////////////////////
.ttl-style_10{
  @extend .ttl-style_03;
  .ja{
    color: $white;
  }
  .en{
    color: $bg-color3;
  }
}
///////////////////////////////////
.ttl-style_11{
  @include fz_vw(36);
  line-height: 1.2em;
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_11{
  position: absolute;
  left: 40px;
  top: 10px;
  line-height: 1em;
  font-size: 2.5rem;
}
}
///////////////////////////////////
.ttl-style_12{
  font-size: 2.5rem;
  line-height: 1.6em;
  color: $key-color;
  margin-bottom: 20px;
}
///////////////////////////////////
.ttl-style_13{
  @extend .ttl-style_01;
  color: $white;
  .en{
    color: $bg-color3;
  }
  .ja{
    display: block;
  }
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_13{
  .ja{
    display: inline-block;
  }
}
}
///////////////////////////////////
.ttl-style_14{
  @extend .ttl-style_11;
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_14{
  position: relative;
  top: auto;
  left: auto;
}
}
///////////////////////////////////
.ttl-style_15{
  @extend .ttl-style_04;
  h1{
    @include fz_vw(42);
  }
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_15{
  h1{
    font-size: 4.2rem;
  }
}
}
///////////////////////////////////
.ttl-style_16{
  line-height: 1em;
  @include fz_vw(38);
  text-align: center;
  margin-bottom: 30/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_16{
  font-size: 3.2rem;
  margin-bottom: 24px;
}
}
///////////////////////////////////
.ttl-style_17{
  @include fz_vw(30);
  color: $key-color;
  margin-bottom: 16/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_17{
  font-size: 2.2rem;
  margin-bottom: 12px;
}
}
///////////////////////////////////
.ttl-style_18{
  line-height: 1em;
  @include fz_vw(70);
  padding-top: 25/$design-num-sp*100vw;
  padding-bottom: 80/$design-num-sp*100vw;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_18{
  font-size: 6.4rem;
  padding-top: 30px;
  padding-bottom: 75px;
}
}
///////////////////////////////////
.ttl-style_19{
  color: $key-color;
  @include fz_vw(28);
  line-height: 1.6em;
  margin-bottom: 15/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_19{
  font-size: 1.5rem;
  margin-bottom: 5px;
}
}
///////////////////////////////////
.ttl-style_20{
  line-height: (50/42)+em;
  @include fz_vw(42);
  text-align: center;
  margin-bottom: 20/$design-num-sp*100vw;
  color: $key-color;
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_20{
  line-height: (48/40)+em;
  font-size: 4.0rem;
  margin-bottom: 20px;
}
}
///////////////////////////////////
.ttl-style_21{
  line-height: (50/38)+em;
  @include fz_vw(38);
  text-align: center;
  margin-bottom: 20/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.ttl-style_21{
  line-height: (35/28)+em;
  font-size: 2.8rem;
  margin-bottom: 20px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.btn-style_01,.mf_finder_searchBox_items .mf_finder_searchBox_submit{
  @include btnsetting;
  background-color: $key-color;
  border: 2/$design-num-sp*100vw solid $key-black;
  line-height: 1em;
  @include fz_vw(38);
  white-space: nowrap;
  color: $white;
  min-height: 120/$design-num-sp*100vw;
  border-radius: 8px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  padding: 0;
  >span{
    position: relative;
  }
}
@media screen and (min-width: $break-point-middle) {
.btn-style_01,.mf_finder_searchBox_items .mf_finder_searchBox_submit{
  border: 1px solid $key-black;
  font-size: 1.8rem;
  min-height: 70px;
  border-radius: 10px;
  &::before{
    content: '';
    display: block;
    background-color: $key-black;
    position: absolute;
    top: 0;
    left: -100%;
    bottom: 0;
    width: 100%;
    transition: .3s;
  }
  &:hover{
    &::before{
      left: 0;
    }
  }
  &:active{
    &::before{
      opacity: 0;
    }
  }
}
}
/////////////////////////////////////////
.btn-style_02{
  @include btnsetting;
  background-color: $key-black;
  text-align: center;
  color: $white;
  //min-height: 76/$design-num-sp*100vw;
  font-weight: bold;
  @include fz_vw(24);
  line-height: 1em;
  padding: 24/$design-num-sp*100vw;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  >span{
    position: relative;
  }
  &.style_01{
    .label{
      left: 10/$design-num-sp*100vw;
    }
    &::after{
      position: relative;
      @include iconFont;
      content: '\EA10';
      @include fz_vw(15);
      line-height: 1em;
      font-weight: normal;
      top: -10/$design-num-sp*100vw;
      left: 10/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.btn-style_02{
  min-height: 52px;
  font-size: 1.4rem;
  padding: 20px;
  &::before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: block;
    left: -100%;
    background-color: $key-color;
    transition: .3s;
  }
  &.style_01{
    .label{
      left: .7rem;
    }
    &::after{
      font-size: 1.0rem;
      top: -.7rem;
      left: .7rem;
    }
  }
  &:hover{
    &::before{
      left: 0;
    }
  }
}
}
///////////////////////////////////
.btn-style_03{
  @extend .btn-style_01;
}
@media screen and (min-width: $break-point-middle) {
.btn-style_03{
  font-size: 1.6rem;
}
}
///////////////////////////////////
.btn-style_04{
  @extend .btn-style_03;
  background-color: $key-gray1;
  border: 1px solid $key-gray1;
}
///////////////////////////////////
.btn-style_05{
  background-color: $key-black;
  color: $white;
  text-align: center;
  min-height: 107/$design-num-sp*100vw;
  display: flex;
  align-items: center;
  padding: 0 30/$design-num-sp*100vw;
  border-radius: 50px;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .icon{
    color:$youtube-color;
    @include fz_vw(36);
    line-height: 1em;
    padding-right: 12/$design-num-sp*100vw;
    position: relative;
    top: 4/$design-num-sp*100vw;
    overflow: hidden;
    &::before{
      content: '';
      width: 16/$design-num-sp*100vw;
      height: 16/$design-num-sp*100vw;
      position: absolute;
      top: 8/$design-num-sp*100vw;
      left: 16%;
      background-color: $white;
    }
    i{
      position: relative;
    }
  }
  .label{
    font-weight: bold;
    @include fz_vw(28);
    line-height: 1em;
    position: relative;
    display: inline-block;
  }
  &[target="_blank"]{
    .label{
      &::before{
        font-weight: normal;
        @include iconFont;
        content:'\EA10';
        @include fz_vw(12);
        line-height: 1em;
        position: absolute;
        right: -20/$design-num-sp*100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.btn-style_05{
  min-height: 53px;
  padding: 0 20px;
  &::before{
    content: '';
    display: block;
    top: 0;
    left: -100%;
    bottom: 0;
    width: 100%;
    background-color: $key-color;
    position: absolute;
    transition: .3s;
  }
  .icon{
    font-size: 2.4rem;
    padding-right: 6px;
    top: 3px;
    &::before{
      width: 10px;
      height: 10px;
      top: 5px;
    }
  }
  .label{
    font-size: 1.5rem;
  }
  &[target="_blank"]{
    .label{
      &::before{
        font-size: .8rem;
        right: -10px;
      }
    }
  }
  &:hover{
    &::before{
      left: 0;
    }
  }
}
}
///////////////////////////////////
.btn-style_06{
  @extend .btn-style_05;
  min-height: 81/$design-num-sp*100vw;
  position: relative;
  .label{
    @include fz_vw(26);
    white-space: nowrap;
  }
  &::after{
    @include iconFont;
    content: '\EA0F';
    @include fz_vw(22);
    line-height: 1em;
    position: absolute;
    right: 24/$design-num-sp*100vw;
    top: -webkit-calc(50% - .5em);
    top: calc(50% - .5em);
    transition: .3s;
  }
  &.style_01{
    min-width: 340/$design-num-sp*100vw;
    .label{
      letter-spacing: -.03em;
    }
  }
  &.style_02{
    min-width: 400/$design-num-sp*100vw;
    .label{
      letter-spacing: -.03em;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.btn-style_06{
  min-height: 53px;
  .label{
    font-size: 1.4rem;
  }
  &::after{
    font-size: 1.6rem;
    right: 12px;
    top: -webkit-calc(50% - .8rem);
    top: calc(50% - .8rem);
  }
  &:hover{
    &::after{
      right: 9px;
    }
  }
  &.style_01{
    min-width: 200px;
  }
  &.style_02{
    min-width: 240px;
  }
}
}
///////////////////////////////////
.btn-style_07{
  @extend .btn-style_06;
  min-height: 65/$design-num-sp*100vw;
  background-color: $key-color;
  &::after{
    right: 20/$design-num-sp*100vw;
    @include fz_vw(22);
    top: -webkit-calc(50% - .5em);
    top: calc(50% - .5em);
  }
}
@media screen and (min-width: $break-point-middle) {
.btn-style_07{
  min-height: 40px;
  &::before{
    background-color: $key-black;
  }
  &::after{
    right: 10px;
    font-size: 1.4rem;
    top: -webkit-calc(50% - .7rem);
    top: calc(50% - .7rem);
  }
  &:hover{
    &::after{
      right: 6px;
    }
  }
}
}
///////////////////////////////////
.btn-style_08{
  @extend .btn-style_05;
  min-height: 107/$design-num-sp*100vw;
  &::before{
    background-color: $white;
  }
  .icon{
    @include fz_vw(38);
    &::before{
      width: 16/$design-num-sp*100vw;
      height: 16/$design-num-sp*100vw;
    }
  }
  .label{
    @include fz_vw(28);
  }
}
@media screen and (min-width: $break-point-middle) {
.btn-style_08{
  min-height: 73px;
  .icon{
    font-size: 2.8rem;
    &::before{
      width: 16px;
      height: 16px;
    }
  }
  .label{
    font-size: 1.8rem;
  }
  &:hover{
    color: $key-black;
  }
}
}
///////////////////////////////////
.btn-style_09{
  @extend .btn-style_08;
  &::before{
    background-color: $key-color;
  }
}
@media screen and (min-width: $break-point-middle) {
.btn-style_09{
  &:hover{
    color: $white;
  }
}
}
///////////////////////////////////
.btn-style_10{
  background-color: $white;
  overflow: hidden;
  text-align: center;
  @include fz_vw(26);
  line-height: 1em;
  color: $key-color;
  font-weight: bold;
  overflow: hidden;
  padding: 28/$design-num-sp*100vw 28/$design-num-sp*100vw 30/$design-num-sp*100vw 28/$design-num-sp*100vw;
  position: relative;
  .label{
    position: relative;
  }
  .icon{
    @include fz_vw(28);
    line-height: 1em;
    padding-left: 20/$design-num-sp*100vw;
    transition: .3s;
    position: relative;
    left: 0;
    top: 1/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.btn-style_10{
  font-size: 1.4rem;
  padding: 16px 16px 17px 16px;
  .icon{
    font-size: 1.8rem;
    padding-left: 10px;
    top: 2px;
  }
  &::before{
    content: '';
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    bottom: 0;
    background-color: $key-color;
    transition: .3s;
  }
  &:hover{
    color: $white;
    .icon{
      left: 10px;
    }
    &::before{
      left: 0;
    }
  }
}
}
///////////////////////////////////
.btn-style_11{
  @include btnsetting;
  background-color: $key-black;
  color: $white;
  text-align: center;
  min-height: 107/$design-num-sp*100vw;
  display: flex;
  align-items: center;
  padding: 0 30/$design-num-sp*100vw;
  border-radius: 50px;
  justify-content: center;
  position: relative;
  overflow: hidden;
  >span{
    @include fz_vw(26);
    font-weight: bold;
    line-height: 1em;
    position: relative;
    white-space: nowrap;
  }
}
@media screen and (min-width: $break-point-middle) {
.btn-style_11{
  min-height: 53px;
  margin: 0 10px;
  &::before{
    content: '';
    display: block;
    top: 0;
    left: -100%;
    bottom: 0;
    width: 100%;
    background-color: $key-color;
    position: absolute;
    transition: .3s;
  }
  >span{
    font-size: 1.5rem;
  }
  &:hover{
    &::before{
      left: 0;
    }
  }
}
}
///////////////////////////////////
.btn-style_12{
  @extend .btn-style_11;
  background-color: #888;
}
///////////////////////////////////
.btn-style_13{
  background-color: $key-black;
  color: $white;
  text-align: center;
  padding: 20/$design-num-sp*100vw 30/$design-num-sp*100vw;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  .icon{
    color:$youtube-color;
    @include fz_vw(36);
    line-height: 1em;
    padding-right: 12/$design-num-sp*100vw;
    position: relative;
    top: 4/$design-num-sp*100vw;
    overflow: hidden;
    &::before{
      content: '';
      width: 16/$design-num-sp*100vw;
      height: 16/$design-num-sp*100vw;
      position: absolute;
      top: 8/$design-num-sp*100vw;
      left: 16%;
      background-color: $white;
    }
    i{
      position: relative;
    }
  }
  .label{
    font-weight: bold;
    @include fz_vw(28);
    line-height: 1em;
    position: relative;
    display: inline-block;
  }
  &[target="_blank"]{
    .label{
      &::before{
        font-weight: normal;
        @include iconFont;
        content:'\EA10';
        @include fz_vw(12);
        line-height: 1em;
        position: absolute;
        right: -22/$design-num-sp*100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.btn-style_13{
  padding:10px 20px;
  &::before{
    content: '';
    display: block;
    top: 0;
    left: -100%;
    bottom: 0;
    width: 100%;
    background-color: $key-color;
    position: absolute;
    transition: .3s;
  }
  .icon{
    font-size: 2.4rem;
    padding-right: 6px;
    top: 3px;
    &::before{
      width: 10px;
      height: 10px;
      top: 5px;
    }
  }
  .label{
    font-size: 1.5rem;
  }
  &[target="_blank"]{
    .label{
      &::before{
        font-size: .8rem;
        right: -12px;
      }
    }
  }
  &:hover{
    &::before{
      left: 0;
    }
  }
}
}
///////////////////////////////////
.btn-style_14{
  @include btnsetting;
  border-radius: 50px;
  line-height: 1em;
  white-space: nowrap;
  background-color: $key-color;
  color: $white;
  @include fz_vw(32);
  font-weight: bold;
  text-align: center;
  padding: 24/$design-num-sp*100vw 4/$design-num-sp*100vw 24/$design-num-sp*100vw 24/$design-num-sp*100vw;
  position: relative;
  overflow: hidden;
  &::before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    width: 100%;
    transition: .3s;
    background-color: $key-black;
  }
  >span{
    position: relative;
  }
  .count{
    font-family: $fontEn;
    margin-left: .5em;
    background-color: $white;
    display: inline-block;
    border-radius: 50px;
    @include fz_vw(30);
    line-height: 1em;
    padding: 8/$design-num-sp*100vw 20/$design-num-sp*100vw 0;
    color: $key-color;
  }
}
@media screen and (min-width: $break-point-middle) {
.btn-style_14{
  @include btnsetting;
  border-radius: 50px;
  line-height: 1em;
  white-space: nowrap;
  background-color: $key-color;
  color: $white;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  padding: 12px 2px 12px 12px;
  position: relative;
  overflow: hidden;
  &::before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    width: 100%;
    transition: .3s;
    background-color: $key-black;
  }
  >span{
    position: relative;
  }
  .count{
    font-family: $fontEn;
    margin-left: .5em;
    background-color: $white;
    display: inline-block;
    border-radius: 50px;
    font-size: 1.6rem;
    line-height: 1em;
    padding: 4px 10px 0;
    color: $key-color;
  }
  &:active{
    &::before{
      display: none;
    }
    .count{
      color: $key-color;
    }
  }
  &:hover{
    &::before{
      left: 0;
    }
    .count{
      color: $black;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
$nav-gutter:60/$design-num-sp*100vw;
.list-style_01{
  &.nav-parent{
    >li{
      margin-bottom: 40/$design-num-sp*100vw;
      &:last-child{
        margin-bottom: 0;
      }
    }
    >li>a{
      @include fz_vw(36);
      font-weight: bold;
      color: $key-black;
      position: relative;
      display: inline-block;
      padding-left: $nav-gutter;
      white-space: nowrap;
      .label{
        @include over_01($key-color);
      }
      &.dis{
        opacity: .3;
        cursor: default;
        pointer-events: none;
      }
      .icon{
        @include arrow_01;
      }
      &.current{
        cursor: default;
        pointer-events: none;
        .icon{
          background-color: $black;
        }
      }
    }
  }
  .nav-child{
    padding-top:20/$design-num-sp*100vw;
    padding-left: $nav-gutter;
    li{
      margin-bottom: 20/$design-num-sp*100vw;
      &:last-child{
        margin-bottom: 0;
      }
    }
    a{
      @include fz_vw(28);
      @include over_01($key-black);
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.list-style_01{
  --nav-gutter:27px;
  &.nav-parent{
    >li{
      margin-bottom: 26px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    >li>a{
      font-size: 2.0rem;
      padding-left: var(--nav-gutter);
      &:hover{
        color: $key-color;
        .icon{
          left: 3px;
          background-color: $key-black;
        }
      }
    }
  }
  .nav-child{
    padding-top: 14px;
    padding-left: var(--nav-gutter);
    li{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    a{
      font-size: 1.6rem;
    }
    a:hover{
      opacity: .8;
    }
  }
}
}
//////////////////////////////////
.list-style_02{
  @extend .list-style_01;
  &.nav-parent{
    >li{
      margin-bottom: 30/$design-num-sp*100vw;
      &:last-child{
        margin-bottom: 0;
      }
    }
    >li>a{
      @include fz_vw(26);
      .icon{
        background-color: $key-black;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.list-style_02{
  &.nav-parent{
    >li{
      margin-bottom: 12px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    >li>a{
      font-size: 1.4rem;
      .icon{
        background-color: $key-black;
      }
      &:hover{
        .icon{
          background-color: $key-color;
        }
      }
    }
  }
}
}
//////////////////////////////////
.list-style_03{
  padding-top: 5/$design-num-sp*100vw;
  padding-left: $nav-gutter;
  li{
    margin-bottom: 30/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
  a{
    @include fz_vw(24);
  }
}
@media screen and (min-width: $break-point-middle) {
.list-style_03{
  padding-top: 12px;
  padding-left: 27px;
  li{
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  a{
    font-size: 1.2rem;
    &:hover{
      text-decoration: underline;
    }
  }
}
}
//////////////////////////////////
.list-style_04{
  padding-left: 1em;
  text-indent: -1em;
}
//////////////////////////////////
.list-style_05{
  >*{
    display: inline-block;
    font-weight: bold;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.tag-style_01{
  @include killSpace;
  li{
    margin-right: 12/$design-num-sp*100vw;
    margin-bottom: 28/$design-num-sp*100vw;
    &:last-child{
      margin-right: 0;
    }
  }
  a{
    display: block;
    color: $key-color;
    border: 1px solid $key-color;
    border-radius: 50px;
    line-height: 1em;
    @include fz_vw(24);
    padding: 8/$design-num-sp*100vw 16/$design-num-sp*100vw;
    min-width: 3em;
    text-align: center;
  }
}
@media screen and (min-width: $break-point-middle) {
.tag-style_01{
  li{
    margin-right: 8px;
    margin-bottom: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
  a{
    font-weight: bold;
    font-size: 1.3rem;
    padding: 5px 10px;
  }
  a:hover{
    background-color: $key-color;
    color: $white;
  }
}
}
////////////////////////////////////////
.tag-style_02{
  @include killSpace;
  li{
    margin-right: 12/$design-num-sp*100vw;
    margin-bottom: 28/$design-num-sp*100vw;
    .js-way &{
      @include opacity(0);
    	@include transition(all 0.9s $ease-in-out-circ);
    	@include transform(scale(0));
    	@include transform-origin(50%,50%);
    }
    .js-way.active &{
      @include opacity(1);
  		@include transform(scale(1));
    }
    @for $i from 2 through 40 {
		  &:nth-child(#{$i}) {
				transition-delay: 0.03*$i*1s;
		  }
		}
    &:last-child{
      margin-right: 0;
    }
  }
  a{
    color: $key-color;
    text-align: center;
    display: block;
    border: 2/$design-num-sp*100vw solid $key-color;
    border-radius: 50px;
    line-height: 1em;
    @include fz_vw(24);
    padding: 8/$design-num-sp*100vw 18/$design-num-sp*100vw;
    min-width: 3em;
    transition: .3s;
    &::before{
      content: '#';
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.tag-style_02{
  li{
    margin-right: 12px;
    margin-bottom: 20px;
  }
  a{
    border: 1px solid $key-color;
    font-size: 1.5rem;
    padding: 8px 18px;
  }
  a:hover{
    background-color: $key-color;
    color: $white;
  }
}
}
////////////////////////////////////////
.tag-style_03{
  @include killSpace;
  .article-style_02 &{
    li{
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 46%;
      &.style_04{
        max-width: none;
      }
    }
  }
  .article-style_10 &{
    display: flex;
    justify-content: space-between;
    li{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 96/200*100%;
      margin-right: 0;
    }
  }
  li{
    margin-right: 8/$design-num-sp*100vw;
    margin-bottom: 10/$design-num-sp*100vw;
    border-radius: 50px;
    @include fz_vw(20);
    line-height: 1em;
    padding:8/$design-num-sp*100vw 18/$design-num-sp*100vw;
    background-color: $key-color;
    color: $white;
    border: 1px solid $key-color;
    a{
      padding:8/$design-num-sp*100vw 18/$design-num-sp*100vw;
      display: block;
      color: $white;
      border: 1px solid $key-color;
      border-radius: 50px;
      background-color: $key-color;
    }
    &:last-child{
      margin-right: none;
    }
    &.style_01{
      background-color: transparent;
      color: $key-color;
      a{
        background-color: transparent;
        color: $key-color;
      }
      .article-style_09 &{
        border: 1px solid $white;
        color: $white;
      }
    }
    &.style_02{
      color: $key-black;
      border: 1px solid $key-black;
      background-color: transparent;
      a{
        color: $key-black;
        border: 1px solid $key-black;
        background-color: transparent;
      }
    }
    &.style_03{
      color: $white;
      border: 1px solid $white;
      background-color: transparent;
      font-weight: bold;
      a{
        color: $white;
        border: 1px solid $white;
        background-color: transparent;
      }
    }
    &.style_04,&.style_05{
      border: none;
      font-weight: bold;
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
    }
    &.style_05{
      color: $key-black;
    }
  }
  &.style_01{
    li{
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.tag-style_03{
  li{
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 1.1rem;
    padding: 5px 11px;
    a{
      padding: 5px 11px;
    }
    a:hover{
      background-color: $key-black!important;
      color: $white!important;
      border: 1px solid $key-black!important;
    }
    &:last-child{
      margin-right: none;
    }
  }
}
}
////////////////////////////////////////
.tag-style_04{
  @include killSpace;
  .article-style_08 &,.article-style_10 &{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  li{
    margin-right: 8/$design-num-sp*100vw;
    margin-bottom: 12/$design-num-sp*100vw;
    @include fz_vw(20);
    line-height: 1em;
    color: $key-color;
    .article-style_09 &{
      color: $white;
    }
    &:last-child{
      margin-right: 0;
    }
    &::before{
      content: '#';
    }
    a{
      color: $key-color;
      @include over_01($key-color);
      padding-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.tag-style_04{
  li{
    margin-right: 12px;
    margin-bottom: 8px;
    font-size: 1.1rem;
    .article-style_09 &{
      color: $white;
    }
  }
}
}
////////////////////////////////////////
.tag-style_05{
  @include killSpace;
  li{
    margin-right: 10/$design-num-sp*100vw;
    margin-bottom: 10/$design-num-sp*100vw;
    @include fz_vw(24);
    line-height: 1em;
    a{
      display: block;
      background-color: $white;
      padding: 8/$design-num-sp*100vw 14/$design-num-sp*100vw 9/$design-num-sp*100vw;
      border-radius: 50px;
      color: $key-color;
      transition: .3s;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.tag-style_05{
  li{
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 1.5rem;
    a{
      padding: 8px 14px 7px;
    }
    a:hover{
      background-color: $key-color;
      color: $white;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.summary-style_01{
  @include postP_01;
}
/////////////////////////////////
.summary-style_02{
  line-height: (36/26)+em;
  @include fz_vw(26);
  p{
    margin-bottom: 1em;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.summary-style_02{
  line-height: (22/13)+em;
  font-size: 1.3rem;
}
}
/////////////////////////////////
.summary-style_03{
  line-height: (27/16)+em;
  font-size: 1.6rem;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.img-style_01{
  aspect-ratio: 200 / 200;
  border-radius: 100%;
  overflow: hidden;
  img{
    @include imgset;
  }
}
/////////////////////////////////////
.img-style_02{
  img{
    border-radius: 16px;
    @include imgset;
    max-width: 100%;
    margin: 0 auto;
    width: auto;
  }
}
/////////////////////////////////////
.img-style_03{
  img{
    @include imgset;
  }
}
/////////////////////////////////////
.img-style_04{
  img{
    @include imgset;
    aspect-ratio:710/474;
    object-fit: cover;
    border-radius: 16px;
  }
}
@media screen and (min-width: $break-point-middle) {
.img-style_04{
  img{
    aspect-ratio:1/1;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.tbl-style_01{
  table{
    width: 100%;
  }
  th,td{
    @include fz_vw(28);
    line-height: (36/28)+em;
    padding: 25/$design-num-sp*100vw 30/$design-num-sp*100vw;
    border-top: 20/$design-num-sp*100vw solid $bg-color1;
    border-bottom: 20/$design-num-sp*100vw solid $bg-color1;
  }
  th{
    vertical-align: middle;
    text-align: center;
    width: 180/$design-num-sp*100vw;
    background-color: $bg-color2;
  }
  &.style_01{
    th{
      white-space: nowrap;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.tbl-style_01{
  th,td{
    font-size: 1.6rem;
    line-height: (23/16)+em;
    padding: 18px 26px;
    border-top: 5px solid $bg-color1;
    border-bottom: 5px solid $bg-color1;
  }
  th{
    width: 180/720*100%;
  }
}
}
////////////////////////////////////////
.tbl-style_02{
  table{
    width: 1252/$design-num-sp*100vw;
  }
  th,td{
    padding: 30/$design-num-sp*100vw 13/$design-num-sp*100vw;
    border-right: 10/$design-num-sp*100vw solid $bg-color1;
    border-bottom: 10/$design-num-sp*100vw solid $bg-color1;
    vertical-align: middle;
    &:last-child{
      border-right: none;
    }
  }
  th{
    @include fz_vw(28);
    line-height: 1em;
    font-weight: bold;
    text-align: center;
    background-color: $bg-color3;
    white-space: nowrap;
  }
  td{
    @include fz_vw(26);
    line-height: (30/26)+em;
    background-color: #f9e8e4;
    &.style_01{
      width: 170/$design-num-sp*100vw;
      text-align: center;
      white-space: nowrap;
    }
    &.style_02{
      width: 170/$design-num-sp*100vw;
      text-align: center;
      white-space: nowrap;
    }
    &.style_03{
      width: 460/$design-num-sp*100vw;
    }
    &.style_04{
      //width: 460/$design-num-sp*100vw;
    }
  }
  tr{
    &.current-day{
      td{
        background-color: $bg-color3;
      }
    }
    &:last-child{
      td{
        border-bottom: none;
      }
    }
  }
  // tbody tr{
  //   cursor: pointer;
  // }
  // .scroll-hint-icon-wrap{
  //   top: -100%;
  // }
  // .scroll-hint-icon{
  //   top: 50px;
  //   // opacity: .8;
  //   // &::before{
  //   //   animation: scroll-hint-appear 1.2s linear;
  //   //   animation-iteration-count: 2;
  //   // }
  // }
  .scroll-hint-icon-wrap.is-active .scroll-hint-icon{
    opacity: 0;
  }
  &.active{
    .scroll-hint-icon{
      top: 50px;
      opacity: .8;
      &::before{
        animation: scroll-hint-appear 1.2s linear;
        animation-iteration-count: 2;
      }
      &::after{
        opacity: 1;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.tbl-style_02{
  table{
    width: 100%;
  }
  th,td{
    font-size: 1.6rem;
    line-height: (24/16)+em;
    padding: 22px 16px;
    border-right: 10px solid $bg-color1;
    border-bottom: 10px solid $bg-color1;
    vertical-align: middle;
    &:last-child{
      border-right: none;
    }
  }
  th{
    font-weight: bold;
    text-align: center;
    background-color: $bg-color3;
    white-space: nowrap;
  }
  td{
    background-color: #f9e8e4;
    &.style_01{
      width: 130/$content-num-base*100%;
      text-align: center;
    }
    &.style_02{
      width: 140/$content-num-base*100%;
      text-align: center;
    }
    &.style_03{
      width: 460/$content-num-base*100%;
    }
  }
  tr{
    &.current-day{
      td{
        background-color: $bg-color3;
      }
    }
    &:last-child{
      td{
        border-bottom: none;
      }
    }
  }
  // tbody tr{
  //   &:hover{
  //     text-decoration: underline;
  //   }
  // }
  &.active{
    .scroll-hint-icon{
      display: none;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.dl-style_01{
  dl{
    margin-bottom: 30/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
  dt{
    font-weight: bold;
    margin-bottom: 20/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.dl-style_01{
  dl{
    margin-bottom: 12px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  dt{
    margin-bottom: 10px;
  }
}
}
//////////////////////////////////////
.dl-style_02{
  dl{
    @include setTbl;
    margin-bottom: 10/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
    >*{
      vertical-align: top;
    }
  }
  dt{
    width: 4em;
    white-space: nowrap;
    >span{
      border-radius: 50px;
      border: 1px solid $key-color;
      @include fz_vw(20);
      line-height: 1em;
      padding: 6/$design-num-sp*100vw 10/$design-num-sp*100vw;
      text-align: center;
      display: inline-block;
      min-width: 5em;
      color: $key-color;
      &.style_01{
        letter-spacing: .03em;
      }
    }
  }
  dd{
    line-height: 1.2em;
    @include fz_vw(24);
    padding-top: 8/$design-num-sp*100vw;
    padding-left: .8em;
    >span{
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.dl-style_02{
  dl{
    margin-bottom: 6px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  dt{
    width: 5em;
    >span{
      font-size: 1.1rem;
      padding: 3px 5px;
      min-width: 5em;
    }
  }
  dd{
    font-size: 1.2rem;
    padding-top: 4px;
  }
}
}
//////////////////////////////////////
.dl-style_03{
  dl{
    @include setTbl;
    width: auto;
    margin-bottom: 30/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
    >*{
      vertical-align: top;
    }
  }
  dt{
    width: 140/$design-num-sp*100vw;
    white-space: nowrap;
    >span{
      display: block;
      @include fz_vw(22);
      line-height: 1.4em;
      font-weight: bold;
      color: $white;
      background-color: $key-color;
      border-radius: 50px;
      padding: 6/$design-num-sp*100vw 14/$design-num-sp*100vw 6/$design-num-sp*100vw;
      text-align: center;
    }
  }
  dd{
    padding-left: 8/$design-num-sp*100vw;
    position: relative;
    //padding-top: .5em;
    p{
      line-height: (36/26)+em;
      @include fz_vw(26);
      margin-bottom: 1em;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.dl-style_03{
  dl{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  dt{
    width: 110px;
    >span{
      font-size: 1.5rem;
      padding: 5px 14px 7px;
    }
  }
  dd{
    padding-left: 10px;
    p{
      line-height: (20/16)+em;
      font-size: 1.6rem;
    }
  }
}
}
//////////////////////////////////////
.dl-style_04{
  display: flex;
  margin-bottom: 22/$design-num-sp*100vw;
  &:last-child{
    margin-bottom: 0;
  }
  dl{
    @include setTbl;
    width: auto;
    table-layout: auto;
    white-space: nowrap;
    margin-right: 18/$design-num-sp*100vw;
    &:last-child{
      margin-right: 0;
    }
    >*{
      vertical-align: top;
    }
  }
  dt{
    >span{
      @include fz_vw(22);
      line-height: 1em;
      font-weight: bold;
      color: $white;
      background-color: $key-color;
      border-radius: 50px;
      padding: 6/$design-num-sp*100vw 14/$design-num-sp*100vw 6/$design-num-sp*100vw;
      text-align: center;
    }
  }
  dd{
    padding-left: 8/$design-num-sp*100vw;
    @include fz_vw(30);
    line-height: 1.6em;
    position: relative;
    top: -.1em;
  }
}
@media screen and (min-width: $break-point-middle) {
.dl-style_04{
  margin-bottom: 14px;
  &:last-child{
    margin-bottom: 0;
  }
  dl{
    margin-right: 12px;
    &:last-child{
      margin-right: 0;
    }
  }
  dt{
    >span{
      font-size: 1.5rem;
      padding: 4px 14px 6px;
    }
  }
  dd{
    padding-left: 10px;
    font-size: 1.8rem;
    top: -.3em;
  }
}
}
//////////////////////////////////////
.dl-style_05{
  dt{
    font-weight: bold;
  }
}
//////////////////////////////////////
.dl-style_06{
  @extend .dl-style_02;
  font-weight: bold;
  >.col{
    display: inline-block;
    margin-right: 20/$design-num-sp*100vw;
    &:last-child{
      margin-right: 0;
    }
  }
  dl{
    width: auto;
  }
  dt{
    >span{
      border: 1px solid $white;
      color: $white;
    }
  }
  dd{
    color: $white;
    >span{
      -webkit-line-clamp: 1;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.dl-style_06{
  >.col{
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.link-style_01{
  color: $key-color;
  text-decoration: underline;
  @include fz_vw(24);
  line-height: 1em;
  display: inline-block;
  position: relative;
  &[target="_blank"]{
    &::before{
      @include iconFont;
      content: '\EA10';
      @include fz_vw(20);
      line-height: 1em;
      position: absolute;
      top: 0;
      right: -20/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.link-style_01{
  font-size: 1.3rem;
  &[target="_blank"]{
    &::before{
      font-size: 1.0rem;
      right: -10px;
    }
  }
  &:hover{
    text-decoration: none;
    opacity: .8;
  }
}
}
/////////////////////////////////////////
.link-style_02{
  text-decoration: none;
}
@media screen and (min-width: $break-point-middle) {
.link-style_02{
  &:hover{
    text-decoration: underline;
    opacity: .8;
  }
}
}
/////////////////////////////////////////
.link-style_03{
  color: $key-color;
  text-decoration: underline;
  display: inline-block;
  position: relative;
  padding-right: .8em;
  &[target="_blank"]{
    &::before{
      @include iconFont;
      content: '\EA10';
      font-size: 1.0rem;
      line-height: 1em;
      position: absolute;
      top: 0;
      right: 0px;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.link-style_03{
  &:hover{
    text-decoration: none;
    opacity: .8;
  }
}
}
/////////////////////////////////////////
.link-style_04{
  @extend .link-style_03;
  @include fz_vw(22);
  &[target="_blank"]{
    &::before{
      top: 2px;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.link-style_04{
  font-size: 1.3rem;
}
}
/////////////////////////////////////////
.link-style_05{
  @include over_02($key-color);
  color: $key-color;
  display: inline-block;
  overflow: hidden;
}
/////////////////////////////////////////
.link-style_06{
  color: $key-color;
  text-decoration: underline;
}
@media screen and (min-width: $break-point-middle) {
.link-style_06{
  &:hover{
    text-decoration: none;
    opacity: .8;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.tx-style_01{
  font-family: $fontEn;
  //line-height: 1em;
}
/////////////////////////////////
.tx-style_02{
  text-align: center;
  line-height: 1.6em;
  font-weight: bold;
  .en{
    @include fz_vw(22);
    font-family: $fontEn;
    padding-right: 1em;
  }
  .ja{
    @include fz_vw(24);
  }
}
@media screen and (min-width: $break-point-middle) {
.tx-style_02{
  a{
    &:hover{
      text-decoration: underline;
    }
  }
  .en{
    font-size: 1.2rem;
  }
  .ja{
    font-size: 1.4rem;
  }
}
}
///////////////////////////////////////////
.tx-style_03{
  text-align:center;
  @include fz_vw(22);
}
@media screen and (min-width: $break-point-middle) {
.tx-style_03{
  font-size: 1.2rem;
}
}
//////////////////////////////////////////
.tx-style_04{
  text-align:center;
  @include fz_vw(26);
  font-weight: bold;
}
@media screen and (min-width: $break-point-middle) {
.tx-style_04{
  font-size: 1.8rem;
}
}
//////////////////////////////////////////
.tx-style_05{
  text-align:center;
  @include fz_vw(24);
}
@media screen and (min-width: $break-point-middle) {
.tx-style_05{
  font-size: 1.3rem;
}
}
//////////////////////////////////////////
.tx-style_06{
  color: $key-color;
}
//////////////////////////////////////////
.tx-style_07{
  color:$facebook-color;
}
//////////////////////////////////////////
.tx-style_08{
  font-weight: bold;
}
//////////////////////////////////////////
.tx-style_09{
  font-weight: bold;
  color: $key-color;
}
//////////////////////////////////////////
.tx-style_10{
  margin-top: 1em;
  display: block;
}
//////////////////////////////////////////
.tx-style_11{
  @include fz_vw(28);
  line-height: 1.6em;
}
@media screen and (min-width: $break-point-middle) {
.tx-style_11{
  font-size: 1.4rem;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.embed-style_01{
  padding: 30/$design-num-sp*100vw;
  border-radius: 16/$design-num-sp*100vw;
  background-color: $white;
}
@media screen and (min-width: $break-point-middle) {
.embed-style_01{
  padding: 20px;
  border-radius: 16px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}

@charset "utf-8";
.post-content{
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
>*{
  margin-bottom: 50/$design-num-sp*100vw;
  &:last-child{
    margin-bottom: 0;
  }
}
>h2{
  @include postTtl_01;
}
>blockquote{
  padding: 30/$design-num-sp*100vw;
  position: relative;
  background-color: $white;
  >*{
    position: relative;
  }
  &::before{
    @include iconFont;
    content: '\EA24';
    @include fz_vw(80);
    line-height: 1em;
    color: #eee;
    position: absolute;
    top: 0px;
    left: 20/$design-num-sp*100vw;
  }
}
>p,>blockquote>p{
  @include postP_01;
  bold{
    font-weight: bold;
  }
  a{
    text-decoration: underline;
  }
}
>ul{
  @include postP_01;
  li{
    position: relative;
    padding: 14/$design-num-sp*100vw 0 8/$design-num-sp*100vw 1em;
    border-bottom: 1px dotted #ccc;
    margin-bottom: 0;
    &:last-child{
      border: none;
    }
    &::before{
      content: '';
      width: .5em;
      height: .5em;
      background-color: $key-color;
      border-radius: 100%;
      position: absolute;
      top: 1em;
      left: 0;
    }
  }
}
>h3{
  @include postTtl_02;
}
>img {
  display: inline-block;
  &.alignleft {
    float: none;
    width: 100%;
  }
  &.alignright {
    float: none;
    width: 100%;
  }
}
>.wp-block-image {
  @include clearfix;
  position: relative;
  &::after{
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  img{
    border-radius: 16px;
  }
}
///////////////////
@media screen and (min-width: $break-point-middle) {
>*{
  margin-bottom: 40px;
  &:last-child{
    margin-bottom: 0;
  }
}
>blockquote{
  padding: 20px;
  &::before{
    font-size: 8.0rem;
    left: 10px;
  }
}
>p,>blockquote>p{
  a{
    &:hover{
      text-decoration: none;
      opacity: .8;
    }
  }
}
>ul{
  li{
    padding: 8px 0 8px 1em;
  }
}
>img {
  &.alignleft {
    margin-right: 16px;
    float: left;
  }
  &.alignright {
    margin-left: 16px;
    float: right;
  }
  &.aligncenter {
    margin: 0 auto;
    display: block;
  }
  &.size-full,
  &.size-medium {
    width: 100%;
    display: block;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
}

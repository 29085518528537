@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
footer{
  position: relative;
  background-color: $key-color;
  overflow: hidden;
  >.inner{
    padding-top: 100/$design-num-sp*100vw;
    padding-bottom: 50/$design-num-sp*100vw;
    padding-left: $sp-pad2;
    padding-right: $sp-pad2;
  }
  .bg-elem{
    span{
      position: absolute;
      svg{
        fill:#c44232;
        width: 320/$design-num-sp*100vw;
        height: 320/$design-num-sp*100vw;
      }
      &:first-child{
        right: 0;
        top: 0;
      }
      // &:last-child{
      //   right: 0;
      //   bottom: 0;
      //   mix-blend-mode: multiply;
      //   img{
      //     width:302/$design-num-sp*100vw;
      //   }
      // }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: $footer-size;
  >.inner{
    @include setTbl;
    @include contentWrap;
    padding-top: 100px;
    padding-bottom: 85px;
    >*{
      vertical-align: top;
    }
  }
  .bg-elem{
    span{
      svg{
        width: 224px;
        height: 224px;
      }
      // &:last-child{
      //   display: none;
      // }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
footer .col-logo{
  width: 363/$design-num-sp*100vw;
  margin-bottom: 50/$design-num-sp*100vw;
  .sitelogo{
    position: relative;
  }
  a{
    display: inline-block;
  }
  img{
    width: 100%;
  }
}
@media screen and (min-width: $break-point-middle) {
footer .col-logo{
  width: 303/$content-num-base*100%;
  margin-bottom: 0;
  .sitelogo{
    top: -10px;
  }
  a:hover{
    opacity: .8;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
footer .col-sitemap{
  position: relative;
  margin-bottom: 60/$design-num-sp*100vw;
  >ul,>*>ul{
    >*{
      margin-bottom: 40/$design-num-sp*100vw;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
footer .col-sitemap{
  margin-bottom: 0;
  padding-left: 40/$content-num-base*100%;
  width: 640/$content-num-base*100%;
  >ul,>*>ul{
    @include setTbl;
    table-layout: auto;
    margin-bottom: 0;
    >*{
      vertical-align: top;
    }
  }
  .child{
    margin-top: 16px;
    padding-left: 24px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
footer .nav-l{
  display: inline-block;
  a{
    display: inline-block;
    position: relative;
    padding-left: 40/$design-num-sp*100vw;
    color: $white;
    @include fz_vw(26);
    line-height: 1em;
    font-weight: bold;
    .label{
      @include over_01($white);
    }
    .icon{
      position: absolute;
      top: -2/$design-num-sp*100vw;
      left: 0;
      display: block;
      border-radius: 100%;
      width: 33/$design-num-sp*100vw;
      height: 33/$design-num-sp*100vw;
      background-color: $key-black;
      text-align: center;
      transition: .3s;
      i{
        line-height: 33/$design-num-sp*100vw;
        @include fz_vw(20);
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
footer .nav-l{
  a{
    padding-left: 24px;
    font-size: 1.4rem;
    .icon{
      top: -webkit-calc(50% - 8px);
      top: calc(50% - 8px);
      width: 18px;
      height: 18px;
      i{
        line-height: 18px;
        font-size: 1.0rem;
      }
    }
  }
  a:hover{
    .icon{
      left: 3px;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
footer .nav-s{
  position: absolute;
  top: 0;
  left: 350/$design-num-sp*100vw;
  li{
    display: block;
    margin-bottom: 30/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
  a{
    display: inline-block;
    color: $white;
    line-height: 1em;
    @include fz_vw(24);
    font-weight: bold;
    @include over_01($white);
  }
}
@media screen and (min-width: $break-point-middle) {
footer .nav-s{
  position: relative;
  left: 0;
  li{
    margin-bottom: 16px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  a{
    font-size: 1.2rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
footer .col-sns{
  ul{
    @include killSpace;
  }
  li{
    width: 68/$design-num-sp*100vw;
    margin: 0 20/$design-num-sp*100vw 0 0;
    &:last-child{
      margin-right: 0;
    }
  }
  a{
    display: block;
    width: 100%;
    height: 68/$design-num-sp*100vw;
    border-radius: 100%;
    background-color: $white;
    text-align: center;
    transition: .3s;
    i{
      line-height: 68/$design-num-sp*100vw;
      @include fz_vw(30);
      color: $key-color;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
footer .col-sns{
  ul{
    text-align: right;
  }
  li{
    width: 36px;
    margin: 0 4px 10px;
  }
  a{
    height: 36px;
    i{
      line-height: 36px;
      font-size: 2.0rem;
    }
  }
  a:hover{
    background-color: $black;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.link-list{
  margin-bottom: 30/$design-num-sp*100vw;
  ul{
    border-top: 1px solid #c44232;
    border-bottom: 1px solid #c44232;
    padding-left: $sp-pad2;
    padding-right: $sp-pad2;
    @include killSpace;
    @include fz_vw(20);
    line-height: 1.6em;
    text-align: center;
    padding-top: 20/$design-num-sp*100vw;
    padding-bottom: 20/$design-num-sp*100vw;
  }
  li{
    color: $key-black;
    &::after{
      content: '|';
      padding: 0 4/$design-num-sp*100vw;
    }
    &:last-child{
      display: none;
    }
  }
  a{
    color: $key-black;
    text-decoration: underline;
  }
}
@media screen and (min-width: $break-point-middle) {
.link-list{
  margin-bottom: 40px;
  ul{
    @include contentWrap;
    font-size: 1.1rem;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  li{
    &::after{
      padding: 0 4px;
    }
  }
  a:hover{
    text-decoration: none;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.col-license{
  padding-bottom: 50/$design-num-sp*100vw;
  padding-left: $sp-pad2;
  small{
    display: block;
    font-weight: bold;
    @include fz_vw(24);
    line-height: 1em;
    color: $white;
    font-family: $fontEn;
  }
}
@media screen and (min-width: $break-point-middle) {
.col-license{
  padding-bottom: 20px;
  padding-left: 0;
  small{
    text-align: center;
    font-size: 1.4rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}

@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#wrapper{
  position: relative;
  padding-top: $header-size-sp;
  overflow: hidden;
  //min-width: $break-point-full;
}
@media screen and (min-width: $break-point-middle) {
#wrapper{
  padding-top: $header-size;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#section-fv{
  margin-bottom: 60/$design-num-sp*100vw;
  .col-info{
    padding: 0 $sp-pad;
  }
  .col-hero{
    position: relative;
    margin-bottom: 60/$design-num-sp*100vw;
    >.bg-elem{
      position: absolute;
      bottom: -140/$design-num-sp*100vw;
      right: 20/$design-num-sp*100vw;
      z-index: 20;
      mix-blend-mode: multiply;
      >span{
        display: block;
      }
      img{
        @include imgset;
        width: 154/$design-num-sp*100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
#section-fv{
  margin-bottom: 45px;
  >.inner{
    @include setTbl;
    height: 605px;
    >*{
      vertical-align: top;
    }
  }
  .col-info{
    width: 440px;
    padding: 20px;
    position: relative;
  }
  .col-hero{
    position: relative;
    margin-bottom: 0;
    >.bg-elem{
      bottom: -27px;
      right: -100px;
      img{
        @include imgset;
        width: 186px;
      }
    }
  }
}
}
@media screen and (min-width: 1300px) {
#section-fv{
  .col-hero{
    >.bg-elem{
      right: -46px;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.fv-topics{
  border-radius: 16/$design-num-sp*100vw;
  background-color: $white;
  border: 1px solid $key-color;
  padding:30/$design-num-sp*100vw 20/$design-num-sp*100vw;
  position: relative;
  .sec-ttl{
    .col-ttl{
      position: absolute;
      left: 262/$design-num-sp*100vw;
      top: 30/$design-num-sp*100vw;
      z-index: 10;
    }
    .col-ctr{
      display: none;
    }
    h2{
      border-bottom: 1px solid $key-color;
      line-height: 1em;
      @include fz_vw(28);
      color: $key-color;
      .icon{
        width: 40/$design-num-sp*100vw;
        height: 40/$design-num-sp*100vw;
        background-color: $key-color;
        border-radius: 100%;
        display: inline-block;
        text-align: center;
        position: relative;
        top: -6/$design-num-sp*100vw;
        margin-right: 6/$design-num-sp*100vw;
        i{
          color: $white;
          @include fz_vw(20);
          line-height: 40/$design-num-sp*100vw;
        }
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.fv-topics{
  border-radius: 8px;
  padding:20px;
  .sec-ttl{
    .col-ttl{
      left: 20px;
      top: 20px;
    }
    .col-ctr{
      display: block;
      width: 50px;
      position: absolute;
      top: 18px;
      right: 160px;
      display: flex;
      justify-content: space-between;
    }
    .carousel-next, .carousel-prev{
      width: 24px;
      height: 24px;
      background-color: $key-color;
      border-radius: 4px;
      &:hover{
        background-color: $key-black;
      }
      &:active{
        background-color: $key-color;
      }
    }
    .slick-next:before, .slick-prev:before, .carousel-next:before, .carousel-prev:before {
      line-height: 24px;
      font-size: 1.2rem;
    }
    h2{
      font-size: 1.5rem;
      .icon{
        width: 20px;
        height: 20px;
        top: -3px;
        margin-right: 3px;
        i{
          font-size: 1.2rem;
          line-height: 20px;
        }
      }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.fv-topics article{
  a{
    display: block;
    .eyecatch{
      width: 219/$design-num-sp*100vw;
      position: absolute;
      top: 0;
      left: 0;
      img{
        @include imgset;
      }
    }
    .content{
      padding-top: 60/$design-num-sp*100vw;
      padding-left: 240/$design-num-sp*100vw;
      background-color: $white;
    }
    .update{
      color: $key-gray1;
      @include fz_vw(20);
      line-height: 1em;
      margin-bottom:10/$design-num-sp*100vw;
      font-family: $fontEn;
      overflow: hidden;
      >span{
        position: relative;
        top: 80/$design-num-sp*100vw;
        transition: .6s;
      }
    }
    h2{
      line-height: 1.2em;
      @include fz_vw(30);
      overflow: hidden;
      span{
        position: relative;
        top: 80/$design-num-sp*100vw;
        transition: .6s;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
.swiper-slide-active article a{
  .update>span,h2>span{
    top: 0;
  }
}
@media screen and (min-width: $break-point-middle) {
.fv-topics article{
  a{
    .eyecatch{
      width: 133/358*100%;
      left: auto;
      right: 0;
    }
    .content{
      padding-top: 38px;
      padding-left: 0;
      padding-right: 140px;
    }
    .update{
      font-size: 1.2rem;
      margin-bottom: 5px;
      >span{
        top: 40px;
      }
    }
    h2{
      //line-height: (17/15)+em;
      font-size: 1.5rem;
      span{
        top: 40px;
      }
    }
  }
}
.swiper-slide-active article a{
  .update>span,h2>span{
    top: 0;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.sec-koyomi{
  margin-top: 60/$design-num-sp*100vw;
  border-radius: 16/$design-num-sp*100vw;
  background-color: $key-color;
  >.inner,>a{
    display: block;
    padding: 40/$design-num-sp*100vw;
    @include setTbl;
    >*{
      vertical-align: top;
    }
  }
  .col-ttl{
    width: 2.5em;
    position: relative;
    &::before{
      content: '';
      width: 1px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background-image :
        none,  /* 上の線 */
        linear-gradient(to bottom, $bg-color3,$bg-color3 2px, transparent 2px, transparent 2px), /* 右の線 */
        none,  /* 上の線 */
        none;  /* 上の線 */
      background-size:
        0px 0px,  /* 上の線 */
        2px 8px, /* 右の線 */
        0px 0px,  /* 下の線 */
        0px 0px;   /* 左の線 */
      background-position:
        left top,  /* 上の線 */
        right top, /* 右の線 */
        right bottom,  /* 下の線 */
        left bottom;   /* 左の線 */
      background-repeat:
        repeat-x,  /* 上の線 */
        repeat-y, /* 右の線 */
        repeat-x,  /* 下の線 */
        repeat-y;   /* 左の線 */
    }
  }
  h2{
    writing-mode: vertical-rl;
    text-orientation: upright;
    color: $bg-color3;
    @include fz_vw(26);
    line-height: 1em;
    position: absolute;
    left: 0;
    top: 0;
  }
  h3{
    color: $bg-color3;
    @include fz_vw(30);
    line-height: 1em;
    padding-left: 38/$design-num-sp*100vw;
  }
  .fv-koyomi-ttl{
    padding: 0 6em 22/$design-num-sp*100vw 0px;
    position: relative;
    &::before{
      content: '';
      @include dotted($bg-color3,2,4,1);
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
    }
  }
  .koyomi-col-btn{
    position: absolute;
    right: 0;
    top: -10/$design-num-sp*100vw;
    span{
      color: $bg-color3;
      text-decoration: underline;
    }
  }
  .koyomi-content{
    position: relative;
    .bg-elem{
      position: absolute;
      top: 0;
      right: -40/$design-num-sp*100vw;
      svg{
        width: 180/$design-num-sp*100vw;
        height: 180/$design-num-sp*100vw;
        fill:#c44232;
      }
    }
    >.inner{
      position: relative;
      padding: 20/$design-num-sp*100vw 0 0 38/$design-num-sp*100vw;
      color: $white;
    }
  }
  .ttl-date{
    margin-bottom: 30/$design-num-sp*100vw;
    .year{
      @include fz_vw(22);
      line-height: 1em;
      margin-bottom:15/$design-num-sp*100vw;
    }
    .date{
      display: inline-block;
      @include fz_vw(24);
      line-height: 1em;
      .en{
        @include fz_vw(90);
        line-height: 1em;
        font-family: $fontEn;
      }
      .ja{
        position: relative;
        left: -10/$design-num-sp*100vw;
      }
    }
    .tag{
      display: inline-block;
      @include killSpace;
      li{
        background-color: #8a0e00;
        border-radius: 50px;
        line-height: 1em;
        @include fz_vw(22);
        padding: 10/$design-num-sp*100vw 12/$design-num-sp*100vw 10/$design-num-sp*100vw;
        margin: 0 5/$design-num-sp*100vw 0 0;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-koyomi{
  margin-top: 24px;
  border-radius: 8px;
  >.inner,>a{
    height: 375px;
    padding: 30px 30px;
    transition: .3s;
  }
  >a:hover{
    opacity: .8;
    //background-color: $black;
  }
  h2{
    font-size: 1.4rem;
  }
  h3{
    font-size: 1.8rem;
    padding-left: 22px;
  }
  .fv-koyomi-ttl{
    padding: 0 6em 14px 0px;
  }
  .koyomi-col-btn{
    top: 0;
    a:hover{
      text-decoration: none;
      opacity: .8;
    }
  }
  >a:hover .koyomi-col-btn{
    span{
      text-decoration: none;
      opacity: .8;
    }
  }
  .koyomi-content{
    .bg-elem{
      right: 0;
      svg{
        width: 148px;
        height: 148px;
      }
    }
    >.inner{
      padding: 16px 0 0 24px;
    }
  }
  .ttl-date{
    margin-bottom: 22px;
    .year{
      font-size: 1.3rem;
      margin-bottom: 6px;
    }
    .date{
      display: block;
      font-size: 2.2rem;
      .en{
        font-size: 5.0rem;
      }
      .ja{
        left: -10px;
      }
    }
    .tag{
      display: block;
      li{
        font-size: 1.3rem;
        padding: 4px 10px 5px;
        margin: 0 6px 0 0;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.koyomi-result{
  dl{
    margin-bottom: 30/$design-num-sp*100vw;
    @include setTbl;
    >*{
      vertical-align: top;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  dt{
    width: 140/$design-num-sp*100vw;
    font-weight: bold;
    >span{
      display: block;
      text-align: center;
      border-radius: 50px;
      line-height: 1em;
      @include fz_vw(24);
      padding: 8/$design-num-sp*100vw 10/$design-num-sp*100vw 8/$design-num-sp*100vw;
      background-color: $bg-color3;
      color: $key-black;
    }
  }
  dd{
    padding-left: 1.5em;
    @include fz_vw(26);
  }
  .tx-large{
    @include fz_vw(40);
    line-height: 1em;
    margin-right: 20/$design-num-sp*100vw;
    &:last-child{
      margin-right: 0;
    }
  }
  .elem{
    display: block;
  }
}
@media screen and (min-width: $break-point-middle) {
.koyomi-result{
  dl{
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  dt{
    width: 88px;
    >span{
      font-size: 1.2rem;
      padding: 4px 10px 5px;
    }
  }
  dd{
    font-size: 1.2rem;
  }
  .tx-large{
    font-size: 2.0rem;
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.pr-ec{
  background-color: $white;
  position: relative;
  z-index: 20;
  .bg-elem{
    >span{
      display: block;
      position: absolute;
      z-index: 30;
    }
    >span.style_01{
      top: 570/$design-num-sp*100vw;
      right: 0/$design-num-sp*100vw;
      img{
        @include imgset;
        width: 158/$design-num-sp*100vw;
      }
    }
    >span.style_02{
      top: 560/$design-num-sp*100vw;
      left: 30/$design-num-sp*100vw;
      img{
        @include imgset;
        width: 79/$design-num-sp*100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.pr-ec{
  .bg-elem{
    >span.style_01{
      top: 30px;
      right: 0px;
      img{
        width: 158/$design-num*100vw;
      }
    }
  }
  >.inner{
    @include setTbl;
    height: 468/$design-num*100vw;
  }
}
}
@media screen and (min-width: $design-width) {
.pr-ec{
  .bg-elem{
    >span.style_01{
      img{
        width: 158px;
      }
    }
  }
  >.inner{
    height: 575px;
  }
}
}
//////////////////////////////////
.pr-ec .col-ec{
  background-size: cover;
  background-position: 50% 50%;
  overflow: hidden;
  position: relative;
  height: 530/$design-num-sp*100vw;
  .webp &{
    background-image: url('../images/store-cover.webp');
  }
  .no-webp &{
    background-image: url('../images/store-cover.jpg');
  }
  >.inner{
    position: relative;
    text-align: center;
    height: 100%;
    @include setTbl;
    >*{
      text-align: center;
    }
  }
  a{
    display: block;
    height: 100%;
    position: relative;
    >*{
      position: relative;
    }
    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: block;
      width: 100%;
      background-color: rgba(0, 0, 0, .3);
      transition: .3s;
    }
    h2{
      width: 315/$design-num-sp*100vw;
      margin: 0 auto 35/$design-num-sp*100vw;
      img{
        @include imgset;
      }
    }
    .summary{
      @include fz_vw(24);
      line-height: (36/24)+em;
      color: $white;
      margin-bottom: 38/$design-num-sp*100vw;
    }
    .btn{
      >*{
        display: inline-block;
        min-width: 216/$design-num-sp*100vw;
        margin: 0 auto;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.pr-ec .col-ec{
  height: 100%;
  a{
    h2{
      width: 196px;
      margin: 0 auto 20px;
    }
    .summary{
      font-size: 1.5rem;
      line-height: (25/15)+em;
      margin-bottom: 28px;
    }
    .btn{
      >*{
        min-width: 150px;
      }
    }
  }
  a:hover{
    &::before{
      opacity: .8;
    }
    .btn>*::before{
      //left: 0;
    }
  }
}
}
//////////////////////////////////
.pr-ec .col-sns{
  height: 720/$design-num-sp*100vw;
  @include setTbl;
  >.inner{
    position: relative;
    text-align: center;
  }
  h2{
    width: 354/$design-num-sp*100vw;
    margin: 0 auto 40/$design-num-sp*100vw;
    img{
      @include imgset;
    }
  }
  h3{
    @include fz_vw(26);
    line-height: 1em;
    white-space: nowrap;
    position: relative;
    display: inline-block;
    margin-bottom: 60/$design-num-sp*100vw;
    &::before,&::after{
      content: '';
      display: block;
      width: 30/$design-num-sp*100vw;
      height: 4/$design-num-sp*100vw;
      position: absolute;
      background-color: $key-black;
      top: 16/$design-num-sp*100vw;
    }
    &::before{
      left: -40/$design-num-sp*100vw;
      transform: rotate(50deg);
    }
    &::after{
      right: -40/$design-num-sp*100vw;
      transform: rotate(-50deg);
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.pr-ec .col-sns{
  height: auto;
  display: table-cell;
  width: auto;
  >.inner{
    display: block;
  }
  h2{
    width: 275px;
    margin: 0 auto 30px;
  }
  h3{
    font-size: 1.5rem;
    margin-bottom: 30px;
    &::before,&::after{
      width: 20px;
      height: 2px;
      background-color: $key-black;
      top: 8px;
    }
    &::before{
      left: -20px;
    }
    &::after{
      right: -20px;
    }
  }
}
}
//////////////////////////////////
.pr-ec .list-sns{
  padding-left: $sp-pad2;
  padding-right: $sp-pad2;
  ul{
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
  }
  li{
    width: 195/$design-num-sp*100vw;
    position: relative;
    &:nth-child(n+4){
      margin-top: 20/$design-num-sp*100vw;
    }
  }
  a{
    display: block;
    border-radius: 8px;
    background-color: $facebook-color;
    color: $white;
    position: relative;
    overflow: hidden;
    height: 159/$design-num-sp*100vw;
    &.twitter{
      background-color: $twitter-color;
      .icon i{
        color: $twitter-color;
      }
    }
    &.instagram{
      background-color: $instagram-color;
      .icon i{
        color: $instagram-color;
      }
    }
    &.spotify{
      background-color: $spotify-color;
      .icon i{
        color: $spotify-color;
      }
    }
    &.youtube{
      background-color: $youtube-color;
      .icon i{
        color: $youtube-color;
      }
    }
    &.tiktok{
      background-color: $tiktok-color;
      .icon i{
        color: $tiktok-color;
      }
    }
    .wrap{
      @include setTbl;
      height: 100%;
      position: relative;
    }
    .icon{
      width: 62/$design-num-sp*100vw;
      height: 62/$design-num-sp*100vw;
      text-align: center;
      background-color: $white;
      border-radius: 100%;
      margin: 0 auto 10/$design-num-sp*100vw;
      display: block;
      i{
        @include fz_vw(30);
        line-height: 62/$design-num-sp*100vw;
        color: $facebook-color;
        transition: .5s;
      }
    }
    .label{
      display: block;
      text-align: center;
      @include fz_vw(24);
      line-height: 1em;
      font-family: $fontEn;
      font-weight: bold;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.pr-ec .list-sns{
  padding-left: 0;
  padding-right: 0;
  ul{
    max-width: 508px;
    margin: 0 auto;
  }
  li{
    width: 160/508*100%;
    &:nth-child(n+4){
      margin-top: 12px;
    }
  }
  a{
    height: 130/$design-num*100vw;
    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: -50px;
      right: 0;
      bottom: 0;
      border-right: 50px solid transparent;
      border-top: 150px solid $key-black;
      transform: translateX(-100%);
      transition: .5s $ease-in-out-quad;
    }
    .icon{
      width: 40px;
      height: 40px;
      margin: 0 auto 10px;
      i{
        font-size: 2.4rem;
        line-height: 40px;
      }
    }
    .label{
      font-size: 1.8rem;
    }
  }
  a:hover{
    &::before{
      transform: translateX(20%);
    }
    .icon{
      i{
        color: $key-black;
      }
    }
  }
}
}
@media screen and (min-width: $design-width) {
.pr-ec .list-sns{
  a{
    height: 130px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.global-keyword{
  position: relative;
  z-index: 10;
  .bg-elem{
    >span{
      position: absolute;
      img{
        @include imgset;
      }
      &:first-child{
        right: 40/$design-num-sp*100vw;
        bottom: -80/$design-num-sp*100vw;
        img{
          width: 285/$design-num-sp*100vw;
        }
      }
      &:last-child{
        right: 352/$design-num-sp*100vw;
        bottom: -90/$design-num-sp*100vw;
        img{
          width: 231/$design-num-sp*100vw;
        }
      }
    }
  }
  >.inner{
    padding-top: 80/$design-num-sp*100vw;
    padding-bottom: 100/$design-num-sp*100vw;
    padding-left: $sp-pad;
    padding-right: $sp-pad;
  }
  .sec-keyword{
  }
}
@media screen and (min-width: $break-point-middle) {
.global-keyword{
  .bg-elem{
    >span{
      &:first-child{
        right: 80px;
        bottom: auto;
        top: 44px;
        img{
          width: 285px;
        }
      }
      &:last-child{
        right: 60px;
        bottom: auto;
        top: 222px;
        img{
          width: 329px;
        }
      }
    }
  }
  >.inner{
    @include contentWrap;
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .sec-keyword{
    width: 860/$content-num-base*100%;
    //max-width: 860px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.sec-keyword{
	.col-keyword{
    margin-top: 30/$design-num-sp*100vw;
	}
}
@media screen and (min-width: $break-point-middle) {
.sec-keyword{
	.col-keyword{
		margin-top: 22px;
	}
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.nav-content{
  position: relative;
  background-color: $bg-color2;
  .bg-elem{
    display: none;
  }
  >.inner{
    padding-top: 55/$design-num-sp*100vw;
    padding-bottom: 65/$design-num-sp*100vw;
    padding-left: $sp-pad;
    padding-right: $sp-pad;
  }
  .wrap-style{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after{
      content: '';
      display: block;
      width: 230/$design-num-sp*100vw;
    }
    >*{
      width: 230/$design-num-sp*100vw;
      &:nth-child(n+4){
        margin-top: 40/$design-num-sp*100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.nav-content{
  .bg-elem{
    display: block;
    >span{
      position: absolute;
      opacity: 0;
      right: 80px;
      top: 120px;
      transition: .6s;
      mix-blend-mode: multiply;
      &.active{
        opacity: 1;
        top: -25px;
      }
      img{
        @include imgset;
        width: 124px;
      }
    }
  }
  >.inner{
    @include contentWrap;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .wrap-style{
    &::before,&::after{
      width: 276/$content-num-base*100%;
    }
    &::before{
      order: 1;
    }
    >*{
      width: 276/$content-num-base*100%;
      &:nth-child(n+4){
        margin-top: 0;
      }
      &:nth-child(n+5){
        margin-top: 34/$design-num*100vw;
      }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.breadcrumb{
  background-color: $key-black;
  >.inner{
    display: flex;
    align-items: center;
    min-height: 50/$design-num-sp*100vw;
    justify-content: flex-start;
    padding: 0 $sp-pad;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  ul{
    @include killSpace;
    white-space: nowrap;
  }
  li{
    @include fz_vw(20);
    line-height: 1em;
    color: $key-gray1;
    font-weight: bold;
    &::after{
      @include iconFont;
      content: '\EA0F';
      color: $white;
      padding: 0 10/$design-num-sp*100vw;
      @include fz_vw(14);
      line-height: 1em;
    }
    &:last-child::after{
      display: none;
    }
  }
  a{
    color: $white;
    @include over_01($white);
  }
}
@media screen and (min-width: $break-point-middle) {
.breadcrumb{
  >.inner{
    min-height: 30px;
    padding: 0 70px;
  }
  li{
    font-size: 1.2rem;
    &::after{
      padding: 0 8px;
      font-size: 1.0rem;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.mainttl-style_01{
  position: relative;
  z-index: 10;
  >.bg-elem{
    >span{
      position: absolute;
      bottom: -100/$design-num-sp*100vw;
      left: 20/$design-num-sp*100vw;
      mix-blend-mode: multiply;
      img{
        @include imgset;
        width: 191/$design-num-sp*100vw;
      }
    }
  }
  >.bg-elem.style_01{
    >span{
      left: 20/$design-num-sp*100vw;
      bottom: -50/$design-num-sp*100vw;
      img{
        width: 122/$design-num-sp*100vw;
      }
    }
  }
  >.wrapper{
    position: relative;
    min-height: 400/$design-num-sp*100vw;
    display: flex;
    align-items: center;
    &.style_01{
      justify-content: center;
    }
    >.bg-elem{
      position: absolute;
      top: 0;
      right: 0;
      svg{
        fill:$white;
        width: 371/$design-num-sp*100vw;
        height: 371/$design-num-sp*100vw;
      }
    }
  }
  >.wrapper>.inner{
    padding: 0 $sp-pad2;
    box-sizing: content-box;
  }
  h1{
    @include fz_vw(70);
    line-height: 1em;
    margin-bottom: 26/$design-num-sp*100vw;
    position: relative;
    left: -.1em;
    &.style_01{
      @include fz_vw(65);
    }
    &.style_02{
      @include fz_vw(50);
      text-align: center;
      line-height: 1.4em;
    }
  }
  .ruby{
    color: $key-gray1;
    @include fz_vw(24);
    line-height: 1em;
    //padding-top: 20/$design-num-sp*100vw;
    text-align: center;
    font-family: $fontEn;
  }
  .summary{
    line-height: (34/24)+em;
    @include fz_vw(24);
  }
  .col-img{
    width: 312/$design-num-sp*100vw;
    position: absolute;
    right: 20/$design-num-sp*100vw;
    bottom: -80/$design-num-sp*100vw;
    mix-blend-mode: multiply;
    >span{
      display: block;
    }
    img{
      @include imgset;
    }
    &.style_01{
      width: 249/$design-num-sp*100vw;
      bottom: -50/$design-num-sp*100vw;
    }
    &.style_02{
      width: 195/$design-num-sp*100vw;
      right: 40/$design-num-sp*100vw;
      bottom: -50/$design-num-sp*100vw;
    }
    &.style_03{
      width: 279/$design-num-sp*100vw;
    }
    &.style_04{
      width: 282/$design-num-sp*100vw;
    }
    &.style_05{
      width: 176/$design-num-sp*100vw;
    }
    &.style_06{
      width: 290/$design-num-sp*100vw;
    }
    &.style_07{
      width: 250/$design-num-sp*100vw;
      bottom: -50/$design-num-sp*100vw;
    }
    &.style_08{
      width: 250/$design-num-sp*100vw;
      bottom: -50/$design-num-sp*100vw;
    }
    &.style_09{
      width: 266/$design-num-sp*100vw;
      bottom: -50/$design-num-sp*100vw;
    }
    &.style_10{
      width: 279/$design-num-sp*100vw;
      bottom: -60/$design-num-sp*100vw;
    }
    &.style_11{
      width: 191/$design-num-sp*100vw;
      bottom: -60/$design-num-sp*100vw;
    }
    &.style_12{
      width: 156/$design-num-sp*100vw;
      bottom: -60/$design-num-sp*100vw;
    }
  }
  &.style_01{
    .col-content{
      .effect_01>span{
        justify-content: center;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.mainttl-style_01{
  >.bg-elem{
    @include contentWrap;
    >span{
      bottom: -70px;
      left: 40px;
      img{
        width: 147px;
      }
    }
  }
  >.bg-elem.style_01{
    >span{
      bottom: -70px;
      left: -0px;
      img{
        width: 122px;
      }
    }
  }
  >.wrapper{
    overflow: hidden;
    min-height: 300px;
    >.bg-elem{
      top: -35%;
      svg{
        width: 699px;
        height: 711px;
      }
    }
  }
  >.wrapper>.inner{
    max-width: 945px;
    padding: 0 40px;
    margin: 0 auto;
    position: relative;
  }
  .tbl{
    @include setTbl;
  }
  h1{
    font-size: 6.4rem;
    margin-bottom: 25px;
    &.style_01{
      font-size: 6.4rem;
    }
    &.style_02{
      font-size: 5.4rem;
    }
  }
  .ruby{
    font-size: 1.4rem;
    //padding-top: 10px;
  }
  .summary{
    line-height: (26/16)+em;
    font-size: 1.6rem;
  }
  .col-img{
    width: 407/945*100%;
    position: relative;
    right: auto;
    bottom: auto;
    &.style_01{
      width: 229/945*100%;
      bottom: auto;
    }
    &.style_02{
      width: 195/945*100%;
      right: auto;
      bottom: auto;
    }
    &.style_03{
      width: 279/945*100%;
    }
    &.style_04{
      width: 302/945*100%;
      bottom: 20px;
    }
    &.style_05{
      width: 176/945*100%;
    }
    &.style_06{
      width: 307/945*100%;
    }
    &.style_07{
      width: 270/945*100%;
      bottom: auto;
    }
    &.style_08{
      width: 278/945*100%;
      bottom: auto;
    }
    &.style_09{
      width: 266/945*100%;
      bottom: auto;
    }
    &.style_10{
      width: 309/945*100%;
      bottom: auto;
    }
    &.style_11{
      width: 191/945*100%;
      bottom: auto;
    }
    &.style_12{
      width: 176/945*100%;
      bottom: auto;
    }
  }
}
}
///////////////////////////////////////////
.mainttl-style_02{
  @extend .mainttl-style_01;
  >.bg-elem{
    >span{
      img{
        width: 227/$design-num-sp*100vw;
      }
    }
  }
  .col-img{
    width: 255/$design-num-sp*100vw;
  }
  // .col-img{
  //   width: 312/$design-num-sp*100vw;
  //   position: absolute;
  //   right: 20/$design-num-sp*100vw;
  //   bottom: -80/$design-num-sp*100vw;
  //   mix-blend-mode: multiply;
  //   >span{
  //     display: block;
  //   }
  //   img{
  //     @include imgset;
  //   }
  // }
}
@media screen and (min-width: $break-point-middle) {
.mainttl-style_02{
  >.bg-elem{
    >span{
      img{
        width: 227px;
      }
    }
  }
  .col-img{
    width: 319/945*100%;
  }
}
}
///////////////////////////////////////////
.mainttl-style_03{
  @extend .mainttl-style_01;
  // >.bg-elem{
  //   >span{
  //     position: absolute;
  //     bottom: -100/$design-num-sp*100vw;
  //     left: 0/$design-num-sp*100vw;
  //     mix-blend-mode: multiply;
  //     img{
  //       @include imgset;
  //       width: 214/$design-num-sp*100vw;
  //     }
  //   }
  // }
  // .col-img{
  //   width: 312/$design-num-sp*100vw;
  //   position: absolute;
  //   right: 20/$design-num-sp*100vw;
  //   bottom: -80/$design-num-sp*100vw;
  //   mix-blend-mode: multiply;
  //   >span{
  //     display: block;
  //   }
  //   img{
  //     @include imgset;
  //   }
  // }
}
@media screen and (min-width: $break-point-middle) {
.mainttl-style_03{
  // >.bg-elem{
  //   >span{
  //     img{
  //       width: 219px;
  //     }
  //   }
  // }
  .col-img{
    width: 311/945*100%;
  }
}
}
///////////////////////////////////////////
.mainttl-style_04{
  position: relative;
  height: 500/$design-num-sp*100vw;
  .bg-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &::after{
      content: '';
      display: block;
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .3);
    }
    img{
      @include imgset;
      width: 100%;
      height: 100%!important;
      object-fit: cover;
    }
  }
  >.wrapper{
    @include setTbl;
    height: 100%;
    >*{
      text-align: center;
    }
  }
  h1{
    line-height: 1.2em;
    @include fz_vw(70);
    color: $white;
    >span{
      justify-content: center;
    }
  }
  .en{
    position: relative;
    margin-top: 14/$design-num-sp*100vw;
    @include fz_vw(20);
    line-height: 1em;
    font-family: $fontEn;
    opacity: 0;
    transition: .3s;
    color: $white;
    &.active{
      opacity: 1;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.mainttl-style_04{
  height: 450px;
  h1{
    font-size: 6.4rem;
  }
  .en{
    margin-top: 20px;
    font-size: 1.4rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.content-search{
  position: relative;
  >.wrapper{
    background-color: $bg-color2;
    padding: 70/$design-num-sp*100vw $sp-pad2 100/$design-num-sp*100vw;
  }
  >.bg-elem{
    >span{
      position: absolute;
      img{
        //mix-blend-mode: multiply;
        @include imgset;
      }
      &.style_01{
        left: 20/$design-num-sp*100vw;
        bottom: -70/$design-num-sp*100vw;
        .single &{
          bottom: auto;
          top: -150/$design-num-sp*100vw;
        }
        img{
          width: 152/$design-num-sp*100vw;
        }
        .archive-event &{
          display: none;
        }
      }
      &.style_02{
        right:20/$design-num-sp*100vw;
        bottom: -40/$design-num-sp*100vw;
        .single &{
          bottom: auto;
          top: -90/$design-num-sp*100vw;
        }
        img{
          width: 169/$design-num-sp*100vw;
        }
      }
    }
  }
  >.wrapper>.inner{
    position: relative;
  }
  .col-form{
    margin-top: 30/$design-num-sp*100vw;
    >*{
      margin-bottom: 38/$design-num-sp*100vw;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .col-input{
    width: 100%;
  }
  .col-btn{
    width: 100%;
    >*{
      display: block;
      margin:0 auto;
      width: 390/$design-num-sp*100vw;
    }
  }
  .menuOpen &{
		opacity: 0;
	}
}
@media screen and (min-width: $break-point-middle) {
.content-search{
  >.wrapper{
    padding: 30px 40px 55px;
    .single &{
      padding: 56px 40px 94px;
    }
  }
  >.bg-elem{
    @include contentWrap;
    >span{
      &.style_01{
        left: -40px;
        bottom: -20px;
        .single &{
          top: -360px;
        }
        img{
          width: 128px;
        }
      }
      &.style_02{
        right:-30px;
        bottom: -30px;
        .single &{
          top: -337px;
        }
        img{
          width: 151px;
        }
      }
    }
  }
  >.wrapper>.inner{
    @include contentWrap;
    padding-left: 120/$design-num*100vw;
    padding-right: 120/$design-num*100vw;
  }
  .col-form{
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    >*{
      margin-bottom: 0;
    }
  }
  .col-input{
    width: 250/930*100%;
    &.style_01{
      width: 280/930*100%;
    }
  }
  .col-btn{
    width: 100/930*100%;
    >*{
      width: 100%;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.content-search-panel{
  display: none;
  position: absolute;
  width: 100%;
  top: 218/$design-num-sp*100vw;
  left: 0;
  z-index: 100;
  &.style_01{
    top: 375/$design-num-sp*100vw;
  }
  >.wrapper{
    position: relative;
    border-radius: 0 0 8px 8px;
    border: 1px solid $key-black;
    border-top: none;
    background-color: $white;
  }
  >.wrapper>.inner{
    padding: 20/$design-num-sp*100vw 30/$design-num-sp*100vw 60/$design-num-sp*100vw;
  }
  .btn-close{
    display: none;
  }
  .sec-btn{
    margin-top: 70/$design-num-sp*100vw;
    display: flex;
    flex-direction: column-reverse;
    >*{
      width: 400/$design-num-sp*100vw;
      margin: 0 auto;
      display: block;
      margin-top: 30/$design-num-sp*100vw;
      &:last-child{
        margin-top: 0;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.content-search-panel{
  max-width: 1260px;
  padding-left: 120/$design-num*100vw;
  padding-right: 120/$design-num*100vw;
  padding-top: 16px;
  top: auto;
  &.style_01{
    top: auto;
  }
  >.wrapper{
    border-radius: 8px;
    border: 1px solid $key-black;
  }
  >.wrapper>.inner{
    padding: 35px 35px 45px 35px;
  }
  .btn-close{
    display: block;
    font-size: 1.6rem;
    line-height: 1em;
    color: $key-color;
    right: 12px;
    top: 12px;
    position: absolute;
    cursor: pointer;
  }
  .sec-btn{
    margin-top: 46px;
    display: block;
    @include killSpace;
    text-align: center;
    >*{
      width: auto;
      min-width: 180px;
      margin: 0 9px;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.nav-style_01{
  ul{
    @include killSpace;
    text-align: center;
  }
  li{
    margin: 0 10/$design-num-sp*100vw;
  }
  a{
    display: flex;
    align-items: center;
    min-height: 65/$design-num-sp*100vw;
    background-color: $white;
    min-width: 185/$design-num-sp*100vw;
    padding: 0 20/$design-num-sp*100vw;
    font-weight: bold;
    justify-content: center;
    border-radius: 50px;
    @include fz_vw(26);
    line-height: 1em;
    overflow: hidden;
    position: relative;
    >span{
      position: relative;
    }
    &.active{
      cursor: default;
      pointer-events: none;
      background-color: $key-black;
      color: $white;
    }
  }
  &.style_01{
    position: absolute;
    right: 0;
    top: 10/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.nav-style_01{
  ul{
    text-align: right;
  }
  li{
    margin: 0 10px 0 0;
    &:last-child{
      margin-right: 0;
    }
  }
  a{
    min-height: 40px;
    min-width: 110px;
    padding: 0 10px;
    font-size: 1.4rem;
    &::before{
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      position: absolute;
      left: -100%;
      top: 0;
      transition: .3s;
      background-color: $key-black;
    }
  }
  a:hover{
    color: $white;
    &::before{
      left: 0;
    }
  }
  &.style_01{
    position: relative;
    top: auto;
  }
}
}
//////////////////////////////////////////////////
.nav-style_02{
  @extend .nav-style_01;
  margin-left: -($sp-pad-num/$design-num-sp*100vw);
  margin-right: -($sp-pad-num/$design-num-sp*100vw);
  ul{
    overflow: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  li{
    &:first-child{
      margin-left: 20/$design-num-sp*100vw;
    }
  }
  a{
    padding: 0 30/$design-num-sp*100vw;
    color: $key-gray1;
  }
}
@media screen and (min-width: $break-point-middle) {
.nav-style_02{
  ul{
    text-align: center;
  }
  li{
    margin: 0 10px 0 0;
    &:first-child{
      margin-left: 0;
    }
  }
  a{
    padding: 0 20px;
  }
}
}
//////////////////////////////////////////////////
.nav-style_03{
  border-top: 1px solid $key-gray1;
  border-bottom: 1px solid $key-gray1;
  padding: 40/$design-num-sp*100vw 0;
  @include fz_vw(24);
  line-height: 1em;
  ul{
    @include setTbl;
  }
  .back{
    border-left:  1px solid $key-gray1;
    border-right:  1px solid $key-gray1;
    text-align: center;
  }
  .prev,.next{
    position: relative;
    .label{
      position: relative;
      top: 1px;
    }
  }
  .prev{
    .label{
      padding-left: 8/$design-num-sp*100vw;
    }
    .icon{
      transform: rotate(180deg);
    }
  }
  .next{
    text-align: right;
    .label{
      padding-right: 5px;
    }
    .icon{
      left: auto;
      right: 0;
    }
  }
  .icon{
    @include arrow_02;
    position: relative;
    display: inline-block;
    top: -1px!important;
    background-color: $key-black;
    transition: .3s;
    i{
      position: relative;
      top: 1px;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.nav-style_03{
  padding: 20px 0;
  font-size: 1.4rem;
  .prev,.next{
    position: relative;
    .label{
      top: auto;
    }
  }
  .prev{
    .label{
      padding-left: 5px;
    }
  }
  .icon{
    i{
      top: auto;
    }
  }
  a:hover{
    color: $key-color;
    .icon{
      background-color: $key-color;
    }
  }
  .prev a:hover .icon{
    left: -2px;
  }
  .next a:hover .icon{
    right: -2px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.sec-pager{
  >.inner{
    @include killSpace;
    text-align: center;
  }
  a,>.inner>span{
    margin: 0 12/$design-num-sp*100vw;
    width: 76/$design-num-sp*100vw;
    height: 76/$design-num-sp*100vw;
    @include fz_vw(30);
    line-height: 80/$design-num-sp*100vw;
    text-align: center;
    font-family: $fontEn;
    background-color: $white;
    border-radius: 100%;
    color: $key-black;
    font-weight: bold;
    i{
      font-weight: normal;
      line-height: 80/$design-num-sp*100vw;
    }
    &.prev{
      i{
        transform: rotate(-180deg);
      }
    }
  }
  >.inner>span{
    background-color: $key-black;
    color: $white;
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-pager{
  a,>.inner>span{
    margin: 0 5px;
    width: 40px;
    height: 40px;
    font-size: 1.4rem;
    line-height: 40px;
    i{
      line-height: 40px;
    }
    &:hover{
      background-color: $key-black;
      color: $white;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.mod-mail-magazine{
  position: fixed;
  left: -100%;
  bottom: 200/$design-num-sp*100vw;
  z-index: 2000;
  transition: .3s;
  .loaded &{
    left:0;
  }
  a{
    padding: 10/$design-num-sp*100vw;
    text-align: center;
    display: block;
    background-color:rgba(255, 255, 255, .8);
    i{
      @include fz_vw(60);
      line-height: 1em;
      color: $key-color;
      transition: .3s;
    }
    .tx{
      text-align: center;
      display: block;
      color: $key-color;
      transition: .3s;
      line-height: 1.2em;
      padding-top: 10/$design-num-sp*100vw;
      @include fz_vw(24);
    }
  }
  &.def{
    opacity: 0;
  }
}
@media screen and (min-width: $break-point-middle) {
.mod-mail-magazine{
  position: fixed;
  bottom: auto;
  top: -webkit-calc(30vh + 200px);
  top: calc(30vh + 200px);
  left: -100%;
  a{
    padding: 10px 12px;
    i{
      font-size: 4.0rem;
    }
    .tx{
      padding-top: 5px;
      font-size: 1.2rem;
    }
  }
  &.def{
    opacity: 1;
  }
  a:hover{
    background-color: $key-color;
    i{
      color: $white;
    }
    .tx{
      color: $white;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.share_module-all{
  background-color:rgba(255, 255, 255, .8);
  padding: 20/$design-num-sp*100vw 0;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: -100/$design-num-sp*100vw;
  z-index: 2000;
  transition: .3s $ease-out-quad;
  .loaded &{
    bottom: 0;
  }
  .share-list{
    @include setTbl;
    >*{
      text-align: center;
    }
    .btn{
      padding: 0 20/$design-num-sp*100vw;
    }
  }
  .evernote-button img{
    display: inline-block;
  }
  &.def{
    opacity: 0;
  }
}
@media screen and (min-width: $break-point-middle) {
.share_module-all{
  padding: 10px;
  bottom: auto;
  width: auto;
  left: -100%;
  top: 30vh;
  .loaded &{
    left: 0;
    bottom: auto;
  }
  .share-list{
    display: block;
    >*{
      display: block;
      text-align: left;
    }
    .btn{
      margin-bottom: 10px;
      padding: 0;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  &.def{
    opacity: 1;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.share_module-single{
  .label{
    display: block;
    @include fz_vw(28);
    line-height: 1em;
    font-family: $fontEn;
    font-weight: bold;
    margin-bottom: 10/$design-num-sp*100vw;
  }
  .share-icons{
    @include setTbl;
    li{
      height: 118/$design-num-sp*100vw;
      a{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: $white;
        position: relative;
        transition: .3s;
        i{
          @include fz_vw(50);
        }
      }
    }
    li.facebook a{
      background-color: $facebook-color;
    }
    li.twitter a{
      background-color: $twitter-color;
    }
    li.line a{
      background-color: $line-color;
    }
    li.hatena a{
      background-color: $hatena-color;
    }
    li.pinterest a{
      background-color: $pinterest-color;
    }
    li.wechat a{
      background-color: $wechat-color;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.share_module-single{
  position: fixed;
  left: -100%;
  top: 30vh;
  z-index: 2000;
  transition: .6s $ease-out-quad;
  .loaded &{
    left: 0;
  }
  .col-label{
    height: 4em;
  }
  .label{
    display: inline-block;
    font-size: 1.5rem;
    transform: rotate(90deg);
    position: relative;
    left: 5px;
    margin-bottom: 0;
  }
  .share-icons{
    display: block;
    >*{
      display: block;
    }
    li{
      width: 50px;
      height: 50px;
      a{
        text-align: center;
        display: block;
        color: $white;
        position: relative;
        left: -10px;
        padding-left: 10px;
        transition: .3s;
        width: 60px;
        i{
          font-size: 2.2rem;
          line-height: 50px;
        }
      }
      a:hover{
        left: 0px;
        opacity: .9;
      }
    }
    li.wechat a{
      i{
        font-size: 3.0rem;
      }
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.sec-topics{
  position: relative;
  background-color: $white;
  border-radius: 8px;
  overflow: hidden;
  .bg-elem{
    position: absolute;
    top: 0;
    right: 0;
    svg{
      fill:$bg-color1;
      width: 257/$design-num-sp*100vw;
      height: 225/$design-num-sp*100vw;
    }
  }
  >.inner{
    padding: 50/$design-num-sp*100vw;
    position: relative;
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-topics{
  .bg-elem{
    svg{
      width: 225px;
      height: 225px;
    }
  }
  >.inner{
    padding: 33px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.nav-content-carousel{
  position: relative;
  padding: 22/$design-num-sp*100vw $sp-pad 90/$design-num-sp*100vw;
  >.inner{
    position: relative;
  }
  >.bg-elem{
    @include fz_vw(230);
    line-height: 1em;
    position: absolute;
    width: 100%;
    color: $white;
    font-family: $fontEn;
    text-align: right;
    font-weight: bold;
    top: 0px;
  }
}
@media screen and (min-width: $break-point-middle) {
.nav-content-carousel{
  padding: 50px 0 120px;
  >.inner{
    @include contentWrap;
  }
  >.bg-elem{
    font-size: 23rem;
    text-align: center;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.sec-calender{
  position: relative;
  background-color: $white;
  &::after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 1px solid $key-black;
  }
  .fc-day.addLink{
    transition: .3s;
    cursor: pointer;
    .fc-daygrid-day-number{
      text-decoration: underline;
    }
  }
  .fc .fc-toolbar.fc-header-toolbar{
    display: none;
  }
  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
    display: none;
  }
  .fc .fc-daygrid-day-top{
    justify-content: center;
    align-items: center;
  }
  .fc .fc-daygrid-day-frame{
    display: flex;
    justify-content: center;
  }
  .fc-col-header{
    background-color: $key-color;
    @include fz_vw(24);
    a{
      color: $white;
    }
  }
  .fc-theme-standard th{
    border: none;
  }
  .fc .fc-daygrid-day-number{
    @include fz_vw(28);
  }
  td.fc-day-sat a,td.fc-day-sun a{
    color: $key-color;
  }
  .fc-theme-standard td{
    border: 1px solid #efefef;
  }
  .fc .fc-daygrid-day.fc-day-today{
    background-color: #f7e6e1;
  }
  .header-calendar{
    position: absolute;
    right: 0;
    top: -150/$design-num-sp*100vw;
    >.inner{
      @include killSpace;
      white-space: nowrap;
    }
  }
  .col-month{
    font-family: $fontEn;
    font-weight: bold;
    margin-right: 30/$design-num-sp*100vw;
    .num{
      @include fz_vw(150);
      line-height: 1em;
    }
    .label{
      @include fz_vw(24);
      line-height: 1em;
    }
  }
  .col-ctl{
    @include killSpace;
    white-space: nowrap;
    position: relative;
    top: -10/$design-num-sp*100vw;
  }
  .cal-next{
    margin-left: 5/$design-num-sp*100vw;
  }
  .cal-prev,.cal-next{
    cursor: pointer;
    text-align: center;
    color: $white;
    width: 68/$design-num-sp*100vw;
    height: 68/$design-num-sp*100vw;
    background-color: $key-black;
    border-radius: 8px;
    z-index: 20;
    transform: translateY(0%);
    transition: .3s;
  }
  .cal-next:before, .cal-prev:before{
    @include iconFont;
    content: '\EA0F';
    @include fz_vw(24);
    line-height: 68/$design-num-sp*100vw;
    opacity: 1;
  }
  .cal-prev::before{
    content: '\EA16';
  }
  &.style_01{
    .header-calendar{
      left: 0;
      right: auto;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-calender{
  .fc-day.addLink:hover{
    background-color: #fdf6f4;
    .fc-daygrid-day-number{
      text-decoration: none;
      opacity: .9;
    }
  }
  .fc-col-header{
    font-size: 1.7rem;
  }
  .fc .fc-daygrid-day-number{
    font-size: 2.0rem;
  }
  .header-calendar{
    top: -90px;
  }
  .col-month{
    margin-right: 25px;
    .num{
      font-size: 9.0rem;
    }
    .label{
      font-size: 2.0rem;
    }
  }
  .col-ctl{
    top: -10px;
  }
  .cal-next{
    margin-left: 5px;
  }
  .cal-prev,.cal-next{
    width: 40px;
    height: 40px;
    &:hover{
      background-color: $key-color;
    }
    &:active{
      background-color: $key-black;
    }
  }
  .cal-next:before, .cal-prev:before{
    font-size: 1.6rem;
    line-height: 40px;
  }
  &.style_01{
    .header-calendar{
      left: -180px;
      top: 0;
      >.inner{
        >*{
          display: block;
        }
      }
    }
    .col-ctl{
      top: 0px;
      left: 10px;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.calendar-nav{
  padding-top: 55/$design-num-sp*100vw;
  color: $key-color;
  >.inner{
    position: relative;
  }
  .nav-header{
    padding-left: $sp-pad;
    padding-right: $sp-pad;
    margin-bottom: 20/$design-num-sp*100vw;
    ul{
      @include setTbl;
    }
    li{
      line-height: 1em;
    }
    .nav-current{
      @include fz_vw(32);
      font-weight: bold;
      text-align: center;
    }
    .nav-prev,.nav-next{
      @include fz_vw(24);
    }
    .nav-prev{
      text-align: left;
    }
    .nav-next{
      text-align: right;
    }
    a{
      color: $key-color;
      text-decoration: underline;
    }
  }
  .nav-main{
    @include fz_vw(26);
    line-height: 1em;
    >.inner{
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
    ul{
      @include setTbl;
      border-top: 1px solid $key-color;
      border-bottom: 1px solid $key-color;
      width: auto;
      >*{
        text-align: center;
      }
    }
    li{
      white-space: nowrap;
      border-right: 1px solid $key-color;
      &:last-child{
        border-right: none;
      }
    }
    a{
      color: $key-color;
      padding: 32/$design-num-sp*100vw 32/$design-num-sp*100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s;
      >*{
        display: block;
      }
      &.current{
        cursor: default;
        pointer-events: none;
        background-color: $key-color;
        color: $white;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.calendar-nav{
  padding-top: 47px;
  >.inner{
    @include contentWrap;
  }
  .nav-header{
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px;
    .nav-current{
      font-size: 2.2rem;
    }
    .nav-prev,.nav-next{
      font-size: 1.5rem;
    }
    a:hover{
      text-decoration: none;
    }
  }
  .nav-main{
    font-size: 1.4rem;
    ul{
      width: 100%;
    }
    a{
      padding: 16px 10px;
      &.current{
        cursor: default;
        pointer-events: none;
        background-color: $key-color;
        color: $white;
      }
    }
    a:hover{
      text-decoration: underline;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.bnr-style_01{
  a{
    display: block;
    position: relative;
    height: 450/$design-num-sp*100vw;
    .bg-img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &::after{
        content: '';
        display: block;
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $key-color;
        opacity: .9;
        transition: .3s;
      }
      img{
        @include imgset;
        width: 100%;
        height: 100%!important;
        object-fit: cover;
        opacity: 0;
        transition: .3s;
      }
      &.active img{
        opacity: 1;
      }
    }
    >.wrapper{
      @include setTbl;
      height: 100%;
      >*{
        text-align: center;
      }
    }
    .label-ja{
      line-height: 1.2em;
      @include fz_vw(50);
      color: $white;
      font-weight: bold;
      >span{
        justify-content: center;
      }
    }
    .label-en{
      position: relative;
      margin-top: 20/$design-num-sp*100vw;
      @include fz_vw(28);
      line-height: 1em;
      font-family: $fontEn;
      opacity: 0;
      transition: .3s;
      color: $white;
      &.active{
        opacity: 1;
      }
    }
    .icon{
      margin-top: 40/$design-num-sp*100vw;
      display: inline-block;
      background-color: $white;
      border-radius: 100%;
      width: 80/$design-num-sp*100vw;
      height: 80/$design-num-sp*100vw;
      text-align: center;
      position: relative;
      transition: .3s;
      opacity: 0;
      top: 20/$design-num-sp*100vw;
      left: 0;
      &.active{
        opacity: 1;
        top: 0;
      }
      i{
        color: $key-color;
        line-height: 80/$design-num-sp*100vw;
        @include fz_vw(24);
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.bnr-style_01{
  a{
    height: 450px;
    .label-ja{
      font-size: 2.5rem;
    }
    .label-en{
      margin-top: 10px;
      font-size: 1.4rem;
    }
    .icon{
      margin-top: 20px;
      width: 40px;
      height: 40px;
      top: 10px;
      i{
        line-height: 40px;
        font-size: 2.0rem;
      }
    }
  }
  a:hover{
    .bg-img{
      &::after{
        opacity: 1;
      }
    }
    .icon{
      left: 10px;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.like-comp{
  position: absolute;
  top: 10/$design-num-sp*100vw;
  right: 10/$design-num-sp*100vw;
  border-radius: 4px;
  background-color: $key-color;
  color: $white;
  padding: 0/$design-num-sp*100vw 10/$design-num-sp*100vw 7/$design-num-sp*100vw 10/$design-num-sp*100vw;
  z-index: 10;
  line-height: 1em;
  opacity: 0;
  transition: .6s;
  >.inner{
    &::before{
      @include iconFont;
      content: '\EA1A';
      @include fz_vw(15);
      line-height: 1em;
      position: relative;
      top: 2px;
    }
  }
  span{
    @include fz_vw(16);
    line-height: 1em;
    font-weight: bold;
    font-family: $fontEn;
  }
  .active &{
    opacity: 1;
  }
}
@media screen and (min-width: $break-point-middle) {
.like-comp{
  top: 10px;
  right: 10px;
  padding: 3px 4px 2px 4px;
  >.inner{
    &::before{
      font-size: 1.5rem;
    }
  }
  span{
    font-size: 1.6rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.comment-style_01{
  >.inner{
    @include setTbl;
    >*{
      vertical-align: top;
    }
  }
  .col-img{
    width: 100/$design-num-sp*100vw;
    img{
      @include imgset;
      border-radius: 100%;
    }
  }
  .col-content{
    padding-left: 25/$design-num-sp*100vw;
    position: relative;
    line-height: (36/24)+em;
    @include fz_vw(24);
    &::before{
      @include iconFont;
      content: '\EA1B';
      @include fz_vw(50);
      line-height: 1em;
      position: absolute;
      left: 0;
      top: 30/$design-num-sp*100vw;
      color: $bg-color2;
    }
    >.inner{
      background-color: $bg-color2;
      border-radius: 16px;
      padding: 35/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.comment-style_01{
  .col-img{
    width: 120/720*100%;
  }
  .col-content{
    padding-left: 20px;
    line-height: (22/13)+em;
    font-size: 1.3rem;
    &::before{
      font-size: 3.0rem;
      top: 20px;
    }
    >.inner{
      padding: 20px 14px;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.sec-edomachigoyomi{
  position: relative;
  background-color: $key-color;
  overflow: hidden;
  @include maskFade_01;
  a{
    display: block;
    >.inner{
      position: relative;
    }
    .col-content{
      padding: 80/$design-num-sp*100vw 60/$design-num-sp*100vw 110/$design-num-sp*100vw;
      position: relative;
      z-index: 10;
      overflow: hidden;
      >.inner{
        position: relative;
      }
    }
    .col-img{
      height: 100%;
      .eyecatch{
        height: 100%;
        >span{
          display: block;
          height: 100%;
          &:nth-child(n+2){
            display: none;
          }
        }
      }
      img{
        width: 100%;
        height: 100%!important;
        object-fit: cover;
      }
    }
    .sec-ttl{
      position: relative;
      padding: 0 0 22/$design-num-sp*100vw 0;
      margin-bottom: 40/$design-num-sp*100vw;
      &::before{
        content: '';
        @include dotted($bg-color3,2,4,1);
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
      }
    }
    .elem-btn{
      position: absolute;
      right: 0;
      top: 10/$design-num-sp*100vw;
      overflow: hidden;
      >span{
        @include fz_vw(22);
        line-height: 1em;
        color: $bg-color3;
        position: relative;
        &:before{
          content: '';
          height: 1px;
          width: 100%;
          background-color: $bg-color3;
          bottom: 0;
          position: absolute;
          @include transform(translateX(0%));
        }
      }
    }
    .ttl-date{
      color: $white;
      margin-bottom: 28/$design-num-sp*100vw;
      .year{
        @include fz_vw(22);
        line-height: 1em;
        margin-bottom:14/$design-num-sp*100vw;
      }
      .date{
        display: inline-block;
        @include fz_vw(24);
        line-height: 1em;
        .en{
          @include fz_vw(90);
          line-height: 1em;
          font-family: $fontEn;
        }
        .ja{
          position: relative;
          left: -5/$design-num-sp*100vw;
        }
      }
      .tag{
        display: inline-block;
        @include killSpace;
        li{
          background-color: #8a0e00;
          border-radius: 50px;
          line-height: 1em;
          @include fz_vw(22);
          padding: 9/$design-num-sp*100vw 14/$design-num-sp*100vw 10/$design-num-sp*100vw;
          margin: 0 10/$design-num-sp*100vw 0 0;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-edomachigoyomi{
  a{
    >.inner{
      @include setTbl;
      table-layout: auto;
      height: 100%;
    }
    .col-content{
      padding: 70px 120/$design-num*100vw;
      >.inner{
        width: 758/$design-num*100vw;
        min-width: 530px;
      }
    }
    .sec-ttl{
      padding: 0 0 14px 0;
      margin-bottom: 20px;
    }
    .elem-btn{
      top: -webkit-calc(50% - 1.4rem);
      top: calc(50% - 1.4rem);
      >span{
        font-size: 1.3rem;
      }
    }
    .content{
      >.inner{
        @include setTbl;
        >*{
          vertical-align: top;
        }
      }
    }
    .ttl-date{
      width: 288/758*100%;
      margin-bottom: 0;
      .year{
        font-size: 2.0rem;
        margin-bottom:10px;
      }
      .date{
        font-size: 3.2rem;
        .en{
          font-size: 7.6rem;
        }
        .ja{
          left: -10px;
        }
      }
      .tag{
        display: block;
        margin-top: -5px;
        li{
          font-size: 2.0rem;
          padding: 5px 10px 6px;
          margin: 0 6px 0 0;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
  a:hover{
    opacity: .8;
    .elem-btn{
      >span:before{
        animation:linemove_01 0.8s cubic-bezier(0.58, 0.3, 0.005, 1) 0s 1;
      }
    }
  }
}
}
//////////////////////////
.koyomi-result-archive{
  color: $white;
  dl{
    margin-bottom: 30/$design-num-sp*100vw;
    @include setTbl;
    >*{
      vertical-align: top;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  dt{
    width: 140/$design-num-sp*100vw;
    font-weight: bold;
    >span{
      display: block;
      text-align: center;
      border-radius: 50px;
      line-height: 1em;
      @include fz_vw(24);
      padding: 8/$design-num-sp*100vw 10/$design-num-sp*100vw 8/$design-num-sp*100vw;
      background-color: $bg-color3;
      color: $key-black;
    }
  }
  dd{
    padding-left: 1.5em;
    @include fz_vw(26);
  }
  .tx-large{
    @include fz_vw(40);
    line-height: 1em;
  }
  .elem{
    display: block;
  }
}
@media screen and (min-width: $break-point-middle) {
.koyomi-result-archive{
  padding-top: 16px;
  dl{
    margin-bottom: 16px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  dt{
    width: 132px;
    >span{
      font-size: 1.7rem;
      padding: 4px 10px 5px;
    }
  }
  dd{
    font-size: 1.7rem;
    position: relative;
    top: -.4rem;
  }
  .tx-large{
    font-size: 3.2rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.koyomi-cont_01{
  position: relative;
  &::before{
    content: '';
    @include dotted(#565656,2,4,1);
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
  }
  >.inner{
    padding: 30/$design-num-sp*100vw 30/$design-num-sp*100vw 46/$design-num-sp*100vw 30/$design-num-sp*100vw;
  }
  .bg-elem{
    position: absolute;
    right: 20/$design-num-sp*100vw;
    bottom: -30/$design-num-sp*100vw;
    img{
      width: 140/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.koyomi-cont_01{
  >.inner{
    padding: 30px;
  }
  .bg-elem{
    bottom: auto;
    right: -32px;
    top: 30px;
    img{
      width: 140px;
    }
  }
}
}
////////////////////////////////////
.koyomi-cont_01 .sec-ttl{
  text-align: center;
  margin-bottom: 16/$design-num-sp*100vw;
  .sec-catch{
    color: $key-color;
    @include fz_vw(26);
    line-height: 1em;
    position: relative;
    padding: 0 30/$design-num-sp*100vw;
    font-weight: bold;
    display: inline-block;
    &::before,&::after{
      position: absolute;
      content: '';
      display: block;
      width: 24/$design-num-sp*100vw;
      height: 4/$design-num-sp*100vw;
      background-color: $key-color;
      bottom: 8/$design-num-sp*100vw;
    }
    &::before{
      left: 0;
      transform: rotate(50deg);
    }
    &::after{
      right: 0;
      transform: rotate(-50deg);
    }
  }
  .ttl-main{
    @include setTbl;
    width: auto;
    margin: 0 auto;
    .col-icon{
      width: 71/$design-num-sp*100vw;
    }
    .img-style{
      width: 71/$design-num-sp*100vw;
      height: 71/$design-num-sp*100vw;
      border-radius: 100%;
      overflow: hidden;
      img{
        @include imgset;
      }
    }
    .col-ttl{
      white-space: nowrap;
    }
    h2{
      @include fz_vw(38);
      line-height: 1em;
      padding-left: 7/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.koyomi-cont_01 .sec-ttl{
  margin-bottom: 0;
  .sec-catch{
    font-size: 2.0rem;
    margin-bottom: 10px;
    padding: 0 25px;
    &::before,&::after{
      width: 20px;
      height: 2px;
      bottom: 8px;
    }
  }
  .ttl-main{
    .col-icon{
      width: 56px;
    }
    .img-style{
      width: 56px;
      height: 56px;
    }
    h2{
      font-size: 3.2rem;
      padding-left: 7px;
    }
  }
}
}
////////////////////////////////////
.koyomi-cont_01 .sec-cont_01{
  @include setTbl;
  width: auto;
  margin: 0 auto 30/$design-num-sp*100vw;
  .col{
    width: 290/$design-num-sp*100vw;
    text-align: center;
    white-space: nowrap;
  }
  .main{
    @include fz_vw(70);
    line-height: 1em;
    font-weight: bold;
  }
  .info{
    padding-top: 12/$design-num-sp*100vw;
    @include fz_vw(22);
  }
}
@media screen and (min-width: $break-point-middle) {
.koyomi-cont_01 .sec-cont_01{
  margin: 0 auto 26px;
  .col{
    width: 210px;
  }
  .main{
    font-size: 6.4rem;
  }
  .info{
    padding-top: 16px;
    font-size: 1.4rem;
  }
}
}
////////////////////////////////////
.koyomi-cont_01 .sec-cont_02{
  >.inner{
    // @include setTbl;
    width: auto;
    margin: 0 auto;
  }
  .col{
    //width: 290/$design-num-sp*100vw;
    text-align: center;
    padding: 0 20/$design-num-sp*100vw;
  }
  .main{
    color: $key-color;
    font-weight: bold;
  }
  .time{
    font-family: $fontEn;
    @include fz_vw(64);
    line-height: 1em;
  }
  .tx{
    @include fz_vw(26);
    line-height: 1em;
    padding-left: 8/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.koyomi-cont_01 .sec-cont_02{
  .col{
    //width: 210px;
    padding: 0 10px;
  }
  .time{
    font-size: 5.2rem;
  }
  .tx{
    font-size: 1.6rem;
    padding-left: 8px;
  }
}
}
////////////////////////////////////
.koyomi-cont_01 .elemwrap{
  margin-top: 30/$design-num-sp*100vw;
  a{
    width: 390/$design-num-sp*100vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 113/$design-num-sp*100vw;
    position: relative;
    color: $white;
    background-color: $twitter-color;
    border-radius: 50px;
    overflow: hidden;
    justify-content: center;
    &::before{
      content: '';
      width: 100%;
      position: absolute;
      left: -100%;
      top: 0;
      bottom: 0;
      transition: .3s;
      background-color: lighten($twitter-color,5%);
    }
    .tx{
      position: relative;
      font-weight: bold;
      @include fz_vw(26);
      line-height: 1em;
    }
    .icon{
      @include fz_vw(40);
      line-height: 1em;
      padding-right: 10/$design-num-sp*100vw;
      position: relative;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.koyomi-cont_01 .elemwrap{
  margin-top: 18px;
  a{
    width: 260px;
    height: 73px;
    .tx{
      font-size: 1.8rem;
    }
    .icon{
      font-size: 3.0rem;
      padding-right: 5px;
      top:2px;
    }
  }
  a:hover{
    &::before{
      left: 0;
    }
  }
}
}
////////////////////////////////////
.koyomi-ttl{
  text-align: center;
  margin-bottom: 28/$design-num-sp*100vw;
  >span{
    display: inline-block;
    @include fz_vw(24);
    line-height: 1em;
    color: $key-color;
    border: 1px solid $key-color;
    border-radius: 50px;
    padding: 10/$design-num-sp*100vw 20/$design-num-sp*100vw 12/$design-num-sp*100vw;
    min-width: 5em;
  }
}
@media screen and (min-width: $break-point-middle) {
.koyomi-ttl{
  margin-bottom: 20px;
  >span{
    font-size: 1.7rem;
    padding: 4px 10px 6px;
  }
}
}
////////////////////////////////////
.koyomi-cont_02{
  >.inner{
    padding: 20/$design-num-sp*100vw 20/$design-num-sp*100vw 30/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.koyomi-cont_02{
  >.inner{
    padding: 20px 20px 30px;
  }
}
}
////////////////////////////////////
.koyomi-cont_02 .sec-cont_01{
  margin: 0 auto 40/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.koyomi-cont_02 .sec-cont_01{
  max-width: 440px;
  margin: 0 auto 14px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.koyomi-link-list{
  min-height: 300/$design-num-sp*100vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  li{
    margin-bottom: 20/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
  a{
    @include fz_vw(32);
    line-height: 1.6em;
    @include over_02;
    overflow: hidden;
    display: inline-block;
  }
}
@media screen and (min-width: $break-point-middle) {
.koyomi-link-list{
  min-height: 150px;
  li{
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  a{
    font-size: 1.6rem;
    &:hover{
      opacity: .8;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.sec-hitokoto{
  position: relative;
  .avater{
    position: absolute;
    width: 101/$design-num-sp*100vw;
    height: 101/$design-num-sp*100vw;
    overflow: hidden;
    border-radius: 100%;
    border: 1px solid $key-color;
    right: -5/$design-num-sp*100vw;
    top: -10/$design-num-sp*100vw;
    background-color: $white;
    z-index: 10;
  }
  >.inner{
    background-color: $white;
    border-radius: 16/$design-num-sp*100vw;
    min-height: 230/$design-num-sp*100vw;
    display: flex;
    align-items: center;
    padding: 0 20/$design-num-sp*100vw;
    justify-content: center;
  }
  .wrap{
    text-align: center;
  }
  .img-style{
    img{
      @include imgset;
    }
  }
  .comment{
    @include fz_vw(36);
    line-height: 1.6em;
    text-align: center;
    font-family: $fontMin;
    text-align: center;
    font-weight: bold;
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-hitokoto{
  .avater{
    width: 120px;
    height: 120px;
    right: -38px;
    top: -2px;
  }
  >.inner{
    border-radius: 16px;
    min-height: 230px;
    padding: 0 40px;
  }
  .comment{
    font-size: 3.0rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.sec-todays-tarot{
  background-color: $white;
  border-radius: 16/$design-num-sp*100vw;
  padding: 40/$design-num-sp*100vw 80/$design-num-sp*100vw;
  .col-tarot{
    width: 360/$design-num-sp*100vw;
    margin: 0 auto;
    a{
      display: block;
      .eyecatch{
        margin-bottom: 22/$design-num-sp*100vw;
        overflow: hidden;
        border-radius: 16/$design-num-sp*100vw;
        img{
          @include imgset;
          transition: .5s;
        }
      }
      .sec-btn{
        .img-style{
          display: block;
          width: 163/$design-num-sp*100vw;
          margin: 0 auto;
          img{
            @include imgset;
          }
        }
      }
    }
  }
  .col-content{
    padding-top: 30/$design-num-sp*100vw;
  }
  .sec-ttl{
    margin-bottom:22/$design-num-sp*100vw;
    @include setTbl;
  }
  .avater{
    width: 121/$design-num-sp*100vw;
    height: 121/$design-num-sp*100vw;
    overflow: hidden;
    border-radius: 100%;
    img{
      @include imgset;
    }
  }
  h2{
    @include fz_vw(28);
    line-height: 1.6em;
    padding-left: 20/$design-num-sp*100vw;
  }
  .summary{
    line-height: (37/26)+em;
    @include fz_vw(26);
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-todays-tarot{
  border-radius: 16px;
  padding: 30px;
  >.inner{
    @include setTbl;
    >*{
      vertical-align: top;
    }
  }
  .col-tarot{
    width: 250/660*100%;
    margin: 0;
    a{
      .eyecatch{
        margin-bottom: 10px;
        border-radius: 16px;
      }
      .sec-btn{
        .img-style{
          width: 90px;
        }
      }
    }
    a:hover{
      opacity: .8;
      .eyecatch{
        img{
          //transform: scale(1.02);
        }
      }
    }
  }
  .col-content{
    padding-left: 26/660*100%;
    padding-top: 26px;
  }
  .sec-ttl{
    margin-bottom: 14px;
  }
  .avater{
    width: 71px;
    height: 71px;
  }
  h2{
    font-size: 1.8rem;
    padding-left: 20px;
  }
  .summary{
    line-height: (22/15)+em;
    font-size: 1.5rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.tarot-result{
  text-align: center;
  margin-bottom: 24/$design-num-sp*100vw;
  a{
    display: block;
    width: 426/$design-num-sp*100vw;
    margin: 0 auto 12/$design-num-sp*100vw;
    .img-style{
      img{
        @include imgset;
        border-radius: 16/$design-num-sp*100vw;
      }
    }
  }
  p{
    text-align: center;
    @include fz_vw(22);
    line-height: (26/22)+em;
  }
  h2{
    margin-top: 60/$design-num-sp*100vw;
    @include fz_vw(34);
    line-height: 1em;
  }
}
@media screen and (min-width: $break-point-middle) {
.tarot-result{
  margin-bottom: 16px;
  a{
    width: 250/660*100%;
    margin: 0 auto 12px;
    .img-style{
      img{
        border-radius: 16px;
      }
    }
  }
  a:hover{
    opacity: .8;
  }
  p{
    font-size: 1.2rem;
  }
  h2{
    margin-top: 20px;
    font-size: 2.6rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.faq-wrap{
  >*{
    margin-bottom: 40/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.faq-wrap{
  >*{
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
}
//////////////////////////////////////
.faq-wrap .sec{
  background-color: $white;
  border-radius: 16/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.faq-wrap .sec{
  border-radius: 16px;
}
}
//////////////////////////////////////
.faq-wrap .sec-question{
  position: relative;
  padding: 40/$design-num-sp*100vw 30/$design-num-sp*100vw;
  cursor: pointer;
  transition: .3s;
  >.inner{
    padding-left: 50/$design-num-sp*100vw;
    padding-right:88/$design-num-sp*100vw;
    position: relative;
    &::before{
      @include iconFont;
      content: '\EA1D';
      color: $key-color;
      @include fz_vw(30);
      line-height: 1em;
      position: absolute;
      top: 2px;
      left: 0;
    }
    p{
      line-height: (37/30)+em;
      @include fz_vw(30);
      font-weight: bold;
    }
  }
  .sec-icon{
    width: 58/$design-num-sp*100vw;
    height: 58/$design-num-sp*100vw;
    border-radius: 100%;
    background-color: $bg-color1;
    position: absolute;
    right: 30/$design-num-sp*100vw;
    $gap:29/$design-num-sp*100vw;
    top: -webkit-calc(50% - #{$gap});
    top: calc(50% - #{$gap});
    transition: .3s;
    .btn{
      span{
        display: block;
        width: 20/$design-num-sp*100vw;
        height: 2/$design-num-sp*100vw;
        background-color: $key-black;
        position: absolute;
        top: 50%;
        $gap:10/$design-num-sp*100vw;
        left: -webkit-calc(50% - #{$gap});
        left: calc(50% - #{$gap});
        transition: .3s;
      }
      span:first-child{
        transform: rotate(90deg);
      }
    }
  }
  &.active{
    .sec-icon{
      background-color: $key-color;
      .btn{
        span{
          background-color: $white;
        }
        span:first-child{
          transform: rotate(0deg);
        }
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.faq-wrap .sec-question{
  padding: 40px 35px;
  >.inner{
    padding-left: 36px;
    padding-right: 110px;
    &::before{
      font-size: 2.0rem;
    }
    p{
      line-height: (24/18)+em;
      font-size: 1.8rem;
    }
  }
  .sec-icon{
    width: 50px;
    height: 50px;
    right: 40px;
    top: -webkit-calc(50% - 25px);
    top: calc(50% - 25px);
    .btn{
      span{
        width: 10px;
        height: 1px;
        left: -webkit-calc(50% - 5px);
        left: calc(50% - 5px);
      }
    }
  }
  &:hover{
    opacity: .9;
    // .sec-icon{
    //   background-color: $key-color;
    //   .btn{
    //     span{
    //       background-color: $white;
    //     }
    //   }
    // }
  }
}
}
//////////////////////////////////////
.faq-wrap .sec-answer{
  border-top: 1px solid #dedede;
  padding: 40/$design-num-sp*100vw 30/$design-num-sp*100vw;
  display: none;
  >.inner{
    padding-left: 50/$design-num-sp*100vw;
    position: relative;
    &::before{
      @include iconFont;
      content: '\EA1E';
      color: $key-color;
      @include fz_vw(30);
      line-height: 1em;
      position: absolute;
      top: 2px;
      left: 0;
    }
  }
  .ttl{
    line-height: (37/30)+em;
    @include fz_vw(30);
    font-weight: bold;
    color: $key-color;
    margin-bottom: 18/$design-num-sp*100vw;
  }
  .summary{
    line-height: (40/26)+em;
    @include fz_vw(26);
  }
}
@media screen and (min-width: $break-point-middle) {
.faq-wrap .sec-answer{
  padding: 40px 35px;
  >.inner{
    padding-left: 36px;
    &::before{
      font-size: 2.0rem;
    }
  }
  .ttl{
    line-height: (24/18)+em;
    font-size: 1.8rem;
    margin-bottom: 14px;
  }
  .summary{
    line-height: (25/15)+em;
    font-size: 1.5rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
#toTop{
  //display: none;
  position: fixed;
  bottom: 200/750*100vw;
  right: 20/750*100vw;
  @include fz_vw(25);
  line-height: 1em;
  font-family: $fontEn;
  text-align: center;
  @include opacity(0);
  @include transition(.3s);
  z-index: 1000;
  //padding: 10/750*100vw;
  //@include basicShadow;
  a{
    @include border-radius(8px);
    color: $white;
    text-decoration: none;
    background-color:$black;
    padding: 10/375*100vw 14/375*100vw;
    display: block;
    &:before{
      display: block;
      margin-bottom: 5px;
      @include iconFont;
      content: "\EA20";
      @include fz_vw(30);
      top: 0;
      position: relative;
      @include transition(.3s);
      line-height: 1em;
    }
  }
  &.active{
    @include opacity(1);
  }
  &.footPos{
    bottom: 300/750*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
#toTop{
  display: block;
  font-size: 13px;
  bottom: 20px;
  right: 20px;
  a{
    padding: 10px 16px;
    text-align: center;
    &:before{
      font-size: 20px;
    }
    &:hover{
      background-color: lighten($black,20%);
      &:before{
        top: 3px;
      }
    }
  }
  &.footPos{
    bottom: 20px;
  }
}
}
@media screen and (min-width: 1200px) {
#toTop{
  bottom: 20px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.bg-elem-mask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  overflow: hidden;
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}

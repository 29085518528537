@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.mrg-style_01{
  margin-bottom: 20/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_01{
  margin-bottom: 16px;
}
}
///////////////////////////////////////
.mrg-style_02{
  padding-top: 84/$design-num-sp*100vw;
  padding-bottom: 130/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_02{
  padding: 46px 0 80px;
}
}
///////////////////////////////////////
.mrg-style_03{
  margin-bottom: 50/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_03{
  margin-bottom: 30px;
}
}
///////////////////////////////////////
.mrg-style_04{
  margin-top: 86/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_04{
  margin-top: 68px;
}
}
///////////////////////////////////////
.mrg-style_05{
  padding-top: 58/$design-num-sp*100vw;
  padding-bottom: 70/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_05{
  padding-top: 56px;
  padding-bottom: 100px;
}
}
///////////////////////////////////////
.mrg-style_06{
  margin-bottom: 90/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_06{
  margin-bottom: 50px;
}
}
///////////////////////////////////////
.mrg-style_07{
  margin-bottom: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_07{
  margin-bottom: 30px;
}
}
///////////////////////////////////////
.mrg-style_08{
  margin-top: 50/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_08{
  margin-top: 38px;
}
}
///////////////////////////////////////
.mrg-style_09{
  margin-bottom: 40/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_09{
  margin-bottom: 28px;
}
}
///////////////////////////////////////
.mrg-style_10{
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_10{
  margin-bottom: 88px;
}
}
///////////////////////////////////////
.mrg-style_11{
  padding-top: 90/$design-num-sp*100vw;
  padding-bottom: 140/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_11{
  padding-top: 0;
  padding-bottom: 70px;
}
}
///////////////////////////////////////
.mrg-style_12{
  padding-top: 80/$design-num-sp*100vw;
  padding-bottom: 105/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_12{
  padding-top: 88px;
  padding-bottom: 70px;
}
}
///////////////////////////////////////
.mrg-style_13{
  margin-top: 65/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_13{
  margin-top: 56px;
}
}
///////////////////////////////////////
.mrg-style_14{
  padding-top: 90/$design-num-sp*100vw;
  padding-bottom: 130/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_14{
  padding-top: 88px;
  padding-bottom: 120px;
}
}
///////////////////////////////////////
.mrg-style_15{
  padding-top: 66/$design-num-sp*100vw;
  padding-bottom: 80/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_15{
  padding-top: 70px;
  padding-bottom: 78px;
}
}
///////////////////////////////////////
.mrg-style_16{
  margin-bottom: 20/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_16{
  margin-bottom: 0;
}
}
///////////////////////////////////////
.mrg-style_17{
  margin-bottom: 80/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_17{
  margin-bottom: 88px;
}
}
///////////////////////////////////////
.mrg-style_18{
  padding-top: 68/$design-num-sp*100vw;
  padding-bottom: 90/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_18{
  padding-top: 40px;
  padding-bottom: 68px;
}
}
///////////////////////////////////////
.mrg-style_19{
  padding-top: 83/$design-num-sp*100vw;
  padding-bottom: 120/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_19{
  padding-top: 60px;
  padding-bottom: 110px;
}
}
///////////////////////////////////////
.mrg-style_20{
  padding-top: 114/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_20{
  padding-top: 90px;
  padding-bottom: 70px;
}
}
///////////////////////////////////////
.mrg-style_21{
  margin-top: 20/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_21{
  margin-top: 10px;
}
}
///////////////////////////////////////
.mrg-style_22{
  margin-bottom: 45/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_22{
  margin-bottom: 0;
}
}
///////////////////////////////////////
.mrg-style_23{
  margin-bottom: 50/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_23{
  margin-bottom: 44px;
}
}
///////////////////////////////////////
.mrg-style_24{
  margin-top: 40/$design-num-sp*100vw;
  margin-bottom: 50/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_24{
  margin-top: 60px;
  margin-bottom: 48px;
}
}
///////////////////////////////////////
.mrg-style_25{
  padding-top: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_25{
  padding-top: 50px;
}
}
///////////////////////////////////////
.mrg-style_26{
  margin-bottom: 45/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_26{
  margin-bottom: 26px;
}
}
///////////////////////////////////////
.mrg-style_27{
  padding-top: 58/$design-num-sp*100vw;
  padding-bottom: 70/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_27{
  padding-top: 56px;
  padding-bottom: 70px;
}
}
///////////////////////////////////////
.mrg-style_28{
  padding-top: 84/$design-num-sp*100vw;
  padding-bottom: 130/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_28{
  padding: 70px 0 80px;
}
}
///////////////////////////////////////
.mrg-style_29{
  margin-top: 40/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_29{
  margin-top: 24px;
}
}
///////////////////////////////////////
.mrg-style_30{
  margin-top: 80/$design-num-sp*100vw;
  margin-bottom: 40/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_30{
  margin-top: 60px;
  margin-bottom: 0;
}
}
///////////////////////////////////////
.mrg-style_31{
  margin-bottom: 32/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_31{
  margin-bottom: 34px;
}
}
///////////////////////////////////////
.mrg-style_32{
  padding-top: 40/$design-num-sp*100vw;
  padding-bottom: 0/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_32{
  padding: 46px 0 0px;
}
}
///////////////////////////////////////
.mrg-style_33{
  padding-bottom: 120/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_33{
  padding-bottom: 120px;
}
}
///////////////////////////////////////
.mrg-style_34{
  margin-bottom: 58/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_34{
  margin-bottom: 40px;
}
}
///////////////////////////////////////
.mrg-style_35{
  margin-bottom: 65/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_35{
  margin-bottom: 40px;
}
}
///////////////////////////////////////
.mrg-style_36{
  margin-bottom: 16/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_36{
  margin-bottom: 14px;
}
}
///////////////////////////////////////
.mrg-style_37{
  margin-bottom: 16/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_37{
  margin-bottom: 0px;
}
}
///////////////////////////////////////
.mrg-style_38{
  margin-bottom: 120/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_38{
  margin-bottom: 28px;
}
}
///////////////////////////////////////
.mrg-style_39{
  margin-bottom: 25/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_39{
  margin-bottom: 20px;
}
}
///////////////////////////////////////
.mrg-style_40{
  margin-top: 40/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_40{
  margin-top: 40px;
}
}
///////////////////////////////////////
.mrg-style_41{
  margin-bottom: 40/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_41{
  margin-bottom: 40px;
}
}
///////////////////////////////////////
.mrg-style_42{
  margin-top: 30/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_42{
  margin-top: 20px;
}
}
///////////////////////////////////////
.mrg-style_43{
  margin-top: 4/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_43{
  margin-top: 2px;
}
}
///////////////////////////////////////
.mrg-style_44{
  margin-bottom: 20/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_44{
  margin-bottom: 10px;
}
}
///////////////////////////////////////
.mrg-style_45{
  margin-top: 30/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_45{
  margin-top: 16px;
}
}
///////////////////////////////////////
.mrg-style_46{
  margin-top: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_46{
  margin-top: 40px;
}
}
///////////////////////////////////////
.mrg-style_47{
  margin-bottom: 40/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_47{
  margin-bottom: 52px;
}
}
///////////////////////////////////////
.mrg-style_48{
  margin-bottom: 40/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_48{
  margin-bottom: 40px;
}
}
///////////////////////////////////////
.mrg-style_49{
  margin-bottom: 60/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_49{
  margin-bottom: 66px;
}
}
///////////////////////////////////////
.mrg-style_50{
  margin-top: 30/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_50{
  margin-top: 40px;
}
}
///////////////////////////////////////
.mrg-style_51{
  margin-top: 30/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_51{
  margin-top: 20px;
}
}
///////////////////////////////////////
.mrg-style_52{
  margin-bottom: 40/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_52{
  margin-bottom: 20px;
}
}
///////////////////////////////////////
.mrg-style_53{
  margin-top: 30/$design-num-sp*100vw;
}
@media screen and (min-width: $break-point-middle) {
.mrg-style_53{
  margin-top: 40px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}

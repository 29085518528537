@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.sec-style_01{
  padding-left: $sp-pad;
  padding-right: $sp-pad;
  >.inner{
    position: relative;
  }
  &.style_01{
    background-color: $bg-color2;
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-style_01{
  padding-left: 0;
  padding-right: 0;
  >.inner{
    @include contentWrap;
  }
}
}
////////////////////////////////////////
.sec-style_02{
  position: relative;
  padding: 0 $sp-pad;
  >.bg-elem{
    position: absolute;
    top: 0;
    right: 0;
    svg{
      width: 217/$design-num-sp*100vw;
      height: 217/$design-num-sp*100vw;
      fill:$white;
    }
  }
  >.inner{
    position: relative;
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-style_02{
  padding: 0 40px;
  >.bg-elem{
    svg{
      width: 266px;
      height: 266px;
    }
  }
  >.bg-elem.style_01{
    top: -60px;
    right: -210px;
    z-index: -1;
    svg{
      width: 430px;
      height: 430px;
    }
  }
  >.inner{
    max-width: 720px;
    margin: 0 auto;
  }
}
}
////////////////////////////////////////
.sec-style_03{
  border-radius: 8px;
  background-color: $bg-color2;
  >.inner{
    padding: 40/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-style_03{
  >.inner{
    padding: 30px;
  }
}
}
////////////////////////////////////////
.sec-style_04{
  position: relative;
  >.inner{
    padding-left: $sp-pad;
    padding-right: $sp-pad;
    padding-top: 76/$design-num-sp*100vw;
    padding-bottom: 80/$design-num-sp*100vw;
    position: relative;
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-style_04{
  position: relative;
  >.inner{
    @include contentWrap;
    padding-top: 64px;
    padding-bottom: 55px;
  }
}
}
////////////////////////////////////////
.sec-style_05{
  position: relative;
  >.wrap{
    position: relative;
    background-color: $white;
    padding-top: 55/$design-num-sp*100vw;
    padding-bottom: 107/$design-num-sp*100vw;
  }
  >.wrap>.inner{
    position: relative;
    padding-left: $sp-pad;
    padding-right: $sp-pad;
  }
}
.sec-style_05.style_01{
  padding-left: $sp-pad;
  padding-right: $sp-pad;
  >.wrap{
    border-radius: 16px 0 0 16px;
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      bottom: 0;
      background-color: $white;
    }
  }
  >.wrap>.inner{
    padding: 0 0 0 146/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-style_05{
  padding-left: 40px;
  padding-right: 40px;
  >.wrap{
    margin: 0 auto;
    position: relative;
    max-width: $content-width-base;
    background-color: $white;
    padding-top: 68px;
    padding-bottom: 50px;
    border-radius: 16px 0 0 16px;
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      width: 100%;
      bottom: 0;
      background-color: $white;
    }
  }
  >.wrap>.inner{
    padding: 0px 0 0px 118px;
    position: relative;
  }
}
.sec-style_05.style_01{
  padding-left: 40px;
  padding-right: 40px;
  >.wrap>.inner{
    padding: 0px 0 0px 118px;
  }
}
}
////////////////////////////////////////
.sec-style_06{
  @extend .sec-style_04;
  background-color: $white;
}
////////////////////////////////////////
.sec-style_07{
  @extend .sec-style_04;
  >.inner{
    padding-top: 0;
    padding-bottom: 0;
  }
}
////////////////////////////////////////
.sec-style_08{
  @extend .sec-style_07;
  background-color: $white;
}
////////////////////////////////////////
.sec-style_09{
  @extend .sec-style_07;
  background-color: $key-color;
  overflow: hidden;
}
////////////////////////////////////////
.sec-style_10{
  position: relative;
  padding-left: $sp-pad;
  padding-right: $sp-pad;
  background-color: $white;
}
@media screen and (min-width: $break-point-middle) {
.sec-style_10{
  padding-left: 40px;
  padding-right: 40px;
  >.inner{
    max-width: 1110px;
    margin: 0 auto;
  }
}
}
////////////////////////////////////////
.sec-style_11{
  border-top: 10/$design-num-sp*100vw solid $white;
  padding: 60/$design-num-sp*100vw $sp-pad 90/$design-num-sp*100vw;
}
////////////////////////////////////////
.sec-style_12{
  @extend .sec-style_07;
  background-color: $bg-color2;
}
////////////////////////////////////////
.sec-style_13{
  border-top: 1px solid #dedede;
}
@media screen and (min-width: $break-point-middle) {
.sec-style_13{
  border-top: 1px solid $white;
}
}
////////////////////////////////////////
.sec-style_14{
  background-color: $white;
  border-radius: 16/$design-num-sp*100vw;
  >.inner{
    padding:40/$design-num-sp*100vw 20/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-style_14{
  border-radius: 16px;
  >.inner{
    padding: 36px 30px;
  }
}
}
////////////////////////////////////////
.sec-style_15{
  position: relative;
  background-color: $bg-color2;
  overflow: hidden;
  >.inner{
    padding-left: $sp-pad;
    padding-right: $sp-pad;
    padding-top: 100/$design-num-sp*100vw;
    padding-bottom: 80/$design-num-sp*100vw;
    position: relative;
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-style_15{
  >.inner{
    padding-left: 0;
    padding-right: 0;
    max-width: 512px;
    margin: 0 auto;
    padding-top: 64px;
    padding-bottom: 55px;
  }
}
}
////////////////////////////////////////
.sec-style_16{
  @extend .sec-style_02;
}
@media screen and (min-width: $break-point-middle) {
.sec-style_16{
  >.inner{
    max-width: 820px;
    margin: 0 auto;
  }
}
}
////////////////////////////////////////
.sec-style_17{
  background-color: $white;
  border-radius: 16/$design-num-sp*100vw;
  >.inner{
    padding: 30/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.sec-style_17{
  background-color: $white;
  border-radius: 16px;
  >.inner{
    padding: 20px;
  }
}
}
////////////////////////////////////////
.sec-style_18{
  @extend .sec-style_02;
}
@media screen and (min-width: $break-point-middle) {
.sec-style_18{
  >.inner{
    max-width: 720px;
    margin: 0 auto;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
.wrap-style_01{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &::before,&::after{
    content: '';
    display: block;
    width: 276/$content-num-base*100%;
  }
  &::before{
    order: 1;
  }
  >*{
    width: 276/$content-num-base*100%;
    &:nth-child(n+5){
      margin-top: 34/$design-num*100vw;
    }
  }
}
}
@media screen and (min-width: $design-width) {
.wrap-style_01{
  >*{
    &:nth-child(n+5){
      margin-top: 34px;
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_02{
  >.inner{
    >*{
      margin-bottom: 50/$design-num-sp*100vw;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.wrap-style_02{
  >.inner{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after{
      content: '';
      display: block;
      width: 370/$content-num-base*100%;
    }
    >*{
      width: 370/$content-num-base*100%;
      margin-bottom: 0;
      &:nth-child(n+4){
        margin-top: 44/$design-num*100vw;
      }
    }
  }
}
}
@media screen and (min-width: $design-width) {
.wrap-style_02{
  >.inner{
    >*{
      &:nth-child(n+4){
        margin-top: 44px;
      }
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_03{
  >*{
    padding: 34/$design-num-sp*100vw 0;
    border-bottom: 2px solid #dedede;
    &:last-child{
      border: none;
      padding-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.wrap-style_03{
  >*{
    padding: 24px 0;
  }
}
}
/////////////////////////////////////////
.wrap-style_04{
  >.inner{
    >*{
      margin-bottom: 50/$design-num-sp*100vw;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.wrap-style_04{
  >.inner{
    >*{
      margin-bottom: 30px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_05{
  >.inner{
    display: flex;
    justify-content: space-between;
    >*{
      width: 345/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.wrap-style_05{
  >.inner{
    >*{
      width: 340/720*100%;
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_06{
  >.inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    >*{
      width: 340/$design-num-sp*100vw;
      &:nth-child(n+3){
        margin-top: 40/$design-num-sp*100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.wrap-style_06{
  >.inner{
    >*{
      width: 200/440*100%;
      &:nth-child(n+3){
        margin-top: 24px;
      }
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_07{
  >.inner{
    >*{
      margin-bottom: 50/$design-num-sp*100vw;
      &::after{
        content: '';
        height: 10/$design-num-sp*100vw;
        background-color: $white;
        width: 150%;
        margin-left: -($sp-pad);
        margin-right: -($sp-pad);
        position: relative;
        margin-top: 50/$design-num-sp*100vw;
        display: block;
        z-index: -1;
      }
      &:last-child{
        margin-bottom: 0;
        &::after{
          display: none;
        }
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.wrap-style_07{
  >.inner{
    max-width: 1110px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    >*{
      width: 540/1110*100%;
      margin-bottom: 0;
      &:nth-child(n+3){
        margin-top: 40px;
      }
      &::after{
        display: none;
      }
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_08{
  >*{
    margin-bottom: 30/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.wrap-style_08{
  >*{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_09{
  >.inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    >*{
      width: 340/$design-num-sp*100vw;
      margin-top: 40/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.wrap-style_09{
  >.inner{
    >*{
      width: 520/1110*100%;
      margin-top: 44px;
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_10{
  >.inner{
    >*{
      margin-bottom: 36/$design-num-sp*100vw;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.wrap-style_10{
  max-width: 1056px;
  margin: 0 auto;
  >.inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after{
      content: '';
      display: block;
      width: 325/1056*100%;
      height: 0;
    }
    >*{
      width: 325/1056*100%;
      margin-top: 32px;
      margin-bottom: 0;
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_11{
  >.inner{
    >*{
      margin-bottom: 40/$design-num-sp*100vw;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.wrap-style_11{
  >.inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after{
      content: '';
      display: block;
      width: 340/1056*100%;
      height: 0;
    }
    >*{
      width: 340/1056*100%;
      margin-bottom: 0;
      &:nth-child(n+4){
        margin-top: 32px;
      }
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_12{
  >.inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    >*{
      width: 345/$design-num-sp*100vw;
      &:nth-child(n+3){
        margin-top: 40/$design-num-sp*100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.wrap-style_12{
  >.inner{
    >*{
      width: 570/$content-num-base*100%;
      &:nth-child(n+3){
        margin-top: 32px;
      }
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_13{
  >.inner{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    >*{
      width: 340/$design-num-sp*100vw;
      &:nth-child(n+3){
        margin-top: 50/$design-num-sp*100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.wrap-style_13{
  >.inner{
    &::after{
      content: '';
      display: block;
      width: 370/$content-num-base*100%;
    }
    >*{
      width: 370/$content-num-base*100%;
      margin-bottom: 0;
      &:nth-child(n+3){
        margin-top: 0;
      }
      &:nth-child(n+4){
        margin-top: 44/$design-num*100vw;
      }
    }
  }
}
}
@media screen and (min-width: $design-width) {
.wrap-style_13{
  >.inner{
    >*{
      &:nth-child(n+4){
        margin-top: 44px;
      }
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_14{
  >*{
    padding: 40/$design-num-sp*100vw 0;
    &:first-child{
      padding-top: 0;
    }
    border-bottom: 1px solid #dedede;
    &:last-child{
      border: none;
    }
  }
}
@media screen and (min-width: $design-width) {
.wrap-style_14{
  >*{
    padding: 40px 0;
    &:first-child{
      padding-top: 0;
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_15{
  >*{
    margin-bottom: 20/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.wrap-style_15{
  display: flex;
  justify-content: space-between;
  >*{
    margin-bottom: 0;
    width: 230/720*100%;
    &:nth-child(n+4){
      margin-top: 30px;
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_16{
  counter-reset: number 0;
  >*{
    margin-bottom: 20/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .ttl{
    &::before{
      counter-increment: number 1;
      content:  counter(number) ".";
    }
  }
}
@media screen and (min-width: $design-width) {
.wrap-style_16{
  >*{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
}
/////////////////////////////////////////
.wrap-style_17{
  >*{
    margin-bottom: 20/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $design-width) {
.wrap-style_17{
  >*{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.col-style_01{
  >.col-main{
    margin-bottom: 40/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.col-style_01{
  @include setTbl;
  >*{
    vertical-align: top;
  }
  >.col-main{
    width: 580/1062*100%;
    margin-bottom: 0;
  }
  >.col-side{
    padding-left: 44/1062*100%;
  }
}
}
/////////////////////////////////////////
.col-style_02{
  >*{
    margin-bottom: 50/$design-num-sp*100vw;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.col-style_02{
  display: flex;
  justify-content: space-between;
  >*{
    width: 520/1110*100%;
    margin-bottom: 0;
  }
}
}
/////////////////////////////////////////
.col-style_03{
  max-width: 630/$design-num-sp*100vw;
  margin: 0 auto;
  position: relative;
  .col-img{
    padding-bottom: 340/$design-num-sp*100vw;
    position: relative;
    >*{
      position: absolute;
      bottom: -60/$design-num-sp*100vw;
      left: 0;
      width: 100%;
      img{
        width: 562/$design-num-sp*100vw;
        margin: 0 auto;
        transform: scale(1.2);
        transform-origin: 50% 50%;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.col-style_03{
  max-width: 995px;
  margin: 0 auto;
  >.inner{
    @include setTbl;
  }
  .col-content{
    padding-right: 35/995*100%;
  }
  .col-img{
    padding-bottom: 0;
    width: 447/995*100%;
    >*{
      position: relative;
      bottom: auto;
      img{
        width: 100%;
      }
    }
  }
}
}
/////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
.col-style_04{
  @include setTbl;
  >*{
    vertical-align: top;
  }
  .col-img{
    width: 260/720*100%;
  }
  .col-content{
    padding-left: 20/720*100%;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.elemwrap-style_01{
  >*{
    max-width: 491/$design-num-sp*100vw;
    margin: 0 auto;
  }
}
@media screen and (min-width: $break-point-middle) {
.elemwrap-style_01{
  >*{
    max-width: 262px;
  }
}
}
//////////////////////////
.elemwrap-style_02{
  @include killSpace;
  text-align: center;
  >*{
    max-width: 360/$design-num-sp*100vw;
    margin: 0 auto;
  }
}
@media screen and (min-width: $break-point-middle) {
.elemwrap-style_02{
  >*{
    max-width: 209px;
  }
}
}
//////////////////////////
.elemwrap-style_03{
  >*{
    max-width: 491/$design-num-sp*100vw;
    margin: 0 auto;
  }
}
@media screen and (min-width: $break-point-middle) {
.elemwrap-style_03{
  >*{
    max-width: 344px;
  }
}
}
//////////////////////////
.elemwrap-style_04{
  >*{
    width: 461/$design-num-sp*100vw;
    margin: 0 auto;
  }
}
@media screen and (min-width: $break-point-middle) {
.elemwrap-style_04{
  >*{
    width: 330px;
  }
}
}
//////////////////////////
.elemwrap-style_05{
  >*{
    display: block;
  }
}
//////////////////////////
.elemwrap-style_06{
  @include killSpace;
  text-align: center;
  >*{
    max-width: 500/$design-num-sp*100vw;
    margin: 0 auto;
  }
}
@media screen and (min-width: $break-point-middle) {
.elemwrap-style_06{
  >*{
    max-width: 269px;
  }
}
}
//////////////////////////
.elemwrap-style_07{
  >*{
    width: 541/$design-num-sp*100vw;
    margin: 0 auto;
  }
}
@media screen and (min-width: $break-point-middle) {
.elemwrap-style_07{
  >*{
    width: 330px;
  }
}
}
//////////////////////////
.elemwrap-style_08{
  display: flex;
  flex-direction: column-reverse;
  >*{
    display: block;
    width: 100%;
    margin-top: 20/$design-num-sp*100vw;
    &:last-child{
      margin-top: 0;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.elemwrap-style_08{
  justify-content: center;
  flex-direction: row;
  >*{
    width: 200px;
  }
}
}
//////////////////////////
.elemwrap-style_09{
  text-align: center;
}
//////////////////////////
.elemwrap-style_10{
  text-align: center;
  >*{
    display: inline-block;
    min-width: 14em;
  }
}
//////////////////////////
.elemwrap-style_11{
  text-align: center;
  >*{
    display: inline-block;
    min-width: 14em;
  }
}
@media screen and (min-width: $break-point-middle) {
.elemwrap-style_11{
  text-align: left;
}
}
//////////////////////////
.elemwrap-style_12{
  text-align: center;
  background-color: $white;
  padding: 20/$design-num-sp*100vw 0;
  margin-left: -100%;
  margin-right: -100%;
  position: relative;
  >*{
    display: inline-block;
    min-width: 11em;
  }
}
@media screen and (min-width: $break-point-middle) {
.elemwrap-style_12{
  padding: 20px;
}
}
//////////////////////////
.elemwrap-style_13{
  @include killSpace;
  text-align: center;
  >*{
    max-width: 440/$design-num-sp*100vw;
    margin: 0 auto;
  }
}
@media screen and (min-width: $break-point-middle) {
.elemwrap-style_13{
  >*{
    max-width: 269px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}

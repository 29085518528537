@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.mf_finder_container .mf_finder_searchBox{
  margin: 0;
}
.mf_finder_searchBox_items_wrapper .mf_finder_searchBox_items{
  position: relative;
  margin-top: 20/$design-num-sp*100vw;
  padding: 0;
  .mf_finder_searchBox_query_wrap{
    width: 500/$design-num-sp*100vw;
  }
  .mf_finder_searchBox_submit{
    margin-left: 6/$design-num-sp*100vw;
    &::before{
      display: none;
    }
    span{
      padding: 0 20/$design-num-sp*100vw;
      &::before{
        content: '検索する';
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.mf_finder_searchBox_items_wrapper .mf_finder_searchBox_items{
  margin-top: 22px;
  .mf_finder_searchBox_query_wrap{
    width: 552/710*100%;
  }
  .mf_finder_searchBox_submit{
    width: 150px;
    margin-left: 10px;
    span{
      padding: 0;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.siteContent .mf_finder_container{
  position: relative;
  padding: 40/$design-num-sp*100vw $sp-pad 20/$design-num-sp*100vw;
  .mf_finder_organic_wrapper{
  }
}
@media screen and (min-width: $break-point-middle) {
.siteContent .mf_finder_container{
  padding: 30px 40px 80px;
  .mf_finder_organic_wrapper{
    max-width: 720px;
    margin: 0 auto;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.siteContent .mf_finder_container .mf_finder_organic{
   .mf_finder_organic_header_wrapper{
     margin: 0;
     .mf_finder_organic_header{
       width: 100%;
     }
     .mf_finder_query{
       &::before,&::after{
         @include fz_vw(36);
       }
       >span{
         @include fz_vw(60);
       }
     }
     .mf_finder_organic_nums{
       margin: 0 0 0 auto;
       .mf_finder_organic_total,.mf_finder_organic_range_from,.mf_finder_organic_range_to{
         @include fz_vw(32);
       }
     }
     .mf_finder_organic_range{
     }
   }
   .mf_finder_organic_doc{
     padding: 40/$design-num-sp*100vw 0;
     align-items: flex-start;
   }
   .mf_finder_organic_doc_title{
     @include fz_vw(32);
     line-height: 1.6em;
   }
   .mf_finder_organic_doc_body{
     @include fz_vw(28);
     display: -webkit-box;
     overflow: hidden;
     -webkit-line-clamp: 1;
     -webkit-box-orient: vertical;
     height: auto;
   }
   .mf_finder_organic_doc_title_wrapper:visited{
     color: $black;
   }
   .mf_finder_organic_pager_wrapper{
     padding: 40/$design-num-sp*100vw 0;
   }
   .mf_finder_pager_items{
     @include fz_vw(28);
   }
   .mf_finder_organic_doc_url{
     word-break: break-all;
   }
   .mf_finder_link:hover{
     color: $key-color;
   }
}
.mf_finder_organic_doc_zoom{
  display: none!important;
}
@media screen and (min-width: $break-point-middle) {
.siteContent .mf_finder_container .mf_finder_organic{
   .mf_finder_organic_header_wrapper{
     .mf_finder_query{
       &::before,&::after{
         font-size: 2.0rem;
       }
       >span{
         font-size: 3.0rem;
       }
     }
     .mf_finder_organic_nums{
       .mf_finder_organic_total,.mf_finder_organic_range_from,.mf_finder_organic_range_to{
         font-size: 1.6rem;
       }
     }
     .mf_finder_organic_range{
     }
   }
   .mf_finder_organic_doc{
     padding: 20px 0;
   }
   .mf_finder_organic_doc_title{
     font-size: 1.8rem;
   }
   .mf_finder_organic_doc_body{
     font-size: 1.4rem;
   }
   .mf_finder_organic_pager_wrapper{
     padding: 40px 0;
   }
   .mf_finder_pager_items{
     font-size: 1.4rem;
   }
}
.mf_finder_organic_doc_zoom{
  display: none!important;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}

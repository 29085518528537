@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
header{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5000;
  //min-width:$design-width-min;
  >.inner{
    background-color: $bg-color1;
    @include setTbl;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
header .col-logo{
  width: 316/$design-num-sp*100vw;
  .sitelogo{
    padding-left: $sp-pad;
    width: 100%;
    box-sizing: content-box;
    img{
      @include imgset;
      width: 100%;
    }
  }
  .tagline{
    display: none!important;
  }
}
@media screen and (min-width: $break-point-middle) {
header .col-logo{
  width: 197px;
  >.inner{
    a{
      @include setTbl;
      &:hover{
        opacity: .8;
      }
    }
  }
  .sitelogo{
    padding-left: 30px;
    width: 197px;
  }
}
}
@media screen and (min-width: $break-point-large) {
header .col-logo{
  width: 416px;
  .tagline{
    display: table-cell!important;
    padding-left: 12px;
    line-height: 1em;
    //font-weight: bold;
    font-size: 1.2rem;
    white-space: nowrap;
    position: relative;
    top: -1px;
    //font-family: $fontMin;
    img{
      @include imgset;
      width: 177px;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
header .col-nav{
  >.inner{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.header-col-option{
  width: 630/$design-num-sp*100vw;
  opacity: 0;
  transition: .3s;
  position: fixed;
  top: 0/$design-num-sp*100vw;
  left: $sp-pad2;
  margin-top: -20/$design-num-sp*100vw;
  >.inner{
    @include setTbl;
    height: 100%;
  }
  .menuOpen.flag-menu &{
    position: relative;
    opacity: 1;
    z-index: 3001;
    a,select{
      cursor: default;
      pointer-events: none;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.header-col-option{
  width: 300px;
  opacity: 1;
  transition: .3s;
  position: fixed;
  top: 20px;
  right: 210px;
  left: auto;
  z-index: 5001;
  margin-top: 0;
  .menuOpen.flag-menu &{
    position: fixed;
  }
  .menuOpen &{
    opacity: 0;
  }
}
}
@media screen and (min-width: $break-point-full) {
.header-col-option{
  width: 490px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.header-weather{
  white-space: nowrap;
  >p{
    display: inline-block;
    color: $key-color;
    vertical-align: middle;
  }
  .label-area{
    display: block;
    @include fz_vw(32);
    line-height: 1em;
    font-family: $fontEn;
  }
  .info-date{
    position: relative;
    top: -20/$design-num-sp*100vw;
  }
  .tx-date{
    @include fz_vw(60);
    line-height: 1em;
    font-weight: bold;
    font-family: $fontEn;
  }
  .tx-week{
    @include fz_vw(24);
    line-height: 1em;
    position: relative;
    margin-left: -16/$design-num-sp*100vw;
  }
  .icon-weather{
    @include fz_vw(120);
    line-height: 1em;
    position: relative;
    top: -40/$design-num-sp*100vw;
    margin-left: -20/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.header-weather{
  width: 150px;
  .label-area{
    display: inline-block;
    font-size: 1.2rem;
  }
  .info-date{
    top: 0;
  }
  .tx-date{
    font-size: 2.0rem;
  }
  .tx-week{
    font-size: 1.0rem;
    margin-left: -8px;
  }
  .icon-weather{
    font-size: 3.0rem;
    top: -3px;
    margin-left: -5px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.header-sns{
  display: none!important;
}
@media screen and (min-width: $break-point-full) {
.header-sns{
  display: table-cell!important;
  ul{
    @include killSpace;
    white-space: nowrap;
  }
  li{
    margin: 0 4px;
  }
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $key-black;
    width: 30px;
    height: 30px;
    font-size: 1.6rem;
    color: $key-black;
    border-radius: 100%;
    text-align: center;
    line-height: 1em;
    overflow: hidden;
    position: relative;
    transition: .6s;
    i{
      position: relative;
    }
    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $key-color;
      transition: .3s;
      transform-origin: 50% 50%;
      transform: scale(0);
      border-radius: 100%;
    }
  }
  a:hover{
    border: 1px solid $key-color;
    color: $white;
    background-color: $key-color;
    &::before{
      transform: scale(1.2);
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.header-locale{
  padding: 0 0px 0 30/$design-num-sp*100vw;
  position: relative;
  top: -30/$design-num-sp*100vw;
  z-index: 11;
}
@media screen and (min-width: $break-point-middle) {
.header-locale{
  padding: 0 20px 0 14px;
  top: 0;
  width: 150px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.menu-large{
  @include setTbl;
  width: auto;
  li{
    width: 110/$design-num-sp*100vw;
    height: 110/$design-num-sp*100vw;
    border-left: 2/$design-num-sp*100vw solid $bg-color1;
    opacity: 1;
    transition: .3s;
    .menuOpen &{
      opacity: 0;
      a{
        cursor: default;
        pointer-events: none;
      }
    }
    &.style_01{
      border-left: none;
      a{
        background-color: $key-color;
      }
      .icon,.label{
        color: $white;
      }
      .menuOpen &{
        opacity: 1;
        a{
          cursor: pointer;
          pointer-events:auto;
        }
      }
    }
  }
  a{
    background-color: $white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    overflow: hidden;
    i{
      transition: .3s;
    }
    >span{
      position: relative;
    }
  }
  .icon{
    display: block;
    @include fz_vw(40);
    line-height: 1em;
    color: $key-color;
  }
  .label{
    font-weight: bold;
    @include fz_vw(20);
    &.close{
      display: none;
      .menuOpen &{
        display: inline;
      }
    }
    &.normal{
      .menuOpen &{
        display: none;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.menu-large{
  li{
    width: 70px;
    height: 70px;
    border-left: 2px solid $bg-color1;
  }
  a{
    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: -50px;
      right: 0;
      bottom: 0;
      border-right: 50px solid transparent;
      border-top: 150px solid $key-black;
      transform: translateX(-100%);
      transition: .5s $ease-in-out-quad;
    }
  }
  a:hover{
    color: $white;
    i{
      color: $white;
    }
    &::before{
      transform: translateX(20%);
    }
  }
  .icon{
    font-size: 2.0rem;
  }
  .label{
    font-size: 1.1rem;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.icon-burger{
  width: 54/$design-num-sp*100vw;
  height: 24/$design-num-sp*100vw;
  margin: 0 auto 12/$design-num-sp*100vw;
  display: block;
  position: relative;
  top: 6/$design-num-sp*100vw;
  >span{
    display: block;
    transition: all .4s;
    position: absolute;
    left: 0;
    width: 100%;
    height: 4/$design-num-sp*100vw;
    background-color: $white;
    &:nth-child(1){
      top: 0;
      .active &{
        transform: translateY(10/$design-num-sp*100vw) rotate(-150deg);
      }
    }
    &:nth-child(2){
      bottom: 0;
      .active &{
        transform: translateY(-10/$design-num-sp*100vw) rotate(150deg);
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.icon-burger{
  width: 30px;
  height: 14px;
  margin: 0 auto 5px;
  top: 0;
  >span{
    height: 2px;
    &:nth-child(1){
      .active &{
        transform: translateY(6px) rotate(-150deg);
      }
    }
    &:nth-child(2){
      .active &{
        transform: translateY(-6px) rotate(150deg);
      }
    }
  }
}
a:hover .icon-burger{
  >span{
    width: 120%;
    left: -10%;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.nav-global{
  opacity: 1;
  transition: .3s;
  background-color: $key-color;
  .menuOpen &{
    opacity: 0;
    a{
      cursor: default;
      pointer-events:none;
    }
  }
  >.inner{
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  ul{
    @include setTbl;
    width: auto;
    table-layout: auto;
  }
  li{
    white-space: nowrap;
    &:last-child a::before{
      display: none;
    }
  }
  a{
    background-color: $key-color;
    color: $white;
    @include fz_vw(28);
    line-height: 1em;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    padding: 0 18/$design-num-sp*100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 94/$design-num-sp*100vw;
    &::before{
      content: '';
      display: block;
      width: 2/$design-num-sp*100vw;
      background-color: #d77e73;
      position: absolute;
      top: 18/$design-num-sp*100vw;
      right: 0;
      $navgutter:36/$design-num-sp*100vw;
      height: calc(100% - #{$navgutter});
    }
    >span{
      position: relative;
      z-index: 10;
      overflow: hidden;
      >*{
        transition: .5s ease;
        &.base{
          position: relative;
          top: 0;
        }
      }
    }
    .clone{
      display: none;
    }
  }
  a.current{
    cursor: default;
    pointer-events: none;
    background-color: $key-black;
    color: $white;
  }
}
@media screen and (min-width: $break-point-middle) {
.nav-global{
  background-color: transparent;
  >.inner{
    overflow: hidden;
  }
  ul{
    width: 100%;
    table-layout: fixed;
  }
  li{
    border-right: 1px solid #d77e73;
    &:last-child{
      border: none;
    }
    &:last-child a::before{
      display: block;
    }
  }
  a{
    font-size: 1.6rem;
    line-height: 1.2em;
    padding: 8px;
    min-height: 50px;
    &:before,&:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $key-black;
      transition: .3s ease-in-out;
    }
    &:before {
      transform: translateY(-100%);
      width: auto;
      height: auto;
    }
    &:after {
      transform: translateY(100%);
    }
    .clone{
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(110%)
    }
  }
  a:hover,a.current{
    &:before {
      transform: translateX(0);
      transform: translateY(-50%);
    }
    &:after {
      transform: translateY(50%);
    }
    >span .base{
      top: -2rem;
    }
    .clone{
      transform: translateY(0%);
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}

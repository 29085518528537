@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.carousel-style{
  img{
    @include imgset;
  }
  .screen{
    position: relative;
  }
  .screen>.item:nth-child(n+2){
    display: none;
  }
  .thumbs>.item{
    display: none;
  }
  .slick-slide{
    font-size: 0;
  }
  button{
    outline:0;
    @include appearance(none);
  }
  .slick-next, .slick-prev,.carousel-next, .carousel-prev{
    width: 68/$design-num-sp*100vw;
    height: 68/$design-num-sp*100vw;
    background-color: $key-black;
    border-radius: 8/$design-num-sp*100vw;
    z-index: 20;
    transform: translateY(0%);
    transition: .3s;
    &.swiper-button-disabled{
      cursor: default;
      pointer-events: none;
      &::before{
        opacity: .5;
      }
    }
  }
  .carousel-next, .carousel-prev{
    cursor: pointer;
    text-align: center;
    color: $white;
  }
  .slick-next, .slick-prev{
    width: 68/$design-num-sp*100vw;
    $slick-gutter:34/$design-num-sp*100vw;
    top: -webkit-calc(50% - #{$slick-gutter});
    top: calc(50% - #{$slick-gutter});
  }
  .slick-prev{
    left: 20/$design-num-sp*100vw;
  }
  .slick-next{
    right: 20/$design-num-sp*100vw;
  }
  .slick-next:before, .slick-prev:before, .carousel-next:before, .carousel-prev:before {
    @include iconFont;
    content: '\EA0F';
    @include fz_vw(24);
    line-height: 68/$design-num-sp*100vw;
    opacity: 1;
  }
  .slick-prev::before,.carousel-prev::before{
    content: '\EA16';
  }
}
@media screen and (min-width: $break-point-middle) {
.carousel-style{
  .slick-next, .slick-prev,.carousel-next, .carousel-prev{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    &:hover{
      background-color: $key-color;
    }
    &:active{
      background-color: $key-black;
    }
  }
  .slick-next, .slick-prev{
    width: 40px;
    top: -webkit-calc(50% - 20px);
    top: calc(50% - 20px);
  }
  .slick-prev{
    left: 20px;
  }
  .slick-next{
    right: 20px;
  }
  .slick-next:before, .slick-prev:before, .carousel-next:before, .carousel-prev:before {
    font-size: 1.6rem;
    line-height: 40px;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.carousel-style_01{
  margin-bottom: 70/$design-num-sp*100vw;
  .screen{
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  .movie{
    width: 100%;
    aspect-ratio:720/480;
    display: flex;
    align-items: center;
  }
  .thumbs{
    margin-top: 26/$design-num-sp*100vw;
    .item{
      border-radius: 8px;
      overflow: hidden;
      >span{
        cursor: pointer;
        position: relative;
        display: block;
        margin: 0 2px;
        &::after{
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          border-radius: 8px;
          border: 2px solid $key-black;
          opacity: 0;
          transition: .3s;
        }
      }
    }
    .slick-current{
      .item>span{
        cursor: default;
        pointer-events: none;
        &::after{
          opacity: 1;
        }
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.carousel-style_01{
  margin-bottom: 60px;
  .thumbs{
    margin-top: 24px;
  }
}
}
////////////////////////////////////////////////////////////
.carousel-style_02{
  position: relative;
  .screen{
    // border-radius: 16px;
    // overflow: hidden;
  }
  .slick-next, .slick-prev{
    top: auto;
    bottom: 24/$design-num-sp*100vw;
  }
  .slick-prev{
    right: 103/$design-num-sp*100vw;
    left: auto;
  }
  .slick-next{
    right: 24/$design-num-sp*100vw;
  }
}
@media screen and (min-width: $break-point-middle) {
.carousel-style_02{
  .slick-next, .slick-prev{
    bottom: 12px;
  }
  .slick-prev{
    right: 62px;
  }
  .slick-next{
    right: 12px;
  }
}
}
////////////////////////////////////////////////////////////
.carousel-style_03{
  position: relative;
  overflow: hidden;
  .carousel-ctr{
    position: absolute;
    bottom: 0px;
    right: $sp-pad;
    width: 68/$design-num-sp*100vw;
    >*{
      display: block;
    }
  }
  .carousel-ctr .col-nav{
    width: 58/$design-num-sp*100vw;
    padding-left: 0;
    margin-top: 48/$design-num-sp*100vw;
    >*{
      display: block;
      >*{
        margin-top: 10/$design-num-sp*100vw;
      }
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.carousel-style_03{
  .carousel-ctr{
    width: auto;
    right: auto;
    top: 20px;
    left: 20px;
    >*{
      display: table-cell;
    }
  }
  .col-pagination{
    display: none;
  }
  .carousel-ctr .col-nav{
    width: 100px;
    padding-left: 10px;
    margin-top: 0;
    >*{
      display: flex;
      justify-content: space-between;
      >*{
        margin-top: 0;
      }
    }
  }
}
}
@media screen and (min-width: 1100px) {
.carousel-style_03{
  .carousel-ctr{
    left: 650/880*100%;
    top: auto;
    bottom: 64px;
  }
}
}
@media screen and (min-width: 1200px) {
.carousel-style_03{
  .carousel-ctr{
    left: 548px;
    width: 188px;
  }
  .col-pagination{
    display: table-cell;
  }
}
}
////////////////////////////////////////////////////////////
.carousel-style_04{
  overflow: hidden;
}
////////////////////////////////////////////////////////////
.carousel-style_05{
  .carousel-ctr{
    position: absolute;
    top: -90/$design-num-sp*100vw;
    right: 0;
  }
}
@media screen and (min-width: $break-point-middle) {
.carousel-style_05{
  .carousel-ctr{
    position: absolute;
    top: -67px;
    right: 0;
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.carousel-ctr{
  @include setTbl;
  width: 264/$design-num-sp*100vw;
  height: 68/$design-num-sp*100vw;
  >*{
    position: relative;
  }
  .carousel-next, .carousel-prev{
    position: relative;
  }
  .swiper-horizontal>.swiper-pagination-progressbar, .swiper-pagination-progressbar.swiper-pagination-horizontal, .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite, .swiper-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite{
    top: 50%;
    border-radius: 50px;
    overflow: hidden;
  }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
    background-color: $key-color;
    border-radius: 50px;
    overflow: hidden;
  }
  .swiper-pagination-progressbar{
    background-color: $white;
  }
}
.carousel-ctr .col-nav{
  width: 162/$design-num-sp*100vw;
  padding-left: 13/$design-num-sp*100vw;
  >*{
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: $break-point-middle) {
.carousel-ctr{
  width: 188px;
  height: 40px;
}
.carousel-ctr .col-nav{
  width: 100px;
  padding-left: 10px;
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}

@font-face {
    font-family: "myfont";
    src: url('../fonts/myfont.eot');
    src: url('../fonts/myfont.eot?#iefix') format('eot'),
        url('../fonts/myfont.woff') format('woff'),
        url('../fonts/myfont.ttf') format('truetype'),
        url('../fonts/myfont.svg#myfont') format('svg');
    font-weight: normal;
    font-style: normal;
}
.myfont {
    display: inline-block;
    font-family: "myfont";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.myfont-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.myfont-2x { font-size: 2em; }
.myfont-3x { font-size: 3em; }
.myfont-4x { font-size: 4em; }
.myfont-5x { font-size: 5em; }
.myfont-fw {
    width: 1.2857142857142858em;
    text-align: center;
}
.myfont-arrow_01:before { content: "\EA01" }
.myfont-twitter:before { content: "\EA02" }
.myfont-facebook:before { content: "\EA03" }
.myfont-instagram:before { content: "\EA04" }
.myfont-youtube:before { content: "\EA05" }
.myfont-search:before { content: "\EA06" }
.myfont-mail:before { content: "\EA07" }
.myfont-weather_01:before { content: "\EA08" }
.myfont-weather_02:before { content: "\EA09" }
.myfont-weather_03:before { content: "\EA0A" }
.myfont-weather_04:before { content: "\EA0B" }
.myfont-weather_05:before { content: "\EA0C" }
.myfont-weather_06:before { content: "\EA0D" }
.myfont-weather_07:before { content: "\EA0E" }
.myfont-arrow_02:before { content: "\EA0F" }
.myfont-external:before { content: "\EA10" }
.myfont-close:before { content: "\EA11" }
.myfont-pinterest:before { content: "\EA12" }
.myfont-wechat:before { content: "\EA13" }
.myfont-hatena:before { content: "\EA14" }
.myfont-line:before { content: "\EA15" }
.myfont-arrow_03:before { content: "\EA16" }
.myfont-info:before { content: "\EA17" }
.myfont-play:before { content: "\EA18" }
.myfont-tiktok:before { content: "\EA19" }
.myfont-like:before { content: "\EA1A" }
.myfont-balloon:before { content: "\EA1B" }
.myfont-question:before { content: "\EA1C" }
.myfont-tx-q:before { content: "\EA1D" }
.myfont-tx-a:before { content: "\EA1E" }
.myfont-select:before { content: "\EA1F" }
.myfont-arrow-04:before { content: "\EA20" }
.myfont-spotify:before { content: "\EA21" }
.myfont-mailmagazine:before { content: "\EA22" }
.myfont-signin:before { content: "\EA23" }
.myfont-quote:before { content: "\EA24" }

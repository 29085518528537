@charset "utf-8";
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
.bg-elem-global{
  >span{
    display: block;
    position: absolute;
    mix-blend-mode: multiply;
    img{
      @include imgset;
    }
  }
  >span.style_01{
    left: 20/$design-num-sp*100vw;
    top: -20/$design-num-sp*100vw;
    img{
      width: 170/$design-num-sp*100vw;
    }
  }
  >span.style_02{
    right: 20/$design-num-sp*100vw;
    top: -40/$design-num-sp*100vw;
    img{
      width: 206/$design-num-sp*100vw;
    }
  }
  >span.style_03{
    left: 0px;
    top: -90/$design-num-sp*100vw;
    img{
      width: 217/$design-num-sp*100vw;
    }
  }
  >span.style_04{
    top: -30/$design-num-sp*100vw;
    right: 0/$design-num-sp*100vw;
    img{
      width: 260/$design-num-sp*100vw;
    }
  }
  >span.style_05{
    top: 20/$design-num-sp*100vw;
    right: 300/$design-num-sp*100vw;
    img{
      width: 112/$design-num-sp*100vw;
    }
  }
  >span.style_06{
    bottom: -160/$design-num-sp*100vw;
    left: 20/$design-num-sp*100vw;
    img{
      width: 254/$design-num-sp*100vw;
    }
  }
  >span.style_07{
    z-index: -1;
    left: -40/$design-num-sp*100vw;
    top: 40/$design-num-sp*100vw;
    mix-blend-mode: normal;
    transform: rotate(135deg) scale(1, -1);
    svg{
      width: 277/$design-num-sp*100vw;
      height: 277/$design-num-sp*100vw;
      fill:$white;
    }
  }
  >span.style_08{
    top: 3302/$design-num-sp*100vw;
    z-index: 10;
    right: 0px;
    img{
      width: 185/$design-num-sp*100vw;
    }
  }
  >span.style_09{
    top: 2185/$design-num-sp*100vw;
    right: 30/$design-num-sp*100vw;
    z-index: 10;
    transform: rotate(10deg);
    img{
      width: 117/$design-num-sp*100vw;
    }
  }
  >span.style_10{
    right: -40/$design-num-sp*100vw;
    bottom: 40/$design-num-sp*100vw;
    mix-blend-mode: normal;
    transform: rotate(45deg);
    svg{
      width: 277/$design-num-sp*100vw;
      height: 277/$design-num-sp*100vw;
      fill:$white;
    }
  }
  >span.style_11{
    top: -140/$design-num-sp*100vw;
    right: 40/$design-num-sp*100vw;
    img{
      width:200/$design-num-sp*100vw;
    }
  }
  >span.style_12{
    top: -100/$design-num-sp*100vw;
    left: 40/$design-num-sp*100vw;
    z-index: 10;
    img{
      width: 248/$design-num-sp*100vw;
    }
  }
  >span.style_13{
    top: -130/$design-num-sp*100vw;
    right: 30/$design-num-sp*100vw;
    img{
      width: 248/$design-num-sp*100vw;
    }
  }
  >span.style_14{
    display: none;
    left: -50px;
    top: 350px;
    mix-blend-mode: normal;
    transform: rotate(135deg) scale(1, -1);
    svg{
      width: 325px;
      height: 325px;
      fill:$bg-color;
    }
  }
  >span.style_15{
    top: -150/$design-num-sp*100vw;
    left: 20/$design-num-sp*100vw;
    img{
      width: 272/$design-num-sp*100vw;
    }
  }
  >span.style_16{
    top: -140/$design-num-sp*100vw;
    right: 20/$design-num-sp*100vw;
    img{
      width: 238/$design-num-sp*100vw;
    }
  }
  >span.style_17{
    right: -50/$design-num-sp*100vw;
    top: 40/$design-num-sp*100vw;
    mix-blend-mode: normal;
    transform: rotate(45deg);
    z-index: -1;
    svg{
      width: 325/$design-num-sp*100vw;
      height: 325/$design-num-sp*100vw;
      fill:$white;
    }
  }
  >span.style_18{
    top: -120/$design-num-sp*100vw;
    right: 0px;
    img{
      width: 211/$design-num-sp*100vw;
    }
  }
  >span.style_19{
    bottom: -50/$design-num-sp*100vw;
    left: 150/$design-num-sp*100vw;
    img{
      width: 103/$design-num-sp*100vw;
    }
  }
  >span.style_20{
    right: 0px;
    top: 0px;
    mix-blend-mode: normal;
    svg{
      width: 325/$design-num-sp*100vw;
      height: 325/$design-num-sp*100vw;
      fill:#c44232;
    }
  }
  >span.style_21{
    left: 0;
    bottom: 0px;
    mix-blend-mode: normal;
    transform: rotate(180deg);
    svg{
      width: 325/$design-num-sp*100vw;
      height: 325/$design-num-sp*100vw;
      fill:#c44232;
    }
  }
  >span.style_22{
    left: -40/$design-num-sp*100vw;
    top: -140/$design-num-sp*100vw;
    mix-blend-mode: normal;
    transform: rotate(-135deg);
    z-index: -1;
    svg{
      width: 325/$design-num-sp*100vw;
      height: 325/$design-num-sp*100vw;
      fill:$bg-color2;
    }
  }
  //予約設定
  >span.style_23{
    z-index: -1;
    left: -40/$design-num-sp*100vw;
    top: 40/$design-num-sp*100vw;
    mix-blend-mode: normal;
    transform: rotate(135deg) scale(1, -1);
    svg{
      width: 277/$design-num-sp*100vw;
      height: 277/$design-num-sp*100vw;
      fill:$white;
    }
  }
  >span.style_24{
    display: block;
    left: -0px;
    top: -10/$design-num-sp*100vw;
    mix-blend-mode: normal;
    transform: rotate(-90deg);
    svg{
      width: 317/$design-num-sp*100vw;
      height: 325/$design-num-sp*100vw;
      fill:$bg-color2;
    }
  }
  // >span.style_25{
  //   top: -80px;
  //   right: 0px;
  //   img{
  //     width: 210px;
  //   }
  // }
  >span.style_26{
    top: -30/$design-num-sp*100vw;
    right: 20/$design-num-sp*100vw;
    img{
      width: 186/$design-num-sp*100vw;
    }
  }
  // >span.style_27{
  //   z-index: 10;
  //   top: -140px;
  //   right: 0px;
  //   img{
  //     width: 210px;
  //   }
  // }
  >span.style_28{
    left: -20/$design-num-sp*100vw;
    bottom: -20/$design-num-sp*100vw;
    mix-blend-mode: normal;
    transform: rotate(180deg);
    svg{
      width: 277/$design-num-sp*100vw;
      height: 277/$design-num-sp*100vw;
      fill:#c44232;
    }
  }
  >span.style_29{
    display: none;
  }
  >span.style_30{
    right: 40/$design-num-sp*100vw;
    top: 40/$design-num-sp*100vw;
    img{
      width: 179/$design-num-sp*100vw;
    }
  }
  >span.style_31{
    left: 0;
    bottom: -160/$design-num-sp*100vw;
    img{
      width: 181/$design-num-sp*100vw;
    }
  }
  //予約
  // >span.style_32{
  //   left: 0;
  //   bottom: -160/$design-num-sp*100vw;
  //   img{
  //     width: 181/$design-num-sp*100vw;
  //   }
  // }
  >span.style_33{
    left: 20/$design-num-sp*100vw;
    top: -50/$design-num-sp*100vw;
    img{
      width: 180/$design-num-sp*100vw;
    }
  }
}
@media screen and (min-width: $break-point-middle) {
.bg-elem-global{
  >span.style_01{
    left: 10px;
    top: -60px;
    img{
      width: 292px;
    }
  }
  >span.style_02{
    right: 40px;
    top: -49px;
    img{
      width: 260px;
    }
  }
  >span.style_03{
    left: 100px;
    top: -90px;
    img{
      width: 200px;
    }
  }
  >span.style_04{
    top: -60px;
    right: 120px;
    img{
      width: 188px;
    }
  }
  >span.style_05{
    top: 201px;
    left: -34px;
    img{
      width: 106px;
    }
  }
  >span.style_06{
    bottom: -70px;
    left: 50px;
    img{
      width: 238px;
    }
  }
  >span.style_07{
    left: -50px;
    top: -20px;
    mix-blend-mode: normal;
    transform: rotate(135deg) scale(1, -1);
    svg{
      width: 325px;
      height: 325px;
      fill:$white;
    }
  }
  >span.style_08{
    top: 689px;
    right: 0px;
    img{
      width: 149px;
    }
  }
  >span.style_09{
    right: auto;
    top: 990px;
    left: -10px;
    img{
      width: 117px;
    }
  }
  >span.style_10{
    bottom: auto;
    right: -50px;
    top: 1350px;
    mix-blend-mode: normal;
    transform: rotate(45deg);
    svg{
      width: 325px;
      height: 325px;
      fill:$white;
    }
  }
  >span.style_11{
    top: -103px;
    right: 230px;
    img{
      width: 162px;
    }
  }
  >span.style_12{
    top: -120px;
    left: 160px;
    img{
      width: 256px;
    }
  }
  >span.style_13{
    top: -90px;
    right: 140px;
    img{
      width: 310px;
    }
  }
  >span.style_14{
    display: block;
    left: -50px;
    top: 350px;
    mix-blend-mode: normal;
    transform: rotate(135deg) scale(1, -1);
    svg{
      width: 325px;
      height: 325px;
      fill:$bg-color;
    }
  }
  >span.style_15{
    top: -130px;
    left: 80px;
    img{
      width: 302px;
    }
  }
  >span.style_16{
    top: -150px;
    right: 50px;
    img{
      width: 308px;
    }
  }
  >span.style_17{
    right: -50px;
    top: 450px;
    mix-blend-mode: normal;
    transform: rotate(45deg);
    svg{
      width: 325px;
      height: 325px;
      fill:$white;
    }
  }
  >span.style_18{
    top: -80px;
    right: 0px;
    img{
      width: 235px;
    }
  }
  >span.style_19{
    bottom: -50px;
    left: 160px;
    img{
      width: 103px;
    }
  }
  >span.style_20{
    right: 0px;
    top: 0px;
    mix-blend-mode: normal;
    svg{
      width: 325px;
      height: 325px;
      fill:#c44232;
    }
  }
  >span.style_21{
    left: -100px;
    bottom: -170px;
    mix-blend-mode: normal;
    transform: rotate(180deg);
    svg{
      width: 325px;
      height: 325px;
      fill:#c44232;
    }
  }
  >span.style_23{
    left: -50px;
    top: 40px;
    mix-blend-mode: normal;
    transform: rotate(135deg) scale(1, -1);
    svg{
      width: 325px;
      height: 325px;
      fill:$white;
    }
  }
  >span.style_24{
    top: 0px;
    svg{
      width: 325px;
      height: 325px;
    }
  }
  >span.style_25{
    z-index: 10;
    top: -80px;
    right: 40px;
    img{
      width: 164px;
    }
  }
  >span.style_26{
    top: -40px;
    right: 40px;
    img{
      width: 138px;
    }
  }
  >span.style_27{
    z-index: 10;
    top: -140px;
    right: 0px;
    img{
      width: 210px;
    }
  }
  >span.style_28{
    left: -20px;
    bottom: -20px;
    svg{
      width: 325px;
      height: 325px;
    }
  }
  >span.style_29{
    display: block;
    right: -200px;
    top: -80px;
    mix-blend-mode: normal;
    transform: rotate(-45deg);
    svg{
      width: 387px;
      height: 444px;
      fill:#f2ddd4;
    }
  }
  >span.style_30{
    right: auto;
    top: auto;
    left: -200px;
    bottom: 90px;
    img{
      width: 130px;
    }
  }
  >span.style_31{
    left: 0;
    bottom: -160px;
    img{
      width: 139px;
    }
  }
  >span.style_32{
    right: 0;
    bottom: -80px;
    img{
      width: 116px;
    }
  }
  >span.style_33{
    left: 100px;
    top: -30px;
    img{
      width: 140px;
    }
  }
}
}
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
@media screen and (min-width: $break-point-middle) {
}
